import React, { Component } from 'react'
import FunCommonInput, { EnDataType } from '../../components/common-input';
import FunLayout, { EnLayoutType } from '../../components/fun-layout';
import Header from '../../components/header';
import CompanyList from '../company-list';
import Footer from '../../components/footer';
import TabBar from '../../components/tab-bar';
import { OperationTabs } from '../../common/constant';
import model from './model';

import './index.scss'


export default class CompanyService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerList: [],
      companyList: [],
    };
  }

  componentDidMount() {
    this.onGetCustomerList('');
  }

  onGetCustomerList(companyName) {
    model.getCustomerList(companyName).then(res => {
      console.log(res);
      const { data = [] } = res;
      const { companyList = [] } = this.state;
      companyList.splice(0, companyList.length);
      data.forEach(item => {
        const { company = {} } = item;
        const { id,
          logo_url = '',
          name = '',
          profile = '',
          create_at = '',
          mobile = '',
          companyLabels = [] } = company;
        companyList.push({
          id, avatar: logo_url, name, profile, create_at, mobile, labels: companyLabels,
        })
      });
      this.setState({ customerList: data, companyList });
    });
  }

  onChange(value) {
    this.onGetCustomerList(value);
  }

  onCompanyInfoClick(value) {
    console.log(value);
    const { id } = value;
    this.props.history.push(`/company-info?companyId=${id}`);
  }

  render() {
    const { companyList } = this.state;
    return (
      <div className="customer-list-container">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <div className="top">
            <Header hidden={false} isNoBorder history={this.props.history}>
              客户列表
            </Header>
            <div className="customer-search">
              <FunCommonInput
                dataType={EnDataType.none}
                placeholder={'搜索客户'}
                leftIcon={'icon-search'}
                noBorder
                noFocus
                onChange={this.onChange.bind(this)}
              />
            </div>
          </div>

          <div className="customer-list-container">
            <CompanyList companyList={companyList} icon='icon-company-info' text='企业详情' callMobile={false} onClick={this.onCompanyInfoClick.bind(this)} />
          </div>

          <Footer>
            <TabBar activeIndex={0} items={OperationTabs} history={this.props.history} />
          </Footer>
        </FunLayout>
      </div>
    )
  }
}
