import { request } from '../../http';
class Model {

  getStaff() {
    return request('/company/staff', {
      method: 'POST',
    });
  }

  submitQuestion(data) {
    return request('/company/consultation/choose_addr', {
      method: 'POST',
      data,
    });
  }
}

export default new Model();
