import { Component } from "react";
import FunLayout, { EnLayoutType } from "@/components/fun-layout";
import Header from "@/components/header";
import { eshiminSetTitleStyle } from "@/common/eshiminUtils";
import model from './model';

import './index.scss';

export default class QuestionDetail extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    const id = query.get('id') || '';
    this.state = {
      detail: {
        title: '',
        create_at: '',
        content: '',
      },
      id,
    };
  }

  componentDidMount() {
    const { id, } = this.state;
    model.getQuestionDetail(id).then(res => {
      const {
        title = '',
        create_at = '',
        content = '',
      } = res;
      this.setState({
        detail: {
          title,
          create_at,
          content
        }
      });
      eshiminSetTitleStyle('问题详情');
    });
  }



  render() {
    const { detail: { title, create_at, content } } = this.state;
    return (
      <div className="detail-container">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <Header hidden={false} hasBack={true} className={'top'} history={this.props.history}>
            问题详情
          </Header>
          <div className="detail-content-container">
            <span className="detail-title">{title}</span>
            <span className="detail-create-time">{create_at}</span>
            <div
              className="detail-content"
              dangerouslySetInnerHTML={{
                __html:
                  content.replace(/(\r\n|\n|\r)/gm, '<br />'),
              }}
            />
          </div>
        </FunLayout>
      </div>
    );
  }
}