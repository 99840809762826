import { Component } from 'react';
import FunCommonInput, { EnDataType } from '@/components/common-input';
import FunButton, { EnButtonSize, EnButtonType } from '@/components/fun-button';
import FunLayout, { EnLayoutType } from '@/components/fun-layout';
import FunToast from '@/components/fun-toast'
import Header from '@/components/header';
import Utils from '@/common/utils';
import LocalStore from '@/store/localStore';
import { eshiminSetTitleStyle } from '@/common/eshiminUtils';
import model from './model';

import './index.scss'

export default class Login extends Component {
  form = {
    mobile: '',
    pwd: '',
  };
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    const redirectUrl = query.get('redirectUrl') || '';
    const username = query.get('username') || '';
    this.state = {
      pwdType: 'password',
      redirectUrl,
      username,
    };
  }

  componentDidMount() {
    eshiminSetTitleStyle('登录');
  }

  componentWillUnmount() {
    LocalStore.set('shouldIntercept', false);
  }

  onClickRight() {
    this.setState({ pwdType: this.state.pwdType === 'password' ? '' : 'password' });
  }

  onForgetPwd() {
    this.props.history.push(`/forget-pwd?type=${1}`);
  }

  onChangeField(field, value) {
    if (field === 'mobile') {
      this.form.mobile = value;
    } else if (field === 'pwd') {
      this.form.pwd = value;
    }
  }

  onCheckParam() {
    const { mobile, pwd } = this.form;
    if (!mobile) {
      return '请输入手机号码';
    }
    const error = Utils.checkMobile(mobile);
    if (error) {
      return error;
    }
    if (!pwd) {
      return '请输入密码';
    }
    // const pwdError = Utils.checkPassword(pwd);
    // if (pwdError) {
    //   return pwdError;
    // }
  }

  onLogin() {
    console.log(this.form);
    const error = this.onCheckParam();
    if (error) {
      return FunToast.show(error, 2);
    }
    const { mobile, pwd } = this.form;
    const { username = '' } = this.state;
    model.login({ wholeResponse: true, mobile, pwd, username }).then(res => {
      const { data: { code } } = res;
      if (code === 200) {
        Utils.setLogin(true);
        const { redirectUrl } = this.state;
        this.props.history.replace(redirectUrl ? redirectUrl : '/home');
      }
    });
  }

  onRegister() {
    this.props.history.push('/register');
  }

  render() {
    const { pwdType } = this.state;
    return (
      <div className="login-container">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <Header hidden={false} className={'top'} history={this.props.history}>
            用户登录
          </Header>

          <div className="login-content-container">
            <FunCommonInput
              maxLength={11}
              type={'number'}
              dataType={EnDataType.none}
              placeholder={'请输入手机号'}
              icon={'icon-mobile'}
              onChange={value => this.onChangeField('mobile', value)}
            />
            <FunCommonInput
              className={"login-item-container"}
              type={pwdType}
              dataType={EnDataType.none}
              placeholder={'请输入密码'}
              icon={pwdType === 'password' ? 'icon-hide' : 'icon-show'}
              onClickRight={this.onClickRight.bind(this)}
              onChange={value => this.onChangeField('pwd', value)}
            />
            <span className="login-forget-pwd" onClick={this.onForgetPwd.bind(this)}>忘记密码?</span>

            <FunButton
              className="login-btn"
              type={EnButtonType.primary}
              size={EnButtonSize.big}
              onClick={this.onLogin.bind(this)}
            >
              登录
            </FunButton>
            <FunButton
              className="login-btn"
              type={EnButtonType.white}
              size={EnButtonSize.big}
              onClick={this.onRegister.bind(this)}
            >
              注册
            </FunButton>
          </div>

        </FunLayout>
      </div>
    )
  }
}
