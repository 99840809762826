import { request } from '../../http';

class Model {

  resetPwd(data) {
    return request('/companyAccount/resetPwd', {
      method: 'POST',
      data,
    });
  }

  /**
   * 运营端重制密码
   */
  resetOperationPwd(data) {
    return request('/staff/resetPwd', {
      method: 'POST',
      data,
    });
  }

  // 发送验证码
  sendOtp(data) {
    return request(`index/sendCaptcha`, {
      method: 'POST',
      data,
    });
  }
}

export default new Model();
