import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FunIcon from '../fun-icon';
import Utils from '../../common/utils';
import classNames from 'classnames';
import './index.scss';
/**
 * 文件上传
 * 目前只支持上传 1 个
 */
export default class FileUpload extends Component {
  static propTypes = {
    id: PropTypes.string,
    fileList: PropTypes.any, // 文件数组
    accept: PropTypes.string,
    multiple: PropTypes.bool,
    inputProps: PropTypes.any,
    labelProps: PropTypes.any,
    buttonProps: PropTypes.any,
    onChange: PropTypes.func,
    selectable: PropTypes.bool,
    error: PropTypes.bool,
    FileListItem: PropTypes.any, // 选择文件后对应的展示组件
  };
  constructor(props) {
    super(props);
    const { id } = props;
    this.inputId = id ? id : Utils.v4();
  }

  fileChange() {
    const { onChange } = this.props;
    const input = document.getElementById(this.inputId);
    console.log('files:', input?.files);
    onChange && onChange(input?.files);
  };

  onDel() {
    const { onChange } = this.props;
    onChange && onChange(null);
  }

  render() {
    const {
      fileList,
      accept = 'image/*',
      multiple = false,
      inputProps,
      selectable,
      FileListItem,
      fileListItemClassName = 'file-list-upload-default',
      fileListItemScale = 1,
      uploadComponentClassName,
      uploadComponent,
    } = this.props;
    return (
      <div className="file-upload-container">
        <div className="file-list">
          {(fileList || []).map((item, index) => (
            <div className="file-list-item">
              <div className="file-list-item-del" onClick={() => this.onDel(index)}>
                <FunIcon icon="icon-close" />
              </div>
              {FileListItem ? (
                <FileListItem scale={fileListItemScale} className={fileListItemClassName} src={item.url} />
              ) : <span>{item.url}</span>}
            </div>
          ))}
        </div>
        {selectable ? (
          <div className={classNames("file-list-upload", uploadComponent ? uploadComponentClassName : 'file-list-upload-default ')}>
            {uploadComponent ? <div>{uploadComponent}</div> : <FunIcon icon='icon-add' />}
            <input
              id={this.inputId}
              accept={accept}
              multiple={multiple}
              type="file"
              onChange={this.fileChange.bind(this)}
              {...inputProps}
            />
          </div>
        ) : null}
      </div>
    );
  }
}
