import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import debounce from '../../common/utils/debounce';
import Loading from '../loading';
import PullToRefresh from '../pull-to-refresh';
import EndTips from '../end-tips';

/**
 * 滚动加载容器
 */
export default class ScrollView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      update: false,
      loading: false
    };

    this.containerRef = React.createRef();
    this.viewBottomRef = React.createRef();

    ['onScroll', 'onIntersection', 'onLoad', 'onBeforeLoad', 'onAfterLoad'].forEach((method) => {
      this[method] = this[method].bind(this);
    });

    this.onScroll = debounce(this.onScroll);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.noMore) {
      this.setState({ update: true });

      if (this.observer) {
        this.observer.disconnect();
        this.observer = null;
      }
    }
  }

  componentDidMount() {
    // 浏览器支持 IntersectionObserver 特性的话，优先使用 IntersectionObserver（有性能优势）
    if (false && 'IntersectionObserver' in window) {
      this.observer = new IntersectionObserver(this.onIntersection, { rootMargin: `0px 0px ${this.props.threshold}px 0px` });
      this.observer.observe(this.viewBottomRef.current);
    }
    // 否则的话通过监听 scroll 事件来实现
    else {
      var isSupportPassive = false;
      try {
        var opts = Object.defineProperty({}, 'passive', {
          get: function () {
            isSupportPassive = true;
          }
        });
        window.addEventListener('test', null, opts);
      } catch (e) { }
      // 判断浏览器是否支持 passive（如不判断，直接传对象参数的话，不支持的浏览器中会把第三个参数转成 true）
      // 使用 passive 特性可增强用户体验
      window.addEventListener('scroll', this.onScroll, isSupportPassive ? { passive: true } : false);
    }
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    } else {
      // 移除事件时不用判断是否支持 passive
      window.removeEventListener('scroll', this.onScroll);
    }

    if (this.waitTimeout) {
      clearTimeout(this.waitTimeout);
    }
  }

  onIntersection() {
    if (!this.observer || this.state.loading || this.waiting) {
      return;
    }

    if (!this.observer.isIntersecting) {
      // 由不相交到相交
      this.observer.isIntersecting = true;
      this.onLoad();
    } else {
      // 由相交到不相交
      this.observer.isIntersecting = false;
    }
  }

  onScroll() {
    if (this.props.noMore || this.state.loading || this.waiting) {
      return;
    }

    const { threshold } = this.props;
    const htmlEl = document.documentElement;
    const bodyEl = document.body;
    const clientHeight = Math.max(window.innerHeight, htmlEl.clientHeight, bodyEl.clientHeight);
    const scrollHeight = Math.max(htmlEl.scrollHeight, bodyEl.scrollHeight);
    const scrollTop = Math.max(htmlEl.scrollTop, bodyEl.scrollTop);
    const isBottom = Math.floor(scrollHeight - clientHeight - scrollTop) <= threshold;
    if (isBottom) {
      this.onLoad();
    }
  }

  onLoad() {
    const { onLoad } = this.props;
    const request = onLoad && onLoad();
    if (request && request.then) {
      this.onBeforeLoad();
      request.then(this.onAfterLoad, this.onAfterLoad);
    }
  }

  onBeforeLoad() {
    this.setState({ loading: true }, () => {
      // 滚动到底部显示出加载动画
      const htmlEl = document.documentElement;
      const bodyEl = document.body;
      window.scrollTo(0, Math.max(htmlEl.scrollHeight, bodyEl.scrollHeight)
        - Math.max(window.innerHeight, htmlEl.clientHeight, bodyEl.clientHeight)
        - (this.props.threshold + 2));
    });
  }

  onAfterLoad() {
    // 断网等导致请求立刻失败的场景下，加载动画出现后立刻消失，但此时由于惯性滚动的存在，滚动条可能还在继续往下滚，
    // 为避免一直触发加载，此处设置一个延迟，一次触发加载后，至少1秒钟后才能触发另一次加载
    this.waiting = true;
    this.waitTimeout = setTimeout(() => {
      this.waiting = false;
      this.waitTimeout = null;
    }, 1000);

    this.setState({ loading: false });
  }

  render() {
    const { loading } = this.state;
    const { className, noMore, noMoreText, pullToRefresh, onRefresh, children, ...restProps } = this.props;

    delete restProps.onLoad;

    return (
      <div className={classNames('fun-scroll-view', className)} {...restProps} ref={this.containerRef}>
        {
          pullToRefresh && onRefresh && <PullToRefresh onRefresh={onRefresh} />
        }
        {children}
        <div className="view-bottom" ref={this.viewBottomRef}>
          {
            loading && <Loading />
          }
          {
            noMore && noMoreText && <EndTips />
          }
        </div>
      </div>
    );
  }
}

ScrollView.propTypes = {
  className: PropTypes.string, // 自定义 class
  threshold: PropTypes.number, // 偏移量，当滚动到距底部小于等于 threshold 时，就触发加载数据事件
  pullToRefresh: PropTypes.bool, // 是否支持下拉刷新
  noMore: PropTypes.bool, // 是否已没有数据了
  noMoreText: PropTypes.string, // 加载结束后的提示文本
  onLoad: PropTypes.func, // 滚动到底触发的加载数据事件
  onRefresh: PropTypes.func // 下拉刷新时的回调函数
};

ScrollView.defaultProps = {
  threshold: 1,
  pullToRefresh: true,
  noMore: false,
  noMoreText: '-我是有底线的-'
};