import React, { Component } from 'react'
import FunCommonInput, { EnDataType } from '../../components/common-input';
import FunButton, { EnButtonSize, EnButtonType } from '../../components/fun-button';
import FunLayout, { EnLayoutType } from '../../components/fun-layout';
import FunToast from '../../components/fun-toast';
import Header from '../../components/header';
import CompanyItem from './company-item';
import model from './model';
import { eshiminSetTitleStyle } from '../../common/eshiminUtils';

import './index.scss'
export default class ChooseCompany extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    const isEditProfile = (query.get('isEditProfile') || '') === 'true';
    this.state = {
      companyId: -1,
      companyName: '',
      companyAvatarImg: '',
      companyList: [],
      isEditProfile,
    };
  }

  componentDidMount() {
    model.getCompanyList({ companyName: '' }).then(res => {
      const { data = [] } = res;
      this.setState({ companyList: data });
    });
    eshiminSetTitleStyle('选择企业');
  }

  onAddCompany() {
    const { isEditProfile } = this.state;
    if (isEditProfile) {
      this.props.history.replace(`/add-company?isEditProfile=${isEditProfile}`);
    } else {
      this.props.history.push(`/add-company?isEditProfile=${isEditProfile}`);
    }
  }

  onSelect(companyId) {
    const { companyList = [] } = this.state;
    companyList.forEach(item => {
      const { id } = item;
      item.isSelected = id === companyId;
    });
    console.log(companyList.filter(item => item.isSelected))
    const selectedList = companyList.filter(item => item.isSelected);
    if (selectedList && selectedList.length > 0) {
      const { id, name, logo_url } = selectedList[0];
      this.setState({
        companyList,
        companyId: id,
        companyName: name,
        companyAvatarImg: logo_url,
      });
    }
  }

  onRegister() {
    const { companyId, companyName, companyAvatarImg, isEditProfile } = this.state;
    if (companyId < 0) {
      FunToast.show('请选择公司');
      return;
    }
    if (isEditProfile) {
      this.props.history.replace(`/edit-profile?companyId=${companyId}&companyName=${companyName}`);
    } else {
      const query = new URLSearchParams(this.props.location.search);
      const token = query.get('otpRes') || '';
      this.props.history.push(`/business-card?companyId=${companyId}&companyName=${companyName}&companyAvatarImg=${companyAvatarImg}&otpRes=${token}`);
    }
  }

  render() {
    const { companyList, isEditProfile } = this.state;
    return (
      <div className="choose-company-container">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <Header className="top" hidden={false} hasBack={true} history={this.props.history}>
            选择企业
          </Header>
          <div className="choose-company-content-container">
            <FunCommonInput
              dataType={EnDataType.none}
              placeholder={'通过关键词搜索企业'}
              leftIcon={'icon-search'}
              noBorder
              noFocus
            />
            {companyList && companyList.map(item => {
              const { id, name, logo_url, isSelected } = item;
              return <CompanyItem
                key={id}
                companyId={id}
                companyName={name}
                companyAvatarImg={logo_url}
                isSelected={isSelected}
                onSelect={this.onSelect.bind(this)}
              />;
            })}

            <FunButton
              className="choose-company-add-btn"
              type={EnButtonType.white}
              size={EnButtonSize.big}
              onClick={this.onAddCompany.bind(this)}
            >
              新增公司信息
            </FunButton>
            <FunButton
              className="choose-company-next-btn"
              type={EnButtonType.primary}
              size={EnButtonSize.big}
              onClick={this.onRegister.bind(this)}
            >
              {isEditProfile ? '完成' : '下一步'}
            </FunButton>
          </div>

        </FunLayout>
      </div>
    )
  }
}
