import { request } from '../../http';

class Model {

  getInfoDetail(infoId) {
    return request(`/info/detail/${infoId}`, {
      method: 'GET',
    });
  }
}

export default new Model();
