import FunToast from "../components/fun-toast";

const MaxImageSize = 1024 * 1024 * 8;

class Upload {
  async uploadImg(file, maxSize = MaxImageSize) {
    if (file.size > maxSize) {
      FunToast.show('图片大小不能超过8M');
      return;
    }
    const formData = new FormData();
    formData.append('imgFile', file);

    const response = await fetch('/index/uploadImg', {
      method: 'POST',
      body: formData,
    });
    const data = await response.json();

    return new Promise((resolve, reject) => {
      if (response?.status >= 400) {
        reject(Error(`${response.status}`));
      }
      resolve(data);
    });
  }

  async downloadFile(fileName, params) {
    const response = await fetch('/index/downloadFile', {
      method: 'POST',
      body: JSON.stringify(params),
      credentials: 'include',
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    });
    const blob = await response.blob();
    const newBlob = new Blob([blob], { type: "application/pdf" })
    const blobUrl = window.URL.createObjectURL(newBlob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = blobUrl;
    a.download = fileName;
    a.click();
    setTimeout(() => { window.URL.revokeObjectURL(blobUrl); }, 250);

    return new Promise((resolve, reject) => {
      if (response?.status >= 400) {
        reject(Error(`${response.status}`));
      }
      resolve({ code: 200, data: [], msg: '' });
    });
  }
}

export default new Upload();
