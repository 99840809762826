import { Component } from "react";
import FunIcon from "@/components/fun-icon";
import FunLayout, { EnLayoutType } from "@/components/fun-layout";
import Header from "@/components/header";
import Image from "@/components/image";
import LocalStore from "@/store/localStore";
import Utils from "@/common/utils";
import { eshiminSetTitleStyle } from "@/common/eshiminUtils";

import './index.scss';

export default class PolicyAnylyze extends Component {
  constructor(props) {
    super(props);
    this.state = {
      policyAnalyzeList: [],
    };
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const id = query.get('id') || '';

    const policyList = LocalStore.get('policyList') || [];
    const filterList = policyList.filter(item => `${item.id}` === id) || [];
    this.setState({ policyAnalyzeList: (filterList && filterList.length > 0 && filterList[0].analyzes) || [] });
    // model.getPolicyAnalyzeList(id).then(res => {
    //   const { analyzes = [] } = res;
    //   this.setState({ policyAnalyzeList: analyzes });
    // });
    eshiminSetTitleStyle('政策解读');
  }

  onShowAnalyzeContent(id) {
    const { policyAnalyzeList = [] } = this.state;
    policyAnalyzeList.forEach(item => {
      if (item.id === id) {
        item.isShowContent = !item.isShowContent;
      }
    });
    this.setState({ policyAnalyzeList });
  }

  onShowImage(url) {
    this.props.history.push(`/see-origin-img?title=政策解读&url=${Utils.getUrl(url)}`);
  }

  render() {
    const { policyAnalyzeList } = this.state;
    return (
      <div className="policy-analyze-list-container ">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <Header className={'top'} hidden={false} hasBack={true} history={this.props.history}>
            政策解读
          </Header>

          <div className="policy-analyze-list-container ">
            {policyAnalyzeList && policyAnalyzeList.map(item => {
              const { id, isShowContent, title, content, img_url } = item;
              return (
                <div className="policy-analyze-item-container" key={id}>
                  <div className="policy-analyze-title-container" onClick={() => this.onShowAnalyzeContent(id)}>
                    <div className="policy-analyze-title">{title}</div>
                    <FunIcon icon={isShowContent ? 'icon-arrow-up' : 'icon-arrow-down'} />
                  </div>
                  {isShowContent && <div className="policy-analyze-content">{content}</div>}
                  {isShowContent && img_url && <div className="policy-img" onClick={() => this.onShowImage(img_url)}>
                    <Image scale={565 / 331} src={Utils.getUrl(img_url)} />
                  </div>}
                </div>
              );
            })}
          </div>
        </FunLayout>
      </div>
    );
  }
}