import { Component } from "react";
import axios from "axios";
import FunIcon from "../../components/fun-icon";
import Image from "../../components/image";
import mask from "../../public/images/icon-salon-mask.png"
import pdfImg from "../../public/images/icon-pdf.png";
import model from './model';
import Utils from "../../common/utils";
import ReactPlayer from 'react-player';
import FunToast from "../../components/fun-toast";
import upload from "../../common/upload";
import ReactLoading from 'react-loading';
import Platform from "../../common/platform";
import { eshiminSetTitleStyle } from "../../common/eshiminUtils";
import { wechatShare } from "../../common/wechatUtils";
import { nodeUrl, env } from '../../../src/app.json';

import './index.scss';

export default class Salon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollY: 0,
      activityInfo: {},
      activityDetail: {},
      loading: true,
      books: [],
    };
    this.windowScroll = this.handleScroll.bind(this)
  }

  onBack() {
    const canGoBack = (this.props.history.length > 1);
    if (canGoBack) {
      this.props.history.goBack();
    } else {
      this.props.history.replace('./home');
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.windowScroll);
    eshiminSetTitleStyle('会员活动');
    model.getActivitySalon().then(res => {
      const { data = [] } = res;
      if (data && data.length > 0) {
        this.setState({ activityInfo: data[0], loading: false });
        const { id, title, content, background_url } = data[0];
        wechatShare(title, content, background_url);
        this.onGetDetail(id);
      } else {
        this.hideLoading();
      }
    }).catch(() => {
      this.hideLoading();
    });
  }

  onGetDetail(id) {
    model.getActivitySalonDetail(id).then(res => {
      this.setState({ activityDetail: res });
      this.getUrl(res.wechat_url);
    });
    model.getBookList().then(res => {
      let { data = [] } = res;
      if (data && data.length > 5) {
        const list = [];
        data.forEach((item, index) => {
          if (index <= 4) {
            list.push(item);
          }
        });
        this.setState({ books: list });
      } else {
        this.setState({ books: data });
      }
    })
  }

  insertStr(source, start, newStr) {
    return source.slice(0, start) + newStr + source.slice(start)
  }

  getUrl(URL) {
    if (!URL) return;
    let http = (window.location.protocol === 'http:' ? 'http:' : 'https:');
    let realurl = nodeUrl[env] + URL;
    // let realurl = http + '//node.csj-cy.com/' + URL;
    axios.get(realurl).then((response) => {
      if (!response || !response.data) return
      let html = response.data;
      html = html && html.replace(/data-src/g, "src")
      let html_src = html;

      let iframe = document.getElementById('iFrame');


      //这时候就成功拿到了微信公众号，这个html_src是个静态网页
      let doc = iframe.contentDocument || iframe.document;

      html_src = this.insertStr(html_src, html_src.indexOf('</head>'), `<meta name="referrer" content="no-referrer"></meta>`)

      let backgroundUrlReg = /url[(]&quot;(\S*)&quot;/g;
      let backgroundImgs = html_src.match(backgroundUrlReg);
      if (backgroundImgs && backgroundImgs.length) {
        backgroundImgs.forEach(item => {
          let url = item.replace(/url[(]&quot;/g, '').replace(/&quot;/g, '');
          let img = document.createElement('img');
          img.src = url;
          doc.querySelector("body").appendChild(img);
        });
      }
      console.log(html_src)
      //配置无效img绕过背景图片路径限制
      doc.write(html_src);
      // 将静态页面写入iframe中
      setTimeout(() => {
        const height = doc.documentElement.scrollHeight;
        this.setState({ height });
      }, 500);
    }, (err) => { console.log(err); });
  }

  joinActivity() {
    if (!Utils.hasLogin()) {
      Utils.goLogin();
      return;
    }
    const { activityInfo: { id = '' } = {} } = this.state;
    model.joinActivity({ activityId: id }).then(() => {
      FunToast.show('报名成功');
    });
  }

  downloadPdf(name, url) {
    if (!Utils.hasLogin()) {
      Utils.goLogin();
      return;
    }
    if (Platform.isApp) {
      FunToast.show('请用浏览器打开下载哦～');
      return;
    }
    this.showLoading();
    const param = {
      name,
      url,
    };
    upload.downloadFile(name, param).then(res => {
      FunToast.show('下载成功');
      this.hideLoading();
    }).catch(() => {
      this.hideLoading();
    });
  }

  handleScroll() {
    this.setState({ scrollY: window.scrollY });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.windowScroll);
  }

  showLoading() {
    this.setState({ loading: true });
  }

  hideLoading() {
    this.setState({ loading: false });
  }

  render() {
    const {
      loading,
      activityInfo: {
        start_time,
        end_time,
        background_url = '',
        title = '',
        content = '',
      } = {},
      activityDetail: {
        reviews = [],
        wechat_url,
      } = {},
      books = [],
    } = this.state;
    const { scrollY } = this.state;
    return (
      <div className="salon-container">
        {loading && <div className="salon-loading">
          <ReactLoading type="bars" color="#CBB486" width={'10%'} />
        </div>}
        <div className="salon-top-container">
          <div className="salon-activity-bg">
            <Image src={Utils.getUrl(background_url)} />
          </div>
          <div className="salon-activity-bg">
            <Image style={{ height: '100%' }} src={mask} />
          </div>
          {!Platform.isApp && <div className="salon-title-container" style={{ backgroundColor: scrollY > 0 ? '#CBB486' : '' }}>
            <div onClick={this.onBack.bind(this)}>
              <FunIcon
                icon='icon-angle-left'
                iconStyle={{ paddingLeft: '21px', color: '#fff' }}
              />
            </div>
            <span className="salon-title">会员活动</span>
          </div>}
          <div className="salon-activity-content-container">
            <div className="salon-activity-time-container">
              <FunIcon icon='icon-clock' iconStyle={{ color: '#fff', marginTop: '3px' }} />
              <span className="salon-activity-time-des">报名时间：</span>
              <span className="salon-activity-time">
                {`${Utils.formatDate(start_time, 'yyyy.MM.dd hh:mm')}-${Utils.formatDate(end_time, 'yyyy.MM.dd hh:mm')}`}
              </span>
            </div>
            <span className="salon-activity-title">{title}</span>
            <span className="salon-activity-profile-des">内容简介:</span>
            <div
              className="salon-activity-profile"
              dangerouslySetInnerHTML={{
                __html:
                  content.replace(/(\r\n|\n|\r)/gm, '<br />'),
              }}
            />
            {wechat_url && <iframe src="" id="iFrame" width="100%" height={this.state.height + 'px'} frameBorder='0' style={{ marginTop: '30px' }}></iframe>}
            <div className="salon-activity-sign-up" onClick={this.joinActivity.bind(this)}>立即报名</div>
          </div>
        </div>

        {!Platform.isApp && books && books.length > 0 && <div className="salon-book-container">
          <span className="salon-book-title">免费图书</span>
          <div className="salon-book-list">
            {books.map(item => {
              let { id, name = '', cover_img = '', url } = item;
              return (
                <div className="salon-book-item" key={id} onClick={() => this.downloadPdf(name, url)}>
                  <div className="salon-book-img">
                    <Image scale={90 / 111} src={cover_img ? cover_img : pdfImg} />
                  </div>
                  <span className="salon-book-name">{(name && name.length > 10) ? name.substring(0, 10) : name}</span>
                </div>
              );
            })}
          </div>
        </div>
        }
        {reviews && reviews.length > 0 && <div className="salon-review-container">
          <span className="salon-review-title">精彩回顾</span>
          {reviews && reviews.map(item => {
            const { id, type, url } = item;
            if (type === 1) {
              return (
                <div className="salon-review-video" key={id}>
                  {url && <ReactPlayer controls url={Utils.getUrl(url)} width="100%" height="100%;" />}
                </div>
              );
            }
            if (type === 2) {
              return (
                <div className="salon-review-image" key={id}>
                  <Image scale={646 / 363} src={Utils.getUrl(url)} />
                </div>
              );
            }
            return <div key={id} />
          })}
        </div>}

      </div >
    );
  }
}