import { request } from '../../http';

class Model {

  getCompanyList(data) {
    return request('/company/list', {
      method: 'POST',
      data,
    });
  }
}

export default new Model();
