import { request } from '../../http';

class Model {

  getMyConsultation(page = 1, pageSize = 15) {
    return request(`/company/consultation/list?page=${page}&pageSize=${pageSize}`, {
      method: 'GET',
    });
  }
}

export default new Model();
