import FunToast from '../components/fun-toast';
import { messager } from './defaultMessenger';
import History from '../common/url/history';
import LocalStore from '../store/localStore';
import Platform from '../common/platform';
import Utils from '../common/utils';

export function handleHttpError(err) {
  let message = err?.message;

  // http错误信息转译
  switch (message) {
    case 'timeout of 60000ms exceeded':
      message = '网络超时，请稍后重试';
      break;
    case 'Network Error':
      message = '网络错误，请检查网络';
      break;
    case 'Request failed with status code 500':
      message = '服务器错误(500)';
      break;
    case 'Request failed with status code 503':
      message = '服务器错误(503)';
      break;
    default:
      break;
  }

  messager && messager(message);

  /** 500 跳转500 页面 */
  // if (err.status >= 500 && err.status <= 504) {
  //   window.location.href = '/exception-page/exception500';
  // }
}

export function handleBusinessError(
  response,
  handleError,
  resolve,
  reject
) {
  const { code } = response;

  if (String(code) === '900') {
    Utils.setLogin(false);
    const { location } = window;
    const { hash = '' } = location;
    const path = hash.replace('#/', '');

    const shouldIntercept = LocalStore.get('shouldIntercept');
    console.log(shouldIntercept)
    // 随申办app内跳转eshimin sso
    if (Platform.isApp) {
      if (path !== '/login-callback' && path && shouldIntercept) {
        LocalStore.set('shouldIntercept', false);
        History.push(`/#/login-callback?redirectUrl=${encodeURIComponent(path)}`);
        location.href = `${Utils.getBaseUrl()}/login/ssb`;
      }
    } else {
      if (path !== '/login' && path && shouldIntercept) {
        const pathName = `/#/login?redirectUrl=${encodeURIComponent(path)}`;
        location.href = pathName;
        History.replace(pathName);
      }
    }

    LocalStore.set('shouldIntercept', true);

    // 运营端未登录重定向
    // if (path !== '/operation-login' && path) {
    //   const pathName = `/#/operation-login?redirectUrl=${encodeURIComponent(path)}`;
    //   location.href = pathName;
    //   History.replace(pathName);
    // }
  } else if (
    (handleError && handleError === 'all') ||
    (Array.isArray(handleError) && handleError.some((errCode) => `${errCode}` === `${code}`))
  ) {
    // 自定义处理错误码
    resolve(response);
  } else {
    // 自动处理错误提示
    FunToast.show(response.msg, 2);;
    reject(response);
  }
}
