import { Component } from "react";
import FunLayout, { EnLayoutType } from "../../components/fun-layout";
import Header from "../../components/header";
import userCameraImg from "../../public/images/icon-user-camera.png";
import userImg from "../../public/images/icon-user-avatar-default.png";
import Image from "../../components/image";
import FunIcon from "../../components/fun-icon";
import Footer from "../../components/footer";
import TabBar from "../../components/tab-bar";
import { Tabs } from "../../common/constant";
import FileUpload from "../../components/file-upload";
import ReactLoading from 'react-loading';
import upload from "../../common/upload";
import FunToast from "../../components/fun-toast";
import Utils from "../../common/utils";
import model from './model';

import "./index.scss";
import { eshiminSetTitleStyle } from "../../common/eshiminUtils";
import LocalStore from "../../store/localStore";
import classNames from "classnames";
import Platform from "../../common/platform";
import { VipInfo } from "../../components/vip-info";

const $primaryColor = '#CBB486';
const $warnColor = '#E64736';
const $textColor = '#2E2E2E';
const list = [
  { id: 1, icon: 'icon-consultation', color: $primaryColor, itemText: '我的消息', itemTextColor: $textColor },

  // { id: 1, icon: 'icon-expert-reservation', color: $primaryColor, itemText: '我的专家预约', itemTextColor: $textColor },
  // { id: 2, icon: 'icon-activity', color: $primaryColor, itemText: '我的参与活动', itemTextColor: $textColor },
  { id: 2, icon: 'icon-consultation', color: $primaryColor, itemText: '进度查询', itemTextColor: $textColor },
  { id: 3, icon: 'icon-unCollect', color: $primaryColor, itemText: '我的收藏', itemTextColor: $textColor },
  { id: 4, icon: 'icon-lock', color: $primaryColor, itemText: '修改密码', itemTextColor: $textColor },
];
export default class Mine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyUserInfo: {},
      avatarUrls: [],
      loading: false,
      list,
    };
    LocalStore.set('shouldIntercept', true);
  }

  componentDidMount() {
    eshiminSetTitleStyle('个人中心');
    console.log('mine login:', Utils.hasLogin());
    if (!Utils.hasLogin()) {
      return;
    }
    this.onGetUserInfo();
    const { list } = this.state;
    if (list.filter(item => item.itemText === '退出系统').length <= 0) {
      list.push({ id: list.length + 1, icon: 'icon-logout', color: $warnColor, itemText: '退出系统', itemTextColor: $warnColor });
    }
    const startDate = LocalStore.get('currentDate');
    const hour = this.calculateHoursDifference(startDate, new Date());
    if (hour > 72) {
      list.map(item => {
        return item.showRedDot = item.itemText === '我的消息'
      });
    }
    this.setState({ list });
  }

  onGetUserInfo() {
    model.getCompanyUserInfo().then(res => {
      this.setState({ companyUserInfo: res || {} });
    });
  }

  getMessageList() {
    model.getMessageList().then(res => {
      let { total = 0 } = res;
      const { list } = this.state;
      list.map(item => {
        return item.showRedDot = total > 0;
      });

      // this.setState({ companyUserInfo: res || {} });
    });
  }

  onHeadClick(e) {
    if (!Utils.hasLogin()) {
      e.stopPropagation();
      e.preventDefault();
      Utils.goLogin();
    }
  }

  calculateHoursDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const difference = Math.abs(end - start);
    const hoursDifference = Math.ceil(difference / (1000 * 60 * 60));
    return hoursDifference;
  };

  onItemClick(itemText) {
    let routeUrl = '';
    switch (itemText) {
      case '我的消息':
        routeUrl = `/message-list`;
        LocalStore.set('currentDate', new Date());
        break;
      case '我的专家预约':
        routeUrl = '/my-expert-reservation';
        break;
      case '我的参与活动':
        routeUrl = '/my-activity';
        break;
      case '进度查询':
        routeUrl = '/my-consultation';
        break;
      case '我的收藏':
        routeUrl = '/my-collect';
        break;
      case '修改密码':
        routeUrl = `/forget-pwd?type=${1}&title=修改密码`;
        break;
      case '退出系统':
        this.onLogout();
        break;
      default:
        break;
    }
    if (!Utils.hasLogin()) {
      Utils.goLogin(routeUrl);
      return;
    }
    this.props.history.push(routeUrl);
  }

  onLogout() {
    this.showLoading();
    model.logout().then(() => {
      if (list.filter(item => item.itemText === '退出系统').length > 0) {
        const itemList = list.splice(list.length - 1, 1);
        this.setState({ list: itemList });
      }

      Utils.setLogin(false);
      this.hideLoading();
      this.props.history.push('/home');
    }).then(() => {
      this.hideLoading();
    });
  }

  onUploadAvatar(files) {
    if (!files?.length) {
      this.setState({ licenseUrls: [] });
      return;
    }
    this.showLoading();
    const file = files[0];
    upload.uploadImg(file).then(res => {
      this.hideLoading();
      const { code, data: { scr }, msg } = res;
      if (code === 200 && scr) {
        model.updateAvatar({ avatar: scr }).then(() => {
          this.onGetUserInfo();
        });
      } else {
        FunToast.show(msg);
      }
    }).catch(() => {
      this.hideLoading();
    });
  }

  onEditProfile() {
    if (!Utils.hasLogin()) {
      Utils.goLogin();
      return;
    }
    const { companyUserInfo: { avatar_url, name, company_id, companyName } } = this.state;
    this.props.history.push(`/edit-profile?avatarUrl=${avatar_url}&name=${name}&companyId=${company_id}&companyName=${companyName}`);
  }

  showLoading() {
    this.setState({ loading: true });
  }

  hideLoading() {
    this.setState({ loading: false });
  }

  render() {
    const {
      companyUserInfo: {
        avatar_url = '',
        name = '',
      } = {},
      avatarUrls,
      loading,
      list,
    } = this.state;
    return (
      <div className="mine-container">
        {loading && <div className="mine-loading">
          <ReactLoading type="bars" color="#CBB486" width={'10%'} />
        </div>}
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <div className="top">
            <Header hidden={false} hasBack={false} isNoBorder>
              个人中心
            </Header>
            <div className="mine-head-container">
              <div className="mine-head-user" onClick={this.onHeadClick.bind(this)}>
                <FileUpload
                  fileList={(avatarUrls || []).map((item) => ({
                    url: Utils.getUrl(item),
                  }))}
                  selectable={!avatarUrls.length}
                  onChange={this.onUploadAvatar.bind(this)}
                  accept="image/jpeg,image/jpg,image/png"
                  FileListItem={Image}
                  fileListItemClassName="mine-avatar-upload-img"
                  uploadComponent={
                    <div className="mine-avatar">
                      <Image scale={154 / 154} src={Utils.getUrl(avatar_url) ? Utils.getUrl(avatar_url) : userImg} />
                      <div className="mine-avatar-camera">
                        <Image scale={54 / 54} src={userCameraImg} />
                      </div>
                    </div>
                  }
                />

                <span className="mine-user-name">{name}</span>
                {/* <span className="mine-company-name">{companyName}</span> */}
              </div>
              <VipInfo
                className="vip-info"
                companyUserInfo={this.state.companyUserInfo}
                history={this.props.history}
              />

              {/* <div className="mine-edit-profile-container" onClick={this.onEditProfile.bind(this)}>
                <span className="mine-edit-profile">编辑资料</span>
                <FunIcon icon="icon-arrow-right" iconStyle={{ color: '#f15d61' }} />
              </div> */}
            </div>
          </div>

          <div className={classNames("mine-content-container", Platform.isApp ? "mine-content-top" : "")}>
            {list.map(item => {
              const { id, icon, itemText, showRedDot, color, itemTextColor } = item;
              return (
                <div key={id} className="mine-item-container" onClick={() => this.onItemClick(itemText)}>
                  <FunIcon icon={icon} iconStyle={{ color: color, marginTop: itemText === '修改密码' ? '4px' : '' }} />
                  <span className="mine-item" style={{ color: itemTextColor, marginLeft: itemText === '修改密码' ? '10px' : '' }}>{itemText}</span>
                  {showRedDot && <div className='red-dot' />}
                  <FunIcon icon="icon-arrow-right" />
                </div>
              );
            })}
          </div>

          <Footer>
            <TabBar activeIndex={2} items={Tabs} history={this.props.history} />
          </Footer>
        </FunLayout>
      </div>
    );
  }
}