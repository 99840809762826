import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Footer from '../footer';
import Link from '../link';

import './index.scss';
import FunIcon from '../fun-icon';

/**
 * 页面置底的Tab bar（一般显示于一级页面，用于切换频道）
 */
export default class TabBar extends Component {
  constructor(props) {
    super(props);
    this.items = [
      {
        text: '首页',
        path: `/home`,
        icon: 'home'
      },
      {
        text: '政策',
        path: `/policy-list`,
        icon: 'policy'
      },
      {
        text: '我的',
        path: `/mine`,
        icon: 'mine'
      },
    ];
  }

  render() {
    let { activeIndex, isTopLevel } = this.props;
    const { items } = this.props;
    return (
      <Footer bgColor="#FFF">
        <div className="fun-tabbar">
          {
            items.map((item, index) => {
              const isActive = index === activeIndex;
              const icon = 'icon-' + item.icon;

              return (
                <Link
                  className={classNames('tab-link', isActive ? 'active' : '')}
                  key={index}
                  href={item.path}
                  history={this.props.history}
                  onClick={() => !isActive || !isTopLevel}>
                  <FunIcon
                    icon={icon}
                    iconStyle={{ color: isActive ? '#CBB486' : '#888888', fontSize: '20px' }}
                  />
                  <div className="tab-text">{item.text}</div>
                </Link>
              )
            })
          }
        </div>
      </Footer>
    );
  }
}

TabBar.propTypes = {
  className: PropTypes.string, // 自定义 class
  activeIndex: PropTypes.number, // 当前选中的索引
  isTopLevel: PropTypes.bool // 是否出现在一级页面中
};

TabBar.defaultProps = {
  activeIndex: 0,
  isTopLevel: true
}