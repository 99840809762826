import { request } from '../../http';
class Model {
  addCompany(data) {
    return request('/company/add/', {
      method: 'POST',
      data,
    });
  }
}

export default new Model();
