import { request } from '../../http';
class Model {
  getSupplierList(searchContent, serviceType, page, pageSize) {
    return request(`/supplier/list?searchContent=${searchContent}&serviceType=${serviceType}&page=${page}&pageSize=${pageSize}`, {
      method: 'GET',
    });
  }
}

export default new Model();
