import { Component } from "react";
import classNames from "classnames";
import CommonButton from "@/components/common-button";
import FunIcon from "@/components/fun-icon";
import './index.scss';
export default class PolicyItem extends Component {
  onItemClick(item) {
    const { onPolicyClick } = this.props;
    if (onPolicyClick) {
      onPolicyClick(item);
    }
  }

  onAnalyze(id) {
    const { onAnalyze } = this.props;
    if (onAnalyze) {
      onAnalyze(id);
    }
  }
  title
  onApplyFunc(id, type, content) {
    const { onApply } = this.props;
    if (onApply) {
      onApply(id, type, content);
    }
  }

  onConsult(policyId, type) {
    this.props.history.push(`/comminicate?policyId=${policyId}&type=${type}`);
  }

  render() {
    const { policyList = [], type } = this.props;
    return (
      <div>
        {policyList && policyList.map(item => {
          const { id, title, content, create_at, leftDate = 0, analyzes = [] } = item;
          return (
            <div key={id} className="policy-list-item" onClick={() => this.onItemClick(item)}>
              <span className="policy-title">{title}</span>
              <div
                className="original-content"
                dangerouslySetInnerHTML={{
                  __html:
                    ((content && content.length >= 50) ? (content.substr(0, 50) + '...') : content).replace(/(\r\n|\n|\r)/gm, '<br />'),
                }}
              />
              {/* <span className="policy-content">{(content && content.length >= 50) ? (content.substr(0, 50) + '...') : content}</span> */}
              {
                type === 1 &&
                <div className="policy-list-item-bottom">
                  <div className="policy-time-container">
                    <FunIcon icon={'icon-date'} />
                    <span className="policy-create-time">{create_at}</span>
                  </div>
                  <CommonButton
                    className='policy-consult-btn'
                    icon={'icon-mail'}
                    text={'咨询'}
                    onClick={() => this.onConsult(id, 2)}
                    type='primary'
                  />
                  <CommonButton
                    className='policy-analyze-btn'
                    icon='icon-analyze-policy'
                    text='解读'
                    type={analyzes.length > 0 ? 'primary' : 'disabled'}
                    onClick={() => this.onAnalyze(id)}
                  />
                </div>
              }

              {
                type === 2 &&
                <div className="policy-list-item-bottom">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FunIcon
                      icon={leftDate > 0 ? 'icon-policy-applying' : 'icon-policy-apply-end'}
                      iconStyle={{ color: leftDate > 0 ? '#CBB486' : '#808080' }}
                    />
                    <span className={classNames("policy-status-des", leftDate > 0 ? "policy-status-applying" : "policy-status-end")}>
                      {leftDate > 0 ? `申报中：${leftDate}天` : '已终止'}
                    </span>
                  </div>

                  <div style={{ display: 'flex' }}>
                    {leftDate > 0 && <CommonButton
                      className='policy-consult-btn'
                      style={{ marginRight: '10px' }}
                      icon='icon-policy-applying'
                      text='申报'
                      type='primary'
                      onClick={() => this.onApplyFunc(id, 3, title)}
                    />
                    }
                    <CommonButton
                      className='policy-consult-btn'
                      icon='icon-mail'
                      text='咨询'
                      type={leftDate > 0 ? 'primary' : 'disabled'}
                      onClick={() => this.onConsult(id, 3)}
                    />
                  </div>
                </div>
              }

            </div>
          );
        })}
      </div>
    );
  }
}