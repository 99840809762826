import React, { Component } from 'react'
import FunLayout, { EnLayoutType } from '../../components/fun-layout';
import Header from '../../components/header';
import classNames from 'classnames';
import FunIcon from '../../components/fun-icon';
import CommonButton from '../../components/common-button';
import Tabs from '../../components/tabs';
import FunDialog from '../../components/fun-dialog';
import TabBar from '../../components/tab-bar';
import Footer from '../../components/footer';
import { OperationTabs } from '../../common/constant';
import model from './model';

import './index.scss'
import FunToast from '../../components/fun-toast';

const tabs = [
  { tabName: '进行中', active: true },
  { tabName: '已完成', active: false },
];

export default class TaskList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taskList: [],
      tabs,
      status: 0,
    };
  }

  componentDidMount() {
    const { status } = this.state;
    this.onGetTaskList(status);
  }

  onGetTaskList(status) {
    model.getTaskList(status).then(res => {
      const { data = [] } = res;
      this.setState({ taskList: data });
    });
  }

  onTabChange(tabName) {
    const { tabs } = this.state;
    tabs.forEach(item => {
      item.active = tabName === item.tabName;
    });
    this.setState({ tabs });
  }

  // 任务tab切换
  onChangeStatus(index) {
    this.onGetTaskList(index);
    this.setState({
      status: index,
    });
  }

  // 处理进行中的任务
  onDealTask(id) {
    FunDialog.show({
      title: '任务处理',
      message: '请仔细确认已完成任务',
      onConfirm: () => {
        model.finishTask({ id }).then(() => {
          FunToast.show('处理成功');
          const { status } = this.state;
          this.onGetTaskList(status);
        });
      },
      cancelText: '取消',
      confirmText: '完成',
      toggle: true,
    });
  }

  render() {
    const { taskList = [], status } = this.state;
    return (
      <div className="task-list-container">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <div className={classNames('home-top-container', 'top')}>
            <Header hidden={false} hasBack={false} isNoBorder>
              任务列表
            </Header>
            {/* <div className="task-tab-container">
              {tabs.map(item => {
                const { tabName, active } = item;
                return (
                  <div className="task-tab-item-container" onClick={() => this.onTabChange(tabName)}>
                    <span className={classNames("task-tab-item", { 'active': active })}>{tabName}</span>
                    <div className={classNames("task-tab-indicator", { 'active': active })} />
                  </div>
                );
              })}
            </div> */}

            <Tabs
              className='task-list-tabs'
              items={['进行中', '已完成']}
              onChange={this.onChangeStatus.bind(this)}
            />
          </div>

          <div className="task-list-item-container">
            {taskList && taskList.map(item => {
              const { id, title, content, create_at } = item;
              return (
                <div className="task-list-item" key={id}>
                  <span className="task-list-title">{title}</span>
                  <span className="task-list-content">{content}</span>
                  <div className="task-list-bottom">
                    <div className="task-list-create-time-container">
                      <FunIcon icon='icon-date' />
                      <span className="task-list-create-time">{create_at}</span>
                    </div>
                    {
                      status === 0 &&
                      <CommonButton
                        icon='icon-deal-with'
                        text='去处理'
                        type='primary'
                        onClick={() => this.onDealTask(id)}
                      />
                    }
                  </div>
                </div>
              );
            })}
          </div>
          <Footer>
            <TabBar activeIndex={1} items={OperationTabs} history={this.props.history} />
          </Footer>
        </FunLayout>
      </div>
    )
  }
}
