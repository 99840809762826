import { request } from '../../http';
class Model {
  getCompanyUserInfo(data) {
    return request('/company/personal', {
      method: 'POST',
      data,
    });
  }

  getMemberRight(data) {
    return request('/config/member_right', {
      method: 'POST',
      data,
    });
  }

  applyMember(data) {
    return request('/company/member/apply', {
      method: 'POST',
      data,
    });
  }

  useService(data) {
    return request('/company/consultation/member', {
      method: 'POST',
      data,
    });
  }
}

export default new Model();
