import React, { Component } from "react";
import FunCommonInput, { EnDataType } from "../../components/common-input";
import FunButton, { EnButtonSize, EnButtonType } from "../../components/fun-button";
import FunIcon from "../../components/fun-icon";
import FunLayout, { EnLayoutType } from "../../components/fun-layout";
import FunPicker from "../../components/fun-picker";
import Header from "../../components/header";
import { IndustryList, LocationList, LocationList1 } from "../../common/constant";
import FunToast from "../../components/fun-toast";
import DatePicker from '../../components/DatePicker';
import model from './model';
import Utils from "../../common/utils";

import './index.scss';
import { eshiminSetTitleStyle } from "../../common/eshiminUtils";
// 租赁用途
const leaseUseList = [
  { id: 1, name: '工位', checked: true },
  { id: 2, name: '办公', checked: false },
  { id: 3, name: '商业', checked: false },
  { id: 4, name: '其他', checked: false },
];

// 办公楼面积
const areaList = [
  { id: 1, name: '50m²以下', checked: false },
  { id: 2, name: '50~100m²', checked: false },
  { id: 3, name: '100~150m²', checked: false },
  { id: 4, name: '150m²以上', checked: false },
];

// 租金预算
const rentList = [
  { id: 1, name: '2万元以下', checked: false },
  { id: 2, name: '2~5万元', checked: false },
  { id: 3, name: '5~10万元', checked: false },
  { id: 4, name: '10万元以上', checked: false },
];

// 工位租金
const stationRentList = [
  { id: 1, name: '1000元以下', checked: false },
  { id: 2, name: '1000元~1500元', checked: false },
  { id: 3, name: '1600元~2000元', checked: false },
  { id: 4, name: '2000元以上', checked: false },
];

// 工位数量
const stationCountList = [
  { id: 1, name: '1-5个', checked: false },
  { id: 2, name: '6-10个', checked: false },
  { id: 3, name: '11-20个', checked: false },
  { id: 4, name: '20个以上', checked: false },
];

export default class CompanyLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leaseUseId: 1,
      industryName: '',
      location: '',
      leaseUseList,
      areaList,
      rentList,
      stationRentList,
      stationCountList,
      rentStartDateProp: '99-0-0',
      rentStartDate: '',
    };
    this.industryPickerRef = React.createRef();
    this.locationPickerRef = React.createRef();
    this.timePickerRef = React.createRef();
    this.form = {
      chooseAddrContent: '',
      chooseAddrType: '',
      employeeId: '',
    };
    this.pageForm = {
      name: '',
      mobile: '',
      companyName: '',
      industryName: '',
      rentStartTime: '',
      rentDate: '',
      leaseUse: '',
      location: '',
      area: '',
      account: '',
      remark: '',

      floor: '',
      smoke: '',
      water: '',
      energy: '',

      stationRent: '',
      stationNumber: '',
    }
  }

  componentDidMount() {
    if (Utils.hasLogin()) {
      model.getStaff().then(res => {
        const { id } = res;
        this.form.employeeId = id;
      });
    }
    eshiminSetTitleStyle('企业选址');
  }

  rendeRadioChoice = (list, className, onItemCheck) => {
    return <div className={className ? className : "company-location-lease-use-container"}>
      {list.map(item => {
        const { id, name, checked } = item;
        return <div className="company-location-lease-use" key={id} onClick={() => onItemCheck(id)}>
          <FunIcon icon={checked ? 'icon-checked-solid' : 'icon-ring'} iconStyle={{ color: checked && '#CBB486', fontSize: '20px' }} iconFontSize={20} />
          <span className="company-location-lease-use-text">{name}</span>
        </div>;
      })}
    </div>;
  }

  // 租赁用途
  onLeaseUseCheck = (id) => {
    const { leaseUseList = [], areaList = [], rentList = [], leaseUseId, location } = this.state;
    leaseUseList.map(item => {
      return item.checked = item.id === id;
    });

    const res = leaseUseList.filter(item => item.id === id);
    this.pageForm.leaseUse = (res && (res.length > 0) && res[0].name) || '';

    areaList.forEach(item => item.checked = false);
    rentList.forEach(item => item.checked = false);

    const selectLeaseUseId = res && (res.length > 0) && res[0].id;
    this.setState({
      leaseUseList,
      leaseUseId: selectLeaseUseId,
      areaList,
      rentList,
      location: leaseUseId === selectLeaseUseId ? location : '',
    });
  }

  onStationRentCheck = (id) => {
    const { stationRentList = [] } = this.state;
    stationRentList.map(item => {
      return item.checked = item.id === id;
    });
    const res = stationRentList.filter(item => item.id === id);
    this.pageForm.stationRent = (res && (res.length > 0) && res[0].name) || '';
    this.setState({ stationRentList });
  }

  onStationCountCheck = (id) => {
    const { stationCountList = [] } = this.state;
    stationCountList.map(item => {
      return item.checked = item.id === id;
    });
    const res = stationCountList.filter(item => item.id === id);
    this.pageForm.stationNumber = (res && (res.length > 0) && res[0].name) || '';
    this.setState({ stationCountList });
  }

  onAreaCheck = (id) => {
    const { areaList = [] } = this.state;
    areaList.map(item => {
      return item.checked = item.id === id;
    });
    const res = areaList.filter(item => item.id === id);
    this.pageForm.area = (res && (res.length > 0) && res[0].name) || '';
    this.setState({ areaList });
  }

  onRentCheck = (id) => {
    const { rentList = [] } = this.state;
    rentList.map(item => {
      return item.checked = item.id === id;
    });
    const res = rentList.filter(item => item.id === id);
    this.pageForm.account = (res && (res.length > 0) && res[0].name) || '';
    this.setState({ rentList });
  }

  onShowDate = () => {
    if (this.timePickerRef.current) {
      this.timePickerRef.current.showMe();
    }
  }

  handleCloseDate = () => { }

  handleEnsureDate = (value) => {
    let [year, month, date] = value;
    const rentStartDateProp = [year, month, date].join('-');
    const now = new Date();
    const currentYear = now.getFullYear();
    year = currentYear - 100 + year + 1;
    date++;
    month = ('00' + (month + 1)).substr(-2);
    date = ('00' + date).substr(-2);

    const rentStartDate = `${year}-${month}-${date}`;
    this.pageForm.rentStartTime = rentStartDate;
    this.setState({ rentStartDateProp, rentStartDate })
  }

  onStationRemarkChange(e) {
    const target = e ? e.target : this.profileRef.current;
    const value = target.value || '';
    this.pageForm.remark = value;
  }

  onOfficeRemarkChange(e) {
    const target = e ? e.target : this.profileRef.current;
    const value = target.value || '';
    this.pageForm.remark = value;
  }

  onBusinessRemarkChange(e) {
    const target = e ? e.target : this.profileRef.current;
    const value = target.value || '';
    this.pageForm.remark = value;
  }

  onChangeField(field, value) {
    if (field === 'name') {
      this.pageForm.name = value;
    } else if (field === 'mobile') {
      this.pageForm.mobile = value;
    } else if (field === 'companyName') {
      this.pageForm.companyName = value;
    } else if (field === 'rentDate') {
      this.pageForm.rentDate = value;
    } else if (field === 'rentStartTime') {
      this.pageForm.rentStartTime = value;
    } else if (field === 'floor') {
      this.pageForm.floor = value;
    } else if (field === 'smoke') {
      this.pageForm.smoke = value;
    } else if (field === 'water') {
      this.pageForm.water = value;
    } else if (field === 'energy') {
      this.pageForm.energy = value;
    }
  }

  onShowIndustrySelect = () => {
    this.industryPickerRef.current.show();
  }

  // 行业选择
  onSelectIndustry = (_, value) => {
    const { id, name } = value;
    this.pageForm.industryName = name;
    this.setState({ industryName: name });
  }

  onShowLocationSelect = () => {
    this.locationPickerRef.current.show();
  }

  // 位置选择
  onSelectLocation = (_, value) => {
    const { name } = value;
    this.pageForm.location = name;
    this.setState({ location: name });
  }

  onCheckParam() {
    const { name, mobile, companyName, industryName, rentStartTime, rentDate, leaseUse, location } = this.pageForm;
    if (!name) {
      return '请输入姓名';
    }
    if (!mobile) {
      return '请输入手机号码';
    }
    if (Utils.checkMobile(mobile)) {
      return '请输入正确的手机号码';
    }
    if (!companyName) {
      return '请输入公司/项目名';
    }
    if (!industryName) {
      return '请选择行业';
    }
    if (!rentStartTime) {
      return '请选择起租时间';
    }
    if (!rentDate) {
      return '请输入租赁年限';
    }
    if (!leaseUse) {
      return '请选择租赁用途';
    }
    if (!location) {
      return '请选择位置区域';
    }
  }

  /***
   * 提交问卷结果，提交到管理端的工单列表
   * 类型是企业选址
   * 内容是表达内容，每个选择是独立一行，格式：问题：答案；
   * 负责人是企业负责人，如果没有，那么分配给默认负责人
   */
  onSubmit() {
    if (!Utils.hasLogin()) {
      Utils.goLogin();
      return;
    }
    const { leaseUseList } = this.state;
    let leaseUseId;
    const selectLeaseUse = leaseUseList.filter(item => item.checked);
    if (selectLeaseUse && selectLeaseUse.length > 0) {
      this.pageForm.leaseUse = selectLeaseUse[0].name;
      leaseUseId = selectLeaseUse[0].id;
    }
    const error = this.onCheckParam();
    if (error) {
      return FunToast.show(error, 2);
    }

    let content;
    const {
      name,
      mobile,
      companyName,
      industryName,
      rentStartTime,
      rentDate,
      leaseUse,
      location,
      area,
      account,
      remark,

      floor,
      smoke,
      water,
      energy,

      stationRent,
      stationNumber,
    } = this.pageForm;

    content = `姓名：${name};手机：${mobile};公司/项目名：${companyName};行业：${industryName};起租时间：${rentStartTime};租赁年限：${rentDate};租赁用途：${leaseUse};位置区域：${location};`
    if (leaseUseId === 1) {
      content = content + `工位租金：${stationRent};工位数量：${stationNumber};特殊要求：${remark};`
    } else if (leaseUseId === 2) {
      content = content + `办公楼建筑面积：${area};租金预算：${account};特殊要求：${remark};`
    } else if (leaseUseId === 3) {
      content = content + `商铺面积：${area};租金预算：${account};楼层要求：${floor};排烟量：${smoke};上下水：${water};用电量：${energy};特殊要求：${remark};`
    }

    this.form.chooseAddrContent = content;
    console.log(this.form.chooseAddrContent)

    model.submitQuestion(this.form).then(() => {
      FunToast.show('提交成功')
      this.props.history.goBack();
    });
  }

  render() {
    const {
      leaseUseList = [],
      leaseUseId,
      industryName,
      location,
      rentStartDate,
      rentStartDateProp
    } = this.state;
    return (
      <div className="company-location-container">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <Header className="top" hidden={false} hasBack history={this.props.history}>
            企业选址
          </Header>

          <div className="company-location-container">
            <FunCommonInput
              maxLength={100}
              dataType={EnDataType.none}
              placeholder={'姓名'}
              icon={'icon-user'}
              required
              onChange={value => this.onChangeField('name', value)}
            />
            <FunCommonInput
              maxLength={11}
              type={'number'}
              dataType={EnDataType.none}
              placeholder={'手机'}
              icon={'icon-mobile'}
              required
              onChange={value => this.onChangeField('mobile', value)}
            />
            <FunCommonInput
              dataType={EnDataType.none}
              placeholder={'公司/项目名'}
              required
              onChange={value => this.onChangeField('companyName', value)}
            />
            <FunCommonInput
              dataType={EnDataType.none}
              placeholder={'行业'}
              icon={'icon-arrow-down-filling'}
              defaultValue={industryName}
              required
              onClick={this.onShowIndustrySelect}
              onChange={this.onSelectIndustry}
            />

            <FunCommonInput
              dataType={EnDataType.none}
              placeholder={'起租时间'}
              icon={'icon-arrow-down-filling'}
              defaultValue={rentStartDate}
              required
              onClick={this.onShowDate}
            />

            <FunCommonInput
              dataType={EnDataType.none}
              placeholder={'租赁年限'}
              required
              onChange={value => this.onChangeField('rentDate', value)}
            />

            <div className="company-location-lease-use-title-container">
              <span className="company-location-lease-use-title">租赁用途</span>
            </div>
            {this.rendeRadioChoice(leaseUseList, '', this.onLeaseUseCheck)}

            {leaseUseId !== 4 &&
              <FunCommonInput
                dataType={EnDataType.none}
                placeholder={'位置区域'}
                icon={'icon-arrow-down-filling'}
                defaultValue={location}
                required
                onClick={this.onShowLocationSelect}
                onChange={this.onSelectLocation}
              />}

            {leaseUseId === 1 &&
              <div>
                <span className="company-location-bold-text">工位租金</span>
                {this.rendeRadioChoice(stationRentList, "company-location-area-container", this.onStationRentCheck)}

                <span className="company-location-bold-text">工位数量</span>
                {this.rendeRadioChoice(stationCountList, "company-location-area-container", this.onStationCountCheck)}

                <textarea
                  ref={this.textRef}
                  style={{ resize: 'none' }}
                  placeholder='特殊需求'
                  maxLength={500}
                  cols="30"
                  rows="6"
                  onChange={this.onStationRemarkChange.bind(this)}
                />
              </div>
            }

            {leaseUseId === 2 &&
              <div>
                <span className="company-location-bold-text">办公楼建筑面积</span>
                {this.rendeRadioChoice(areaList, "company-location-area-container", this.onAreaCheck)}

                <span className="company-location-bold-text">租金预算</span>
                {this.rendeRadioChoice(rentList, "company-location-area-container", this.onRentCheck)}

                <textarea
                  ref={this.textRef}
                  style={{ resize: 'none' }}
                  placeholder='特殊需求（例如：高楼层、精装修）'
                  maxLength={500}
                  cols="30"
                  rows="6"
                  onChange={this.onOfficeRemarkChange.bind(this)}
                />
              </div>
            }

            {leaseUseId === 3 &&
              <div>
                <span className="company-location-bold-text">商铺面积</span>
                {this.rendeRadioChoice(areaList, "company-location-area-container", this.onAreaCheck)}

                <span className="company-location-bold-text">租金预算</span>
                {this.rendeRadioChoice(rentList, "company-location-area-container", this.onRentCheck)}
                <FunCommonInput
                  dataType={EnDataType.none}
                  placeholder={'楼层要求'}
                  onChange={value => this.onChangeField('floor', value)}
                />
                <FunCommonInput
                  dataType={EnDataType.none}
                  placeholder={'排烟量'}

                  onChange={value => this.onChangeField('smoke', value)}
                />
                <FunCommonInput
                  dataType={EnDataType.none}
                  placeholder={'上下水'}
                  onChange={value => this.onChangeField('water', value)}
                />
                <FunCommonInput
                  dataType={EnDataType.none}
                  placeholder={'用电量'}
                  onChange={value => this.onChangeField('energy', value)}
                />
                <textarea
                  ref={this.textRef}
                  style={{ resize: 'none' }}
                  placeholder='特殊需求（例如：加装独立空调）'
                  maxLength={500}
                  cols="30"
                  rows="6"
                  onChange={this.onBusinessRemarkChange.bind(this)}
                />
              </div>
            }

            {leaseUseId === 4 &&
              <textarea
                ref={this.textRef}
                style={{ resize: 'none' }}
                placeholder='请输入补充信息'
                maxLength={500}
                cols="30"
                rows="6"
                onChange={this.onStationRemarkChange.bind(this)}
              />}

            <FunButton
              className="company-location-submit"
              type={EnButtonType.primary}
              size={EnButtonSize.big}
              onClick={this.onSubmit.bind(this)}
            >
              提交
            </FunButton>
          </div>
        </FunLayout>
        <FunPicker
          onChange={this.onSelectIndustry.bind(this)}
          ref={this.industryPickerRef}
          title="选择所属行业"
          displayFiled="name"
          valueFiled="id"
          defaultValue={industryName}
          data={[{
            options: IndustryList
          }]}
        />
        <FunPicker
          onChange={this.onSelectLocation.bind(this)}
          ref={this.locationPickerRef}
          title="选择位置区域"
          displayFiled="name"
          valueFiled="id"
          defaultValue={""}
          data={[{
            options: leaseUseId === 1 ? LocationList : LocationList1
          }]}
        />
        <DatePicker
          title={"选择起租日期"}
          ref={this.timePickerRef}
          initValues={rentStartDateProp}
          cancelHandle={this.handleCloseDate}
          ensureHandle={this.handleEnsureDate}
        />
      </div>
    );
  }
}