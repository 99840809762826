import { request } from '../../http';
class Model {
  queryBannerList(data) {
    return request('/banner/list', {
      method: 'POST',
      data,
    });
  }

  queryInformationList(page = 1, pageSize = 15) {
    return request(`/info/list?page=${page}&pageSize=${pageSize}`, {
      method: 'GET',
    });
  }

  queryQuestionList(data) {
    return request('/qa/list', {
      method: 'POST',
      data,
    });
  }

  getCompanyUserInfo(data) {
    return request('/company/personal', {
      method: 'POST',
      data,
    });
  }
}

export default new Model();
