import History from '../common/url/history';
import CookieApi from "./cookieApi";
import Platform from './platform';
import LocalStore from '../store/localStore';
import { requestUrl, env } from '../../src/app.json';

export const FUN_APP_SIGN = "SceApp";
export const WEIXIN_APP_SIGN = 'microMessenger';

export default class Utils {

  /**
   *
   * @param {string} str 处理的字符串
   * @param {boolean} isStart 是否从首字符开始
   * @returns {string} 下划线转化为驼峰命名规格 an_apple 返回anApple/anApple
   */
  static transToUppercase(str, isStart = false) {
    if (isStart) {
      let $0 = str.charAt(0);
      if ((/[a-z]/i).test($0)) {
        str = str.replace($0, $0.toUpperCase());
      }
    }

    return str.replace(/_(\w)/g, function ($0, $1) {
      if ((/[a-z]/i).test($1)) {
        return $1.toUpperCase();
      } else {
        return $0;
      }
    });
  }


  /**
   * 检测客户端机型和环境
   * @returns {{isFunApp: boolean, isWx: boolean, isIos: boolean, isAndroid: boolean}}
   */
  static getClient() {
    let result, userAgent;

    result = {
      isFunApp: false,
      isWx: false,
      isIos: false,
      isAndroid: false
    };

    userAgent = window.navigator.userAgent.toLowerCase();

    // 是否为公司app中
    if (userAgent.indexOf(FUN_APP_SIGN.toLocaleLowerCase()) > -1) {
      result.isFunApp = true;
    } else {

      // 判断是否为微信
      if (userAgent.indexOf(WEIXIN_APP_SIGN.toLocaleLowerCase()) > -1) {
        result.isWx = true;
      }
    }

    // 判断系统
    if (userAgent.indexOf('android') > -1 || userAgent.indexOf('linux') > -1) {
      //安卓手机
      result.isAndroid = true;
    } else if (userAgent.indexOf('iphone') > -1) {
      //苹果手机
      result.isIos = true;
    } else if (userAgent.indexOf('windows phone') > -1) {
      //winphone手机
      result.isWindowsPhone = true;
    }
    return result;
  }

  static transToUnderline(str) {
    return str.replace(/([A-Z])/g, "_$1").toLowerCase();
  }

  static getStyle(t) {
    return t.currentStyle ? t.currentStyle : getComputedStyle(t);
  }

  // 检验手机号
  static checkMobile(str) {
    let reg = /^[1][0-9]{10}$/;
    if (!str || !reg.test(str)) {
      return "请输入11位数手机号";
    }
  }


  // 校验短信验证码
  static checkMobileCode(str) {
    let reg = /^\d{6}$/;
    if (str.length !== 6) {
      if (!str || !reg.test(str)) {
        return "请填写有效验证码";
      }
    }
  }

  // 校验密码
  // 密码需为至少8位，不设置上限，必须包含数字、字母
  static checkPassword(str) {
    let error, regNumber, regString, regString1;
    regNumber = /\d+/; //验证0-9的任意数字最少出现1次。
    regString = /[a-z]+/; //验证大小写26个字母任意字母最少出现1次。
    regString1 = /[A-Z]+/; //验证大小写26个字母任意字母最少出现1次。

    if (!str || str.length < 8) {
      error = "密码长度不能低于8位";
    }
    //  else if (str.length > 20) {
    //   error = "密码的长度不能大于20";
    // }
    else if (!regString.test(str) || !regString1.test(str) || !regNumber.test(str)) {
      error = "密码必须同时包含大小写字母和数字";
    }
    return error;
  }


  static createRandomChars(len) {
    let maxPos, pwd, i, $chars;

    len = len || 32;
    $chars = `ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678`;
    pwd = '';
    maxPos = $chars.length;

    for (i = 0; i < len; i++) {
      pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
  }

  // 获取谷歌浏览器版本
  static getChromeVersion() {
    let arr = window.navigator.userAgent.split(' ');
    let chromeVersion = '';
    for (let i = 0; i < arr.length; i++) {
      if (/chrome/i.test(arr[i])) {
        chromeVersion = arr[i];
        break;
      }
    }

    // 获取版本号
    if (chromeVersion) {
      return Number(chromeVersion.split('/')[1].split('.')[0]);
    } else {
      return 10000;
    }
  }


  /**
   *
   * @param date 日期 number/date/string
   * @param fmt 格式 yyyy-MM-dd hh:mm:ss
   * @returns {*} 返回字符串
   */
  static formatDate(date, fmt = "yyyy-MM-dd hh:mm:ss") {
    if (!date) {
      return date || '';
    }

    if (typeof date === "string") {
      //解决ios手机时间格式化NAN问题
      if (date.indexOf('-') !== -1) {
        date = date.replace(/-/g, '/');
      }
      date = new Date(Date.parse(date));
    } else if (typeof date === "number") {
      date = new Date(date);
    }

    if (!fmt) {
      fmt = "yyyy-MM-dd hh:mm:ss";
    }

    let o = {
      "M+": date.getMonth() + 1, //月份
      "d+": date.getDate(), //日
      "h+": date.getHours(), //小时
      "m+": date.getMinutes(), //分
      "s+": date.getSeconds(), //秒
      "q+": Math.floor((date.getMonth() + 3) / 3), //季度
      "S": date.getMilliseconds() //毫秒
    };

    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));

    for (var k in o)
      if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));

    return fmt;
  }

  /**
   *
   * @param data 查找的数组
   * @param attr 查找的字段
   * @param value 值
   * @param noEqual 是否查找不等于
   * @returns {Array}
   */
  static getDataByAttr(data, attr, value, noEqual = false) {
    let l = data.length;
    let item, result = [], temp;

    data = data || [];
    for (let i = 0; i < l; i++) {
      item = data[i];
      temp = item[attr];

      if (!noEqual && temp === value) {
        result.push(item);
      } else if (!!noEqual && temp !== value) {
        result.push(item);
      }
    }
    return result;
  }

  /**
   * 获取数组某个字段的字符串数组
   * @param array
   * @param filed
   * @returns {Array}
   */
  static getFieldValue(array, filed) {
    let result = [];
    for (let i = 0, l = array.length; i < l; i++) {
      result.push(array[i][filed]);
    }
    return result;
  }


  /**
   *
   * @param value 检测是否为数字
   * @returns {boolean}
   */
  static checkIsNumber(value) {
    return (/^[1-9]+[0-9]*]*$/).test(value);
  }


  /**
   *
   * @param value
   *
   * 字符串2018-09-05 22:10:10
   * 时间戳 222222222222
   *
   */
  static getDate(value) {
    let date;
    if (Utils.checkIsNumber(value)) {
      date = new Date(parseInt(value));
    } else {
      // 需要处理兼容ios
      value = value.replace(/-/gi, "/");
      date = new Date(value);
    }
    return date;
  }

  /**
   *
   * @param str 去除str的首位空格
   * @returns {*}
   */
  static trim(str) {
    return str.replace(/^\s+|\s+$/g, '');
  }

  /**
   * 校验是否为身份证
   */
  static isIdCardNo(card) {
    // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
    let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    return reg.test(card);
  }

  static formatPhone(phone) {
    return Utils.addSpace(phone, [3, 7]);
  }

  /**
   *
   * @param id
   * @param cardType 为1时候才可以格式化
   * @returns {*}
   */
  static formatIdCardNo(id, cardType = 1) {
    return cardType === 1 ? Utils.addSpace(id, [6, 14]) : id;
  }

  /**
   *
   * @param id
   * @param cardType 为1时候才可以格式化
   * @returns {*}
   */
  static formatBankCardNo(id, cardType = 1) {
    return cardType === 1 ? Utils.addSpace(id, [4, 8, 12, 16, 20, 24, 28]) : id;
  }

  /**
   * index之前添加空格
   * @param str
   * @param indexArray
   */
  static addSpace(str, indexArray) {
    let temp, index;
    str = (str || '').split("");

    for (let i = 0, l = indexArray.length; i < l; i++) {
      index = indexArray[i];
      temp = str[index];

      if (temp && temp !== ' ') {
        str[index] = " " + temp;
      }
    }
    return str.join("");
  }

  /**
   * 很多数组都需要添加key
   *
   */

  static keySequence = + 1000;
  static addListKey(list) {
    if (list && list.map) {
      list.map((item) => {
        if (!item.key_id) {
          item.key_id = 'temp_key_id' + (++Utils.keySequence);
        }
      });
    }
  }

  static checkEmail(str) {
    let reg = /^[\w]+@([a-zA-Z0-9-\.])+$/;
    return str && reg.test(str);
  }

  static checkBankNo(str) {
    let reg = /^[0-9]*$/;
    return str && reg.test(str);
  }

  static v4() {
    let d = Date.now();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now();
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  static getBaseUrl() {
    return requestUrl[env];
  }

  static getUrl(path) {
    return path ? (this.getBaseUrl() + path) : '';
  }

  static hasLogin() {
    // console.log('hasLogin:', CookieApi.getCookie('hasLogin'))
    // return CookieApi.getCookie('hasLogin') || false;

    console.log('hasLogin:', LocalStore.get('hasLogin'))
    return LocalStore.get('hasLogin') || false;
  }

  static setLogin(hasLogin) {
    // if (!hasLogin) {
    //   CookieApi.deleteCookie('PHPSESSID');
    //   CookieApi.deleteCookie('hasLogin');
    // } else {
    //   CookieApi.setCookie('hasLogin', { hasLogin });
    // }

    LocalStore.set('hasLogin', hasLogin);
  }

  static goLogin(redirectUrl) {
    const { location } = window;
    const { hash = '' } = location;
    const path = hash.replace('#/', '');

    if (Platform.isApp) {
      History.push(`/#/login-callback?redirectUrl=${encodeURIComponent(path)}`);
      location.href = `${this.getBaseUrl()}/login/ssb`;
    } else {
      const pathName = `/#/login?redirectUrl=${encodeURIComponent(redirectUrl ? redirectUrl : path)}`;
      location.href = pathName;
      History.replace(pathName);
    }
  }
}
