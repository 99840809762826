import classNames from "classnames";
import { Component } from "react";
import FunLayout, { EnLayoutType } from '../../components/fun-layout';
import Header from '../../components/header';
import { eshiminSetTitleStyle } from '../../common/eshiminUtils';

import './index.scss';

export default class UserProtocol extends Component {

  componentDidMount() {
    eshiminSetTitleStyle('创盈企服用户协议');
  }

  render() {
    return (
      <div className="protocol-container">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <Header hidden={false} hasBack={true} className={'top'} history={this.props.history}>
            创盈企服用户协议
          </Header>

          <div className="protocol-content-container">
            <div className="protocol-title">创盈企服用户协议</div>
            <div className={classNames("protocol-p", "protocol-p-top")}>
              上海春申江创盈企业服务有限公司（以下简称“本公司”）按照下列条款与条件提供信息和产品，您在本协议中亦可被称为“用户”，以下所述条款和条件将构成您与本公司，就您使用提供的内容所达成的全部协议（以下称“本协议”）。
            </div>

            <div className={classNames("protocol-bold", "protocol-p-top")}>一、说明</div>
            <div className={classNames("protocol-p", "protocol-p-top")}>
              本公司向您提供包括但不限于政策查询、信息浏览、活动预约、专家预约、PDF下载、交流互动、企业服务（以下称“本服务”）。本公司在此提示用户，请您在使用本服务前详细阅读本协议的所有内容，尤其是免除、限制本公司责任或者限制用户权利的条款，如您对本协议有任何疑问，请向本公司（021-63511018）进行咨询。一旦您使用本服务，即表示您已阅读并完全同意接受本协议项下所述条款和条件的约束。如果您不同意本协议的任何条款，请您<span className="protocol-red">立即停止</span>使用本服务。未成年人应经其监护人陪同阅读本服务协议并表示同意，方可接受本服务协议。监护人应加强对未成年人的监督和保护，因其未谨慎履行监护责任而损害未成年人利益或者本公司利益的，应由监护人承担责任。
            </div>

            <div className={classNames("protocol-bold", "protocol-p-top")}>二、权利声明</div>
            <div className={classNames("protocol-p", "protocol-p-top")}>
              1、本公司及其关联公司（关联企业指与本公司存在直接或间接，股权或以协议安排等其他形式的控制与被控制关系，以及对公司运营具有重大影响关系的公司法人）享有并保留以下各项内容完整的、不可分割的所有权及/或知识产权：
            </div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（1）平台相关的软件、技术、程序、代码、用户界面等；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（1）本服务相关的商标、图形标记。</div>
            <div className={classNames("protocol-p", "protocol-p-top")}>
              2、本公司提供的服务内容中所涉及的文字、软件、声音、图片、动画、录像、图表等，均受相关知识产权法以及其他相关法律的保护。未经本公司或者其他相关权利人授权，用户不得复制、使用、修改、摘编、翻译、发行,第三方未经本公司及/或其相关权利人的书面许可，不得以任何方式擅自进行使用。
            </div>

            <div className={classNames("protocol-bold", "protocol-p-top")}>三、责任限制</div>
            <div className={classNames("protocol-p", "protocol-p-top")}>
              1、本公司向用户提供的服务均是在依"现状"提供，本公司在此明确声明对本服务不作任何明示或暗示的保证，包括但不限于对服务的可适用性、准确性、及时性、可持续性等。
            </div>
            <div className={classNames("protocol-p", "protocol-p-top")}>
              2、用户理解并同意自行承担使用本服务的风险，且用户在使用本服务时，应遵循中国法律的相关规定，由于用户行为所造成的任何损害和后果，本公司均不承担除法律有明确规定外的责任。
            </div>
            <div className={classNames("protocol-p", "protocol-p-top")}>
              3、不论在何种情况下，本公司均不对由于网络连接故障、通讯线路、第三方网站、电脑硬件等任何原因给用户造成的任何损失承担除法律有明确规定外的责任。
            </div>

            <div className={classNames("protocol-bold", "protocol-p-top")}>四、用户行为规范</div>
            <div className={classNames("protocol-p", "protocol-p-top")}>
              1、用户在本网站注册时，不得使用虚假身份信息。用户应当妥善保存其账户信息和密码，由于用户泄密所导致的损失需由用户自行承担。如用户发现他人冒用或盗用其账户或密码，或其账户存在其他未经合法授权使用之情形，应立即以有效方式通知本公司。用户理解并同意本公司有权根据用户的通知、请求或依据判断，采取相应的行动或措施，包括但不限于冻结账户、限制账户功能等，本公司对采取上述行动所导致的损失不承担除法律有明确规定外的责任。
            </div>
            <div className={classNames("protocol-p", "protocol-p-top")}>
              2、用户在使用本服务时须遵守法律法规，不得利用本服务从事违法违规行为，包括但不限于：
            </div>
            <div className={classNames("protocol-p", "protocol-p-top")}>（1）发布、传送、传播、储存危害国家安全统一、破坏社会稳定、违反公序良俗、侮辱、诽谤、淫秽、暴力以及任何违反国家法律法规的内容；</div>
            <div className={classNames("protocol-p", "protocol-p-top")}>（2）发布、传送、传播、储存侵害他人知识产权、商业秘密等合法权利的内容；</div>
            <div className={classNames("protocol-p", "protocol-p-top")}>（3）恶意虚构事实、隐瞒真相以误导、欺骗他人；</div>
            <div className={classNames("protocol-p", "protocol-p-top")}>（4）发布、传送、传播广告信息及垃圾信息；</div>
            <div className={classNames("protocol-p", "protocol-p-top")}>（4）其他法律法规禁止的行为。</div>

            <div className={classNames("protocol-p", "protocol-p-top")}>
              3、用户不得利用本服务进行任何有损本公司及其关联企业之权利、利益及商誉，或其他用户合法权利之行为。
            </div>
            <div className={classNames("protocol-p", "protocol-p-top")}>
              4、用户不得基于本服务从事制作、使用、传播“私服”、“外挂”等侵害本公司合法权益的行为。如有违反，本公司将依据中国现行法律法规及本公司的相关规定予以处理。
            </div>
            <div className={classNames("protocol-p", "protocol-p-top")}>
              5、用户不得从事任何利用本公司平台系统漏洞进行有损其他用户、本公司或互联网安全的行为。
            </div>
            <div className={classNames("protocol-p", "protocol-p-top")}>
              6、用户知悉并确认，本公司通过公告、邮件、短信等方式，向用户发出关于本服务的通知、规则、提示等信息，均为有效通知。该等信息一经公布或发布，即视为已送达至用户。
            </div>

            <div className={classNames("protocol-bold", "protocol-p-top")}>五、隐私条款</div>
            <div className={classNames("protocol-p", "protocol-p-top")}>
              1、用户个人信息的收集，在您注册登入过程中，本公司会收集您的相关信息，包括姓名、邮箱账号、通讯地址、电话号码、企业营业执照等个人信息。
            </div>
            <div className={classNames("protocol-p", "protocol-p-top")}>
              2、本公司收集您的个人信息的目的主要是为了向您提供服务不得不收集，否则服务无法提供。
            </div>
            <div className={classNames("protocol-p", "protocol-p-top")}>
              3、个人信息的使用，本公司会将个人信息用于审计、数据分析、研究等内部目的，以便用户可以更好地享受本公司提供的全面服务，同时改进本公司的产品与服务。
            </div>
            <div className={classNames("protocol-p", "protocol-p-top")}>
              4、个人信息的披露，本公司不会为第三方的营销目的与第三方共享个人信息，更不会销售个人信息。本公司有义务要求上述合作伙伴严格遵守保密约定和本隐私权政策的要求。
            </div>
            <div className={classNames("protocol-p", "protocol-p-top")}>
              5、本公司根据政府部门的合法要求，有必要披露用户的个人信息。若本公司重组、合并或出售，可将收集的一切个人信息转让给相关受让方。
            </div>

            <div className={classNames("protocol-bold", "protocol-p-top")}>六、协议的终止</div>
            <div className={classNames("protocol-p", "protocol-p-top")}>
              出现下列情况之一，本公司有权立即终止授予给您的软件使用许可，而无需承担任何责任：
            </div>
            <div className={classNames("protocol-p", "protocol-p-top")}>
              1、您违反本协议有关保证、同意、承诺条款或本公司发布的其他使用规则的。
            </div>
            <div className={classNames("protocol-p", "protocol-p-top")}>
              2、您存在其他违法违约或者严重违反公序良俗的行为，本公司依单方面判断认为应该立即终止您使用许可的情形。
            </div>

            <div className={classNames("protocol-bold", "protocol-p-top")}>七、法律及争议解决</div>
            <div className={classNames("protocol-p", "protocol-p-top")}>
              1、本协议使用中华人民共和国法律。
            </div>
            <div className={classNames("protocol-p", "protocol-p-top")}>
              2、因双方就本协议的签订、履行或解释发生争议，双方应努力友好协商解决。如协商不成，任何一方均有权向本公司所在地的<span className="protocol-red">管辖权</span>人民法院起诉。
            </div>
          </div>
        </FunLayout>
      </div>
    );
  }
}