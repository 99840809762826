import React, { Component } from "react";
import ReactLoading from 'react-loading';
import axios from "axios";
import CommonButton from "@/components/common-button";
import FunIcon from "@/components/fun-icon";
import FunLayout, { EnLayoutType } from "@/components/fun-layout";
import FunToast from "@/components/fun-toast";
import Header from "@/components/header";
import FunModal from "@/components/fun-modal";
import FunCommonInput, { EnDataType } from "@/components/common-input";
import Utils from "@/common/utils";
import { wechatShare } from "@/common/wechatUtils";
import { nodeUrl, env } from '../../../app.json';
import model from '../model';

import "./index.scss";

export default class PolicyOriginal extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    const id = query.get('id') || '';
    this.state = {
      id,
      itemData: {},
      isCollect: false,
      loading: false,
      height: 0,
    };
    this.materialsForm = {
      policyId: id,
      consultationType: 1,
      consultationContent: '',
    };
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    const { id } = this.state;
    this.onGetPolicyDetail(id);
    this.onGetIfCollect(id || '');
  }

  onGetPolicyDetail(id) {
    model.getPolicyDetail(id).then(res => {
      this.getUrl(res.wechat_url);
      this.setState({ itemData: res });
      const { title, content } = res;
      wechatShare(title, content, '/img/img/20211215/22451e418b83c415b5f126a5e0c0c62b.jpg');
    });
  }

  onGetIfCollect(id) {
    if (!Utils.hasLogin()) {
      return;
    }
    const ids = [];
    ids.push(id || '');
    model.checkIfCollect({ ids }).then(res => {
      this.setState({ isCollect: res[0][id] || false });
    }).catch(() => {
      this.setState({ isCollect: false });
    });
  }

  onBackToPolicyList() {
    this.props.history.goBack();
  }

  onAnalyze(id) {
    this.props.history.push(`/policy-analyze?id=${id}`);
  }

  onGetMaterials() {
    this.modalRef.current.show();
  }

  onCollectPolicy() {
    if (!Utils.hasLogin()) {
      Utils.goLogin();
      return;
    }
    const { itemData = {}, isCollect } = this.state;
    const { id } = itemData;
    if (isCollect) {
      model.canelCollectPolicy({ id }).then(res => {
        FunToast.show('取消收藏成功');
        itemData.isCollect = false;
        this.setState({ isCollect: false, itemData });
      });
    } else {
      model.collectPolicy({ id }).then(res => {
        FunToast.show('收藏成功');
        itemData.isCollect = true;
        this.setState({ isCollect: true, itemData });
      });
    }
  }

  onChangeField(value) {
    this.materialsForm.consultationContent = value;
  }

  onGetMaterialsConfirm = () => {
    if (!Utils.hasLogin()) {
      this.modalRef.current.hide();
      Utils.goLogin();
      return;
    }
    const { consultationContent } = this.materialsForm;
    if (!Utils.checkEmail(consultationContent)) {
      FunToast.show('请输入正确的邮箱');
      return;
    }

    this.showLoading();
    model.getPolicyMaterials(this.materialsForm).then(() => {
      this.hideLoading();
      this.modalRef.current.hide();
      FunToast.show('稍后将发送到您的邮箱');
    }).catch(() => {
      this.hideLoading();
    });
  }

  handleCloseRule = () => {
    this.modalRef.current.hide();
  };

  showLoading() {
    this.setState({ loading: true });
  }

  hideLoading() {
    this.setState({ loading: false });
  }

  insertStr(source, start, newStr) {
    return source.slice(0, start) + newStr + source.slice(start)
  }

  onLoadFunc() {
    let iframe = document.getElementById('iFrame');
    console.log('height:', iframe.contentDocument.body.scrollHeight)
  }

  getUrl(URL) {
    if (!URL) return;
    let http = (window.location.protocol === 'http:' ? 'http:' : 'https:');
    let realurl = nodeUrl[env] + URL;
    // let realurl = http + '//node.csj-cy.com/' + URL;
    axios.get(realurl).then((response) => {
      if (!response || !response.data) return
      let html = response.data;

      // console.log(html)
      html = html && html.replace(/data-src/g, "src")
      // .replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/g, '')
      // .replace(/https/g, 'http');
      let html_src = html;
      // console.log(html_src)

      let iframe = document.getElementById('iFrame');


      //这时候就成功拿到了微信公众号，这个html_src是个静态网页
      let doc = iframe.contentDocument || iframe.document;



      // const meta = document.createElement('meta')
      // meta.name = 'referrer';
      // meta.content = 'no-referrer';
      // document.getElementsByTagName('head')[0].appendChild(meta);

      html_src = this.insertStr(html_src, html_src.indexOf('</head>'), `<meta name="referrer" content="no-referrer"></meta>`)

      // let head = doc.querySelector("head");
      // let meta = `<meta name="referrer" content="no-referrer"></meta>`;
      // head.innerHTML += meta;
      //添加meta展示图片

      let backgroundUrlReg = /url[(]&quot;(\S*)&quot;/g;
      let backgroundImgs = html_src.match(backgroundUrlReg);
      if (backgroundImgs && backgroundImgs.length) {
        backgroundImgs.forEach(item => {
          let url = item.replace(/url[(]&quot;/g, '').replace(/&quot;/g, '');
          let img = document.createElement('img');
          img.src = url;
          doc.querySelector("body").appendChild(img);
        });
      }
      console.log(html_src)
      //配置无效img绕过背景图片路径限制
      doc.write(html_src);
      // 将静态页面写入iframe中
      setTimeout(() => {
        const height = doc.documentElement.scrollHeight;
        this.setState({ height });
      }, 500);
      //通过延时获取文档高度赋值Iframe去除滚动条，根据实际情况增加延时时间
      // doc.getElementById("js_content").style.visibility = "visible"

      // let html = response.data
      // html = html
      //   .replace(/data-src/g, 'src')
      //   .replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/g, '')
      //   .replace(/https/g, 'http')
      // let html_src = 'data:text/html;charset=utf-8,' + html;
      // const html_src = html
      // const iframe = document.getElementById('iFrame')
      // iframe.src = html_src
      // var doc = iframe.contentDocument || iframe.document
      // let head = doc.querySelector("head");
      // let meta = `<meta name="referrer" content="never">`;
      // head.innerHTML += meta;
      // doc.write(html_src)
      // doc.getElementById('js_content').style.visibility = 'visible'
    }, (err) => { console.log(err); });
  }

  /***
   * 返回富文本没有style样式的话直接replace(/<img/g, '<img style="max-width: 100%;"');
   * 否则需要处理style的情况
   */
  formatRichText(html) {
    let newContent = html.replace(/<img[^>]*>/gi, function (match) {
      match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
      match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
      match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
      return match;
    });
    newContent = newContent.replace(/style="[^"]+"/gi, function (match) {
      match = match.replace(/width:[^;]+;/gi, 'max-width:100%;').replace(/width:[^;]+;/gi, 'max-width:100%;');
      return match;
    });
    newContent = newContent.replace(/<br[^>]*\/>/gi, '');
    newContent = newContent.replace(/\<img/gi, '<img style="max-width:100%;"');
    return newContent;
  }

  render() {
    const {
      itemData: {
        id,
        title = "",
        content = "",
        create_at = "",
        type,
        wechat_url,
        analyzes = [],
        materials = [],
      } = {},
      isCollect,
      loading,
    } = this.state;
    console.log('height:', this.state.height)

    return (
      <div className="policy-original-container">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <Header hidden={false} hasBack={true} className={'top'} history={this.props.history}>政策原文</Header>

          <div className="policy-original-container">
            <div className="original-container">
              <span className="original-title">{title}</span>
              <div className="original-time-container">
                <FunIcon icon={'icon-date'} />
                <span className="original-create-time">{create_at}</span>
              </div>
              <div
                className="original-content"
                dangerouslySetInnerHTML={{
                  __html: this.formatRichText(content),
                }}
              />

              {wechat_url && <iframe src="" id="iFrame" width="100%" height={this.state.height + 'px'} frameBorder='0' style={{ marginTop: '30px' }}></iframe>}


              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '42px' }}>
                <CommonButton
                  style={{ marginRight: '5px' }}
                  className='policy-analyze-btn'
                  icon='icon-back-policy'
                  text='返回列表'
                  type='normal'
                  onClick={() => this.onBackToPolicyList()}
                />
                {type === 1 &&
                  <CommonButton
                    style={{ marginRight: '5px' }}
                    className='policy-analyze-btn'
                    icon={isCollect ? 'icon-collect' : 'icon-unCollect'}
                    text={isCollect ? '已收藏' : '收藏'}
                    type='primary'
                    onClick={() => this.onCollectPolicy()}
                  />}
                {type === 1 && <CommonButton
                  style={{ marginRight: '5px' }}
                  className='policy-analyze-btn'
                  icon='icon-analyze-policy'
                  text='相关解读'
                  type={analyzes.length > 0 ? 'primary' : 'disabled'}
                  onClick={() => this.onAnalyze(id)}
                />}
                {type !== 1 && <CommonButton
                  style={{ marginRight: '5px' }}
                  className='policy-materials-btn'
                  icon='icon-analyze-policy'
                  text='获取申报材料'
                  type={materials.length > 0 ? 'primary' : 'disabled'}
                  onClick={() => this.onGetMaterials(id)}
                />}
              </div>
            </div>
          </div>


        </FunLayout >
        <FunModal
          ref={this.modalRef}
          title={'获取申报材料'}
          wrapperStyle={{ height: '30%' }}
          hasConfirm={true}
          isStop={false}
          isHideImmediatelyWhenConfirm={false}
          onConfirm={this.onGetMaterialsConfirm}
          onHide={this.handleCloseRule}>
          <div className="policy-get-materials-container">
            <span className="policy-get-materials-title">请填写邮箱地址，申报材料之后会发送到您的邮箱，请在“进度查询”查询详情</span>
            <FunCommonInput
              className={"policy-get-materials-email"}
              dataType={EnDataType.none}
              placeholder={'请输入邮箱地址'}
              onChange={value => this.onChangeField(value)}
            />

            {loading && <div className="policy-origin-loading">
              <ReactLoading type="bars" color="#CBB486" width={'10%'} />
            </div>}
          </div>
        </FunModal>
      </div >
    );
  }
}