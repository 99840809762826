import React, { Fragment } from 'react'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import Home from './pages/home';
import Login from './pages/login';
import ForgetPwd from './pages/forget-pwd';
import Register from './pages/register';
import ChooseCompany from './pages/choose-company';
import AddCompany from './pages/add-company';
import MyExpertReservation from './pages/my-expert-reservation';
import CompanyInfo from './pages/company-info';
import MyNotification from './pages/my-notification';
import MyConsultation from './pages/my-consultation';
import MyCollect from './pages/my-collect';
import MyActivity from './pages/my-activity';
import PolicyList from './pages/policy/policy-list';
import PolicyAnylyze from './pages/policy/policy-analyze';
import PolicyApply from './pages/policy/policy-apply';
import IndustryIntroduction from './pages/industry-introduction';
import Comminicate from './pages/comminicate';
import Expert from './pages/expert';
import CompanyService from './pages/company-service';
import TaskList from './pages/task-list';
import CustomerList from './pages/customer-list';
import Mine from './pages/mine/index';
import OperationMine from './pages/operation-mine';
import Detail from './pages/detail';
import Salon from './pages/salon';
import EditCompany from './pages/edit-company';
import BusinessCard from './pages/business-card';
import OperationLogin from './pages/operation-login';
import CompanyLocation from './pages/company-location';
import History from './common/url/history';
import LoginCallback from './pages/login-callback';
import Privacy from './pages/protocol/privacy';
import UserProtocol from './pages/protocol/user-protocol';
import PolicyOriginal from './pages/policy/policy-original';
import SeeOriginImg from './pages/see-origin-img';
import CompanyBuilding from './pages/company-building';
import EditProfile from './pages/edit-profile';
import VipRight from './pages/vip-right';
import PointChange from './pages/point-change';
import MessageList from './pages/message-list';
import QuestionDetail from './pages/question-detail';

import './public/font/style.css';
import './public/eshimin.js';
import './App.scss';

function App() {
  return (
    <Fragment>
      <HashRouter history={History}>
        <Switch>
          <Route path="/home" component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/forget-pwd" component={ForgetPwd} />
          <Route path="/register" component={Register} />
          <Route path="/choose-company" component={ChooseCompany} />
          <Route path="/business-card" component={BusinessCard} />
          <Route path="/add-company" component={AddCompany} />
          <Route path="/my-expert-reservation" component={MyExpertReservation} />
          <Route path="/company-info" component={CompanyInfo} />
          <Route path="/my-consultation" component={MyConsultation} />
          <Route path="/my-collect" component={MyCollect} />
          <Route path="/my-activity" component={MyActivity} />
          <Route path="/policy-list" component={PolicyList} />
          <Route path="/policy-analyze" component={PolicyAnylyze} />
          <Route path="/policy-apply" component={PolicyApply} />
          <Route path="/industry-introduction" component={IndustryIntroduction} />
          <Route path="/comminicate" component={Comminicate} />
          <Route path="/expert" component={Expert} />
          <Route path="/company-service" component={CompanyService} />
          <Route path="/salon" component={Salon} />
          <Route path="/mine" component={Mine} />
          <Route path="/detail" component={Detail} />
          <Route path="/company-location" component={CompanyLocation} />
          <Route path="/login-callback" component={LoginCallback} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/user-protocol" component={UserProtocol} />
          <Route path="/policy-original" component={PolicyOriginal} />
          <Route path="/see-origin-img" component={SeeOriginImg} />
          <Route path="/company-building" component={CompanyBuilding} />
          <Route path="/edit-profile" component={EditProfile} />
          <Route path="/vip-right" component={VipRight} />
          <Route path="/point-change" component={PointChange} />
          <Route path="/message-list" component={MessageList} />
          <Route path="/question-detail" component={QuestionDetail} />
          <Redirect to={"/home"} />

          {/* <Route path="/customer-list" component={CustomerList} />
          <Route path="/task-list" component={TaskList} />
          <Route path="/operation-mine" component={OperationMine} />
          <Route path="/my-notification" component={MyNotification} />
          <Route path="/edit-company" component={EditCompany} />
          <Route path="/operation-login" component={OperationLogin} />
          <Route path="/forget-pwd" component={ForgetPwd} />
          <Route path="/company-info" component={CompanyInfo} />
          <Redirect to={"/customer-list"} /> */}
        </Switch>
      </HashRouter>
    </Fragment>
  );
}

export default App;
