import { request } from '../../http';

class Model {

  getExpertList(name, industry) {
    return request(`/expert/list?searchContent=${name}&industry=${industry}`, {
      method: 'GET',
    });
  }

  reserveExpert(data) {
    return request('/company/expert/appointment', {
      method: 'POST',
      data,
    });
  }
}

export default new Model();
