import React, { Component } from 'react'
import FunCommonInput, { EnDataType } from '@/components/common-input';
import FunIcon from '@/components/fun-icon';
import FunLayout, { EnLayoutType } from '@/components/fun-layout';
import Header from '@/components/header';
import Image from '@/components/image';
import CommonButton from '@/components/common-button';
import Utils from '@/common/utils';
import FunPicker from '@/components/fun-picker';
import { IndustryList } from "@/common/constant";
import { eshiminSetTitleStyle } from '@/common/eshiminUtils';
import model from './model';

import './index.scss'

export default class Expert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expertInfos: [],
      searchContent: '',
      industryType: '',
      industryName: '筛选产业',
    };
    this.industryPickerRef = React.createRef();
  }
  componentDidMount() {
    this.onGetExpertList('', '');
    eshiminSetTitleStyle('咨询预约');
  }

  onGetExpertList(searchContent, industryType) {
    model.getExpertList(searchContent, industryType).then(res => {
      const { data = [] } = res;
      this.setState({ expertInfos: data });
    });
  }

  onChange(value) {
    const { industryType } = this.state;
    this.onGetExpertList(value, industryType);
    this.setState({ searchContent: value });
  }

  onReservation(id, industry) {
    if (!Utils.hasLogin()) {
      Utils.goLogin();
      return;
    }
    this.props.history.push(`/comminicate?type=${industry}&expertId=${id}`);
    // model.reserveExpert({ expertId }).then(() => {
    //   FunDialog.alert({
    //     title: '专家预约成功',
    //     message: '专家会在24小时内与您电话联系',
    //     confirmText: '知道了',
    //     toggle: true,
    //   });
    // });
  }

  onShowIndustryelect() {
    this.industryPickerRef.current.show();
  }

  onSelectIndustryType(_, value) {
    const { id, name } = value;
    const { searchContent = '' } = this.state;
    this.onGetExpertList(searchContent, id);
    this.setState({ industryType: id, industryName: name });
  }

  render() {
    const { expertInfos = [], industryName } = this.state;
    return (
      <div className="expert-container">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <Header className="top" hidden={false} hasBack={true} history={this.props.history}>
            咨询预约
          </Header>
          <div className="expert-container">
            <div className="expert-search">
              <div className="expert-list-industry-container" onClick={this.onShowIndustryelect.bind(this)}>
                <FunIcon icon='icon-arrow-down-filling' />
                <span className="expert-list-industry">{industryName}</span>
              </div>
              <FunCommonInput
                dataType={EnDataType.none}
                placeholder={'搜索专家'}
                leftIcon={'icon-search'}
                noBorder
                noFocus
                onChange={this.onChange.bind(this)}
              />
            </div>

            {expertInfos && expertInfos.map(item => {
              const { id, avatar, name, profile, labels, industry } = item;
              return (
                <div className="expert-item-container" key={id}>
                  <div className="expert-item-avatar-name-profile">
                    <div className="expert-avatar">
                      <Image scale={154 / 154} src={Utils.getUrl(avatar)} />
                    </div>
                    <div className="expert-name-profile-container">
                      <div className="expert-name-container ">
                        <span className="expert-name">{name}</span>
                        {/* <FunIcon icon={'icon-mail'} iconStyle={{ color: '#CBB486' }} /> */}
                      </div>
                      <div
                        className="expert-profile"
                        dangerouslySetInnerHTML={{
                          __html:
                            profile.replace(/(\r\n|\n|\r)/gm, '<br />'),
                        }}
                      />
                      <span className="expert-intro-sub-title">擅长领域：</span>
                      <div className="expert-tags-container">
                        {labels && labels.map(item => {
                          const { id, label } = item;
                          return label && label.split('，') && label.split('，').map(labelItem => {
                            return <span className="expert-tag" id={id}>{labelItem}</span>
                          })
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="expert-reservsation">
                    <CommonButton
                      icon={'icon-mail'}
                      text={'咨询预约'}
                      type='primary'
                      onClick={() => this.onReservation(id, industry)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </FunLayout>
        <FunPicker
          onChange={this.onSelectIndustryType.bind(this)}
          ref={this.industryPickerRef}
          title="选择产业分类"
          displayFiled="name"
          valueFiled="id"
          defaultValue={industryName}
          data={[{
            options: IndustryList
          }]}
        />
      </div>
    )
  }
}
