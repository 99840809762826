import Image from "@/components/image";
import vipLabelImg from "@/public/images/icon-vip-label.png";
import vipFontImg from "@/public/images/icon-cy-vip-font.png";
import FunIcon from "../fun-icon";
import CommonButton from "../common-button";
import { dateFormat } from "@/common/dateUtils";
import Utils from '@/common/utils';

import './index.scss';

export function VipInfoHome(props) {
  const {
    className,
    history,
    companyUserInfo: {
      companyName,
      member_status,
      member_points,
      member_end_time,
      is_hp,
    },
  } = props;

  const pointChange = () => {
    if (Utils.hasLogin()) {
      history.push('/point-change');
    } else {
      Utils.goLogin();
    }
  };

  const vipRight = () => {
    if (Utils.hasLogin()) {
      history.push('/vip-right')
    } else {
      Utils.goLogin();
    }
  };

  return (

    <div className={['vip-info-home-container'].concat(className || '').join(' ')}>
      <div className="vip-container">
        <Image className="labelImg" src={vipLabelImg} />
        {(member_status === 0 || member_status === 1 || !Utils.hasLogin()) && <div className="no-vip-info-container">
          <Image className="fontImg" src={vipFontImg} />
          <span className="vip-right-number">{`尊享${(is_hp === 2 || !Utils.hasLogin()) ? 8 : 7}项专属特权`}</span>
          <span className="join-now" onClick={() => pointChange()}>立即入驻</span>
        </div>}
        {member_status === 2 && <div className="vip-info-content-container">
          <span className="vip-company-name">{companyName}</span>
          <div className="vip-content">
            <FunIcon icon={'icon-point'} iconFontSize={23} />
            <span className="vip-point">{member_points}积分</span>
            <span className="vip-expire-date">{dateFormat(member_end_time * 1000)}到期</span>
            <div className='vip-right-btn' onClick={() => vipRight()}>会员权益</div>
          </div>
          <div className="vip-btn-container">
            <CommonButton
              className='point-change-btn'
              textClassName='btn'
              icon='icon-point-change'
              text='线上积分兑换'
              type='primary'
              onClick={() => pointChange()}
            />
          </div>
        </div>}
      </div>
    </div >
  );
}