import Image from "../image";
import './index.scss';

export default function VipDialogCommon(props) {
  return (
    <div className='vip-dialog-container'>
      <Image className='vip-dialog-title-img' src={props.titleImg} />
      <div className='vip-dialog-content-container'>
        <div className="vip-dialog-content">{props.children}</div>
      </div>
    </div>
  );
}