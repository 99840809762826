import { request } from '../../http';

class Model {

  getMyActivity(data) {
    return request('/company/activity/list', {
      method: 'POST',
      data,
    });
  }
}

export default new Model();
