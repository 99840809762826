import { useEffect, useState } from 'react';
import FunIcon from "@/components/fun-icon";
import FunLayout, { EnLayoutType } from "@/components/fun-layout";
import Header from "@/components/header";
import ScrollView from '@/components/scroll-view';
import model from './model';

import './index.scss';

const iconEnum = {
  1: { icon: 'icon-point', color: '#FFA74A' },
  2: { icon: 'icon-point', color: '#FFA74A' },
  3: { icon: 'icon-policy-label', color: '#FE2051' },
  4: { icon: 'icon-message-tip', color: '#4DC0FA' },
  5: { icon: 'icon-message-tip', color: '#4DC0FA' },
}

const titleEnum = {
  1: '积分变动',
  2: '积分变动',
  3: '政策申报',
  4: '政策推送',
  5: '活动推送',
}

const typeEnum = {
  1: '到账积分：',
  2: '积分支出：',
}

export default function MessageList(props) {
  const [messageList, setMessageList] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);


  useEffect(() => {
    getMessageList(page, pageSize);
  }, [page]);

  const getMessageList = async (page, pageSize) => {
    const { data = [], total } = await model.getMessageList({ page, pageSize });
    if (page === 1) {
      messageList.splice(0, messageList.length);
    }
    setMessageList(messageList.concat(data),);
    setTotal(total);
    setPage(page);
  };

  const onItemClick = (item) => {
    if (item.type === 4) {
      props.history.push(`/policy-original?id=${item.policy_id}`);
    } else if (item.type === 5) {
      props.history.push(`/salon`);
    }
  }

  const onLoad = () => {
    if (messageList.length < total) {
      const _page = page + 1;
      setPage(_page);
    }
  }

  return (
    <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
      <Header hidden={false} hasBack={true} className={'top'} history={props.history}>
        消息中心
      </Header>

      <div className="message-list-content-container">
        <ScrollView
          onLoad={onLoad}
          noMore={messageList.length === total}
        >
          {messageList.map(item => {
            return <div className="message-list-item" key={item.id} onClick={() => onItemClick(item)}>
              <div className="sub-item-container">
                <div className="sub-item">
                  <FunIcon icon={iconEnum[item.type].icon} iconStyle={{ color: iconEnum[item.type].color, fontSize: '15px' }} iconFontSize={15} />
                  <span className="title">{titleEnum[item.type]}</span>
                </div>
                <span className="time">{item.create_at}</span>
              </div>
              <div className="gap-line" />
              <span className="content">{item.content}</span>
              {(item.type === 1 || item.type === 2) && <div className="sub-item-container">
                <span className="point">{typeEnum[item.type] + item.points}</span>
                {/* <span className="point-amount">{typeEnum[item.type] + item.amount}</span> */}
              </div>}
            </div>
          })}
        </ScrollView>
      </div>
    </FunLayout>
  );
}