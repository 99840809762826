import { request } from '../../http';
class Model {

  // 随申办授权后的登录
  loginAfterEshiminSSO(code) {
    return request(`/login/ssb_callback?code=${code}`, {
      method: 'GET',
    });
  }
}

export default new Model();
