import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './PickerWheel.scss';

/**
 * 房间选择，筛选条件
 */
export default class PickerWheel extends Component {
  static propTypes = {
    //容器class
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    //行高
    lineHeight: PropTypes.number,
    listData: PropTypes.array,
    //content, value的key名
    contentField: PropTypes.string,
    valueField: PropTypes.string,
    //滚动类型，line/cycle，默认line
    rollType: PropTypes.string,
    //初始值
    value: PropTypes.any,
    onChange: PropTypes.func
  };

  static defaultProps = {
    lineHeight: 40,
    listData: [],
    contentField: "content",
    valueField: "value",
    rollType: "line"
  };

  constructor(props) {
    super(props);

    this.state = {
      spin: { start: -5, end: 5, branch: 5 }
    }

    this.finger = { startY: 0, lastY: 0, startTime: 0, lastTime: 0, transformY: 0 };

    //bind this
    this.itemTouchStart = this.itemTouchStart.bind(this);
    this.itemTouchMove = this.itemTouchMove.bind(this);
    this.itemTouchEnd = this.itemTouchEnd.bind(this);
  }

  //refs
  $el = React.createRef();
  $list = React.createRef();
  $wheel = React.createRef();

  componentDidMount() {
    const $el = this.$el.current;
    /* 事件绑定 */
    $el.addEventListener('touchstart', this.itemTouchStart);
    $el.addEventListener('touchmove', this.itemTouchMove);
    $el.addEventListener('touchend', this.itemTouchEnd);
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { lineHeight, value } = this.props;
    let move = - lineHeight * value;
    if (prevProps.value !== value) {
      this.setStyle(move, null, 100, 0)
    }
  }

  componentWillUnmount() {
    const $el = this.$el.current;
    $el.removeEventListener('touchstart', this.itemTouchStart);
    $el.removeEventListener('touchmove', this.itemTouchMove);
    $el.removeEventListener('touchend', this.itemTouchEnd);
  }

  /* 初始化状态 */
  init(initType) {
    let size, index, move
    setTimeout(() => {
      if (!initType) {
        const { lineHeight, value, listData } = this.props;
        size = listData.length;
        index = value;
        if (index < 0 || index >= size) {
          console.warn('当前初始值不存在，请检查后listData范围！！')
          this.setListTransform()
          this.getPickValue(0)
        } else {
          move = index * lineHeight
          /* 因为往上滑动所以是负 */
          this.setStyle(-move)
          // this.setListTransform(-move, -move)
        }
      }
    }, 50);
  }

  /* 根据type 控制滚轮显示效果 */
  setHidden(index) {
    const { rollType } = this.props;
    if (rollType === 'line') {
      return index < 0 || index > this.props.listData.length - 1
    } else {
      return false
    }
  }

  setWheelItemDeg(index) {
    return {
      transform: `rotate3d(1, 0, 0, ${-index * 22 % 360}deg) translate3d(0px, 0px, 100px)`
    }
  }

  setWheelDeg(updateDeg, type, time = 1000) {
    const $wheel = this.$wheel.current;
    if (type === 'end') {
      $wheel.style.webkitTransition = `transform ${time}ms cubic-bezier(0.19, 1, 0.22, 1)`
      $wheel.style.webkitTransform = `rotate3d(1, 0, 0, ${updateDeg}deg)`
    } else {
      $wheel.style.webkitTransition = ''
      $wheel.style.webkitTransform = `rotate3d(1, 0, 0, ${updateDeg}deg)`
    }
  }

  setListTransform(translateY = 0, marginTop = 0, type, time = 1000) {
    const { spin } = this.state;
    const { lineHeight } = this.props;
    const $list = this.$list.current;
    if (type === 'end') {
      $list.style.webkitTransition = `transform ${time}ms cubic-bezier(0.19, 1, 0.22, 1)`
      $list.style.webkitTransform = `translateY(${translateY - spin.branch * lineHeight}px)`
      $list.style.marginTop = `${-marginTop}px`
      $list.setAttribute('scroll', translateY)
    } else {
      $list.style.webkitTransition = ''
      $list.style.webkitTransform = `translateY(${translateY - spin.branch * lineHeight}px)`
      $list.style.marginTop = `${-marginTop}px`
      $list.setAttribute('scroll', translateY)
    }
  }

  itemTouchStart(event) {
    let finger = event.changedTouches[0]
    this.finger.startY = finger.pageY
    this.finger.startTime = event.timestamp || Date.now()
    this.finger.transformY = this.$list.current.getAttribute('scroll')
    event.preventDefault()
  }

  moveTimer = null;
  itemTouchMove(event) {
    let finger = event.changedTouches[0]
    this.finger.lastY = finger.pageY
    this.finger.lastTime = event.timestamp || Date.now()
    /* 设置css */
    let move = this.finger.lastY - this.finger.startY
    this.setStyle(move)
    event.preventDefault()
    this.moveTimer && clearTimeout(this.moveTimer);
    this.moveTimer = setTimeout(() => {
      clearTimeout(this.moveTimer);
      let move = this.finger.lastY - this.finger.startY
      this.setStyle(move, 'end');
    }, 400);
  }

  itemTouchEnd(event) {
    this.moveTimer && clearTimeout(this.moveTimer);
    let finger = event.changedTouches[0]
    this.finger.lastY = finger.pageY
    this.finger.lastTime = event.timestamp || Date.now()
    let move = this.finger.lastY - this.finger.startY
    /* 计算速度 */
    /* 速度计算说明
     * 当时间小于300毫秒 最后的移动距离等于 move + 减速运动距离
     * */
    let time = this.finger.lastTime - this.finger.startTime
    let v = move / time
    /* 减速加速度a */
    let a = 2.8
    /* 设置css */
    if (time <= 300) {
      move = v * a * time
      time = 1000 + time * a
      this.setStyle(move, 'end', time)
    } else {
      this.setStyle(move, 'end')
    }
  }

  /* 设置css */
  setStyle(move, type, time, mode = 1) {
    const singleHeight = this.props.lineHeight
    const deg = 22
    const singleDeg = deg / singleHeight
    let currentListMove = this.finger.transformY
    let updateMove = move + mode * Number(currentListMove)
    let updateDeg, spinAim, margin, endMove, endDeg
    const { rollType } = this.props;

    if (type === 'end' && rollType === 'line') {
      const { listData } = this.props;
      /*这里只在释放的时候判断 实现缓动效果*/
      /* 根据滚轮类型 line or cycle 判断 updateMove最大距离 */
      if (updateMove > 0) {
        updateMove = 0
      }
      if (updateMove < -(listData.length - 1) * singleHeight) {
        updateMove = -(listData.length - 1) * singleHeight
      }
    }
    //todo 这里考虑后续设置能最大缓动的值 目前暂时不考虑
    updateDeg = -updateMove * singleDeg
    spinAim = Math.round(updateDeg / 22)
    margin = Math.round(updateMove / singleHeight) * singleHeight // 如果不这么写 会导致没有滚动效果
    /* 计算touchEnd移动的整数距离 */
    endMove = margin
    endDeg = Math.round(updateDeg / deg) * deg
    if (type === 'end') {
      this.setListTransform(endMove, margin, type, time)
      this.setWheelDeg(endDeg, type, time)
      /* 设置值，触发change 延迟 */
      setTimeout(() => this.getPickValue(endMove), 200)
    } else {
      this.setListTransform(updateMove, margin)
      this.setWheelDeg(updateDeg)
    }
    this.updateSpin(spinAim)
  }

  /* 更新spin */
  updateSpin(spinAim) {
    const { spin } = this.state;
    this.setState({
      spin: {
        start: spin.branch * -1 + spinAim,
        end: spinAim + spin.branch,
        branch: 9
      }
    });
  }

  /* 获取spin 数据 */
  getSpinData(index) {
    const { listData } = this.props;
    index = index % listData.length;
    return listData[index >= 0 ? index : index + listData.length] || {};
  }

  /* 获取选中值 */
  getPickValue(move) {
    const { lineHeight, valueField } = this.props;
    let index = Math.round(-move / lineHeight);
    let pickValue = this.getSpinData(index)[valueField];
    this.props.onChange && this.props.onChange(pickValue);
  }

  renderData() {
    const { spin } = this.state;
    const { contentField } = this.props;
    let temp = [];
    for (let k = spin.start; k <= spin.end; k++) {
      let data = {
        content: this.getSpinData(k)[contentField],
        index: k
      }
      temp.push(data);
    }
    return temp;
  }

  render() {
    const renderData = this.renderData();
    return (
      <div ref={this.$el} className="pick-wheel-select-item">
        <div className="pick-wheel-select-line"></div>
        <div className="pick-wheel-select-list">
          <ul className="ppick-wheeld-select-ul" ref={this.$list}>
            {
              renderData.map((item, index) => {
                return <li className={classNames("pick-wheel-select-list-item", { 'hidden': this.setHidden(item.index) })} key={index}>
                  {item.content}
                </li>
              })
            }
          </ul>
        </div>
        <ul className="pick-wheel-select-wheel" ref={this.$wheel}>
          {
            renderData.map((item, index) => {
              return <li className={classNames("pick-wheel-select-wheel-item", { 'hidden': this.setHidden(item.index) })} style={this.setWheelItemDeg(item.index)}
                index={item.index} key={index}>
                {item.content}
              </li>
            })
          }
        </ul>
      </div>
    )
  }
}