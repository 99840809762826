import React, { Component } from 'react'
import FunCommonInput, { EnDataType } from '../../components/common-input';
import FunLayout, { EnLayoutType } from '../../components/fun-layout';
import Header from '../../components/header';
import CompanyList from '../company-list';
import ScrollView from "../../components/scroll-view";
import { eshiminSetTitleStyle } from '../../common/eshiminUtils';
import defaultImg from '../../public/images/icon-company-service-default.png';
import Utils from '../../common/utils';
import registerServiceImg from '../../public/images/icon-register-service.png';
import bankServiceImg from '../../public/images/icon-bank-service.png';
import legalServiceImg from '../../public/images/icon-legal-service.png';
import knowledgeServiceImg from '../../public/images/icon-knowledge-service.png';
import policyServiceImg from '../../public/images/icon-policy-service.png';
import otherServiceImg from '../../public/images/icon-other-service.png';
import model from './model';

import './index.scss'
import Image from '../../components/image';

const tabs = [
  { tabType: 3, tabName: '证照办理', tabIcon: registerServiceImg },
  { tabType: 1, tabName: '金融服务', tabIcon: bankServiceImg },
  { tabType: 2, tabName: '法律服务', tabIcon: legalServiceImg },
  { tabType: 5, tabName: '知识产权服务', tabIcon: knowledgeServiceImg },
  { tabType: 6, tabName: '政策资质申报', tabIcon: policyServiceImg },
  { tabType: 4, tabName: '其他服务', tabIcon: otherServiceImg },
];
export default class CompanyService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs,
      companyList: [],
      tabType: '',
      searchContent: '',
      hasLogin: Utils.hasLogin(),
      page: 1,
      pageSize: 30,
      total: 0,
    };
    // const { tabType, searchContent, page, pageSize } = this.state;
    // this.onGetSupplierList(searchContent, tabType, page, pageSize);
  }
  componentDidMount() {
    // const { tabType, searchContent, page, pageSize } = this.state;
    // this.onGetSupplierList(searchContent, tabType, page, pageSize);
    eshiminSetTitleStyle('服务事项');
  }

  onGetSupplierList(content, type, page, pageSize) {
    const hasLogin = Utils.hasLogin();
    model.getSupplierList(content, type, page, pageSize).then(res => {
      const { companyList = [] } = this.state;
      const { data = [], total } = res;
      if (page === 1) {
        companyList.splice(0, companyList.length);
      }
      this.setState({
        companyList: companyList.concat(data),
        hasLogin,
        total,
        page,
      });
    }).catch(() => {
      this.setState(hasLogin);
    });
  }

  onChange(value) {
    const { tabType, pageSize } = this.state;
    this.onGetSupplierList(value, tabType, 1, pageSize);
    this.setState({ searchContent: value });
  }

  // 工商注册、财务税务、银行服务、法律服务、知识产权、市场推广 点击事件
  onTabClick(type) {
    const { searchContent, pageSize, tabType } = this.state;
    if (tabType === type) return;
    this.onGetSupplierList(searchContent, type, 1, pageSize);
    this.setState({ tabType: type });
  }

  onServiceConsultation() {
    const { tabType } = this.state;
    console.log(tabType)
    this.props.history.push(`/comminicate?type=${tabType}`);
  }

  onLoad() {
    let { tabType, searchContent, page, pageSize, total, companyList } = this.state;
    if (companyList.length < total) {
      page = page + 1;
      this.onGetSupplierList(searchContent, tabType, page, pageSize);
    }
  }

  render() {
    const { tabs, companyList } = this.state;
    return (
      <div className="company-service-container">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <Header className="top" hasBack isNoBorder history={this.props.history}>
            服务事项
          </Header>


          <div className="company-service-container">

            <div className='company-service-top-container'>
              <div className="home-top-container">
                <FunCommonInput
                  dataType={EnDataType.none}
                  placeholder={'搜索服务'}
                  leftIcon={'icon-search'}
                  noBorder
                  noFocus
                  onChange={this.onChange.bind(this)}
                />
              </div>
              <div className="company-service-tab-item-container">
                {tabs.map(item => {
                  const { tabType, tabName, tabIcon } = item;
                  return (
                    <div className="company-service-tab-item" key={tabType} onClick={() => this.onTabClick(tabType)}>
                      {/* <FunIcon icon={tabIcon} iconStyle={{ color: '#fff', fontSize: '30px' }} /> */}
                      <div className='company-service-tab-icon'>
                        <Image scale={1} src={tabIcon} />
                      </div>
                      <span className="company-service-tab">{tabName}</span>
                    </div>
                  );
                })}
              </div>
            </div>



            <ScrollView
              className='company-service-content'
              onLoad={this.onLoad.bind(this)}
            >
              {
                (companyList && companyList.length > 0) ?
                  <CompanyList
                    companyList={companyList}
                    text='服务咨询'
                    onClick={this.onServiceConsultation.bind(this)} />
                  :
                  <div className='company-service-default-img'>
                    <Image scale={161 / 279} src={defaultImg} />
                  </div>
              }
            </ScrollView>
          </div>
        </FunLayout>
      </div>
    )
  }
}
