import React, { Component } from "react";
import FunCommonInput, { EnDataType } from "@/components/common-input";
import FunLayout, { EnLayoutType } from "@/components/fun-layout";
import Header from "@/components/header";
import ScrollView from "@/components/scroll-view";
import PolicyItem from "@/pages/policy/component/policy-item";
import { eshiminSetTitleStyle } from "@/common/eshiminUtils";
import model from "../model";

import './index.scss';
import FunToast from "@/components/fun-toast";
export default class PolicyApply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      policyApplyList: [],
      itemData: null,
      policyTypeName: '筛选分类',
      title: '',
      page: 1,
      pageSize: 30,
      total: 0,
    };
    this.typePickerRef = React.createRef()
  }

  componentDidMount() {
    const { title = '', page, pageSize } = this.state;
    this.onGetApplyPolicyList(title, page, pageSize);
    eshiminSetTitleStyle('政策申报');
  }

  onGetApplyPolicyList(title, page, pageSize) {
    model.getApplyPolicyList(title, page, pageSize).then(res => {
      const { data = [], total = 0 } = res;
      const { policyApplyList = [] } = this.state;
      if (page === 1) {
        policyApplyList.splice(0, policyApplyList.length);
      }
      this.setState({ policyApplyList: policyApplyList.concat(data), total });
    });
  }


  onBackToList() {
    this.props.history.goBack();
  }

  onShowPolicyTypeSelect() {
    this.typePickerRef.current.show();
  }

  onShowOriginal(item) {
    this.props.history.push(`/policy-original?id=${item?.id}`);
  }

  onApply(policyId, consultationType, consultationContent) {
    const params = { policyId, consultationType, consultationContent };
    model.getPolicyMaterials(params).then(() => {
      FunToast.show('申报成功');
    });
  }

  onChange(value) {
    const { pageSize } = this.state;
    this.onGetApplyPolicyList(value, 1, pageSize);
    this.setState({ title: value });
  }

  onLoad() {
    let { title = '', page, pageSize, policyApplyList = [], total } = this.state;
    if (policyApplyList.length < total) {
      page = page + 1;
      this.onGetApplyPolicyList(title, page, pageSize);
    }
  }

  render() {
    const { policyApplyList = [] } = this.state;
    return (
      <div className="policy-apply-container">
        <FunLayout
          type={EnLayoutType.fixedLayout}
          className={'main-content-p'}
          isAdaptorKeyboard={true}
        >

          <div className={'top'}>
            <Header hidden={false} hasBack isNoBorder className={'top'} history={this.props.history}>政策申报</Header>

            <div className="policy-apply-search-container">
              <FunCommonInput
                dataType={EnDataType.none}
                placeholder={'搜索政策'}
                leftIcon={'icon-search'}
                noBorder
                noFocus
                onChange={this.onChange.bind(this)}
              />
            </div>
          </div>

          <ScrollView
            onLoad={this.onLoad.bind(this)}
          >
            <div className="policy-apply-container">
              <PolicyItem
                type={2}
                policyList={policyApplyList}
                history={this.props.history}
                onPolicyClick={item => this.onShowOriginal(item)}
                onApply={(id, type, content) => this.onApply(id, type, content)}
              />
            </div>
          </ScrollView>
        </FunLayout>
      </div>
    );
  }
}