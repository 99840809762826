import React, { Component } from 'react'
import FunCommonInput, { EnDataType } from '../../components/common-input';
import FileUpload from '../../components/file-upload';
import FunButton, { EnButtonSize, EnButtonType } from '../../components/fun-button';
import FunIcon from '../../components/fun-icon';
import FunLayout, { EnLayoutType } from '../../components/fun-layout';
import FunPicker from '../../components/fun-picker';
import FunToast from '../../components/fun-toast';
import Header from '../../components/header';
import Image from '../../components/image';
import uploadImg from '../../public/images/icon-company-upload.png';
import upload from '../../common/upload';
import Utils from '../../common/utils';
import { IndustryList } from '../../common/constant';
import model from './model';

import './index.scss'
import { eshiminSetTitleStyle } from '../../common/eshiminUtils';
export default class AddCompany extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    const isEditProfile = (query.get('isEditProfile') === 'true');
    this.state = {
      industry: '企业所属行业',
      logoUrls: [],
      licenseUrls: [],
      isEditProfile,
    };
    this.industryPickerRef = React.createRef();
    this.profileRef = React.createRef();
    this.form = {
      companyName: '',
      labels: [],
      profile: '',
      industry: '',
      phoneNumber: '',
      email: '',
      logo: '',
      businessLicense: '',
    };
  }

  componentDidMount() {
    eshiminSetTitleStyle('新增企业信息');
  }

  onShowIndustrySelect() {
    this.industryPickerRef.current.show();
  }


  onSelectIndustry(_, value) {
    console.log('onSelectIndustry', value);
    const { id, name } = value;
    this.form.industry = id;
    this.setState({ industry: name });
  }

  onProfileChange(e) {
    const target = e ? e.target : this.profileRef.current;
    const value = target.value || '';
    this.form.profile = value;
  }

  onUploadLogo(files) {
    if (!files?.length) {
      this.form.logo = '';
      this.setState({ logoUrls: [] });
      return;
    }
    const file = files[0];
    upload.uploadImg(file).then(res => {
      const { code, data: { scr } } = res;
      if (code === 200) {
        const { logoUrls = [] } = this.state;
        logoUrls.splice(0, logoUrls.length);
        logoUrls.push(scr);
        this.form.logo = scr;
        this.setState({ logoUrls });
      }
    });
  }

  onUploadLicense(files) {
    if (!files?.length) {
      this.form.businessLicense = '';
      this.setState({ licenseUrls: [] });
      return;
    }
    const file = files[0];
    upload.uploadImg(file).then(res => {
      const { code, data: { scr } } = res;
      if (code === 200) {
        const { licenseUrls = [] } = this.state;
        licenseUrls.splice(0, licenseUrls.length);
        licenseUrls.push(scr);
        this.form.businessLicense = scr;
        this.setState({ licenseUrls });
      }
    });
  }

  onChangeField(field, value) {
    if (field === 'companyName') {
      this.form.companyName = value;
    } else if (field === 'phoneNumber') {
      this.form.phoneNumber = value;
    } else if (field === 'email') {
      this.form.email = value;
    }
  }

  onCheckParam() {
    const { companyName, profile, industry, phoneNumber, email, logo, businessLicense } = this.form;
    if (!companyName) {
      return '请输入企业名称';
    }
    if (!profile) {
      return '请输入企业简介';
    }
    if (!industry) {
      return '请选择企业所属行业';
    }
    if (!phoneNumber) {
      return '请输入企业电话';
    }
    if (!email) {
      return '请输入企业邮箱';
    }
    if (!logo) {
      return '请上传企业LOGO';
    }
    if (!businessLicense) {
      return '请上传营业执照';
    }
  }

  onFinishRegister() {
    const { isEditProfile } = this.state;

    const error = this.onCheckParam();
    if (error) {
      return FunToast.show(error, 2);
    }
    model.addCompany(this.form).then(res => {
      if (isEditProfile) {
        const { companyId, companyName } = res;
        this.props.history.replace(`/edit-profile?companyId=${companyId}&companyName=${companyName}`);
      } else {
        this.props.history.goBack();
      }
    })
  }

  render() {
    const { industry, logoUrls, licenseUrls, isEditProfile } = this.state;
    return (
      <div className="add-company-container">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <Header hidden={false} hasBack={true} className={'top'} history={this.props.history}>
            新增企业信息
          </Header>

          <FunCommonInput
            dataType={EnDataType.none}
            placeholder={'填写企业名称'}
            icon={'icon-home'}
            onChange={value => this.onChangeField('companyName', value)}
          />

          <textarea
            style={{
              width: '100%',
              marginTop: '34px',
              padding: '21px 15px',
              resize: 'none',
              border: '1px solid rgb(201, 201, 201)',
              fontSize: '14px',
              color: '#000000',
              borderRadius: '8px',
              fontWeight: '500',
              fontFamily: 'HarmonyOSSansSC-Bold'
            }}
            ref={this.profileRef}
            onChange={this.onProfileChange.bind(this)}
            placeholder={'编辑企业简介'}
            cols="30"
            rows="6"
          />

          <div className="add-company-industry-select-container" onClick={this.onShowIndustrySelect.bind(this)}>
            <span className="add-company-industry-select-text">{industry}</span>
            <FunIcon icon={"icon-arrow-down-filling"} />
          </div>

          <FunCommonInput
            className={"add-company-item"}
            dataType={EnDataType.none}
            placeholder={'企业电话'}
            icon={'icon-phone'}
            onChange={value => this.onChangeField('phoneNumber', value)}
          />

          <FunCommonInput
            className={"add-company-item"}
            dataType={EnDataType.none}
            placeholder={'企业邮箱'}
            icon={'icon-email'}
            onChange={value => this.onChangeField('email', value)}
          />

          <div className="add-company-upload">
            <div className='add-company-upload-item'>
              <span>上传企业LOGO</span>
              <FileUpload
                fileList={(logoUrls || []).map((item) => ({
                  url: Utils.getUrl(item),
                }))}
                selectable={!logoUrls.length}
                onChange={this.onUploadLogo.bind(this)}
                accept="image/jpeg,image/jpg,image/png"
                FileListItem={Image}
                fileListItemClassName="add-company-upload-img"
                uploadComponent={
                  <div className="add-company-upload-img">
                    <Image scale={1} src={uploadImg} />
                  </div>
                }
              />
            </div>
            <div className='add-company-upload-item'>
              <span>上传营业执照</span>
              <FileUpload
                fileList={(licenseUrls || []).map((item) => ({
                  url: Utils.getUrl(item),
                }))}
                selectable={!licenseUrls.length}
                onChange={this.onUploadLicense.bind(this)}
                accept="image/jpeg,image/jpg,image/png"
                FileListItem={Image}
                fileListItemClassName="add-company-upload-img"
                uploadComponent={
                  <div className="add-company-upload-img">
                    <Image scale={1} src={uploadImg} />
                  </div>
                }
              />
            </div>
          </div>


          <FunButton
            className="add-company-register"
            type={EnButtonType.primary}
            size={EnButtonSize.big}
            onClick={this.onFinishRegister.bind(this)}
          >
            {isEditProfile ? '完成' : '完成注册'}
          </FunButton>
        </FunLayout>

        <FunPicker
          onChange={this.onSelectIndustry.bind(this)}
          ref={this.industryPickerRef}
          title="选择所属行业"
          displayFiled="name"
          valueFiled="id"
          defaultValue={""}
          data={[{
            options: IndustryList
          }]}
        />
      </div>
    )
  }
}
