import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.scss';

/**
 * 跳转链接，封装了跳转功能
 */
export default class Link extends Component {

  constructor(props) {
    super(props);

    ['onClick'].forEach(method => {
      this[method] = this[method].bind(this);
    });
  }

  onClick(e) {
    const { href, onClick, replace, query, history } = this.props;

    if (onClick && onClick(e) === false) {
      return;
    }

    if (href) {
      history.replace(href);
      // if (replace) {
      //   history.replace(href);
      // } else {
      //   history.push(href);
      // }
    }
  }

  render() {
    const { className, children } = this.props;

    return (
      <a className={classNames('fun-link', className)}
        onClick={this.onClick}>
        {children}
      </a>
    );
  }
}

Link.propTypes = {
  className: PropTypes.string,  // 自定义class
  href: PropTypes.string,       // 待跳转地址
  replace: PropTypes.bool,      // 跳转时是否替换当前地址（无 history 记录）
  query: PropTypes.object,      // 跳转参数
  onClick: PropTypes.func
};

Link.defaultProps = {
  replace: false
};