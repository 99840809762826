import { Component } from "react";
import FunLayout, { EnLayoutType } from "../../components/fun-layout";
import Header from "../../components/header";
import userImg from "../../public/images/icon-user-default.png";
import Image from "../../components/image";
import FunIcon from "../../components/fun-icon";
import Footer from "../../components/footer";
import TabBar from "../../components/tab-bar";
import classNames from "classnames";
import { OperationTabs } from "../../common/constant";
import model from './model';

import "./index.scss";

const $primaryColor = '#CBB486';
const $warnColor = '#E64736';
const $textColor = '#2E2E2E';
const list = [
  { icon: 'icon-notification', color: $primaryColor, itemText: '我的提醒', itemTextColor: $textColor },
  { icon: 'icon-lock', color: $primaryColor, itemText: '修改密码', itemTextColor: $textColor },
  { icon: 'icon-logout', color: $warnColor, itemText: '退出系统', itemTextColor: $warnColor },
];
export default class OperationMine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      notificationCount: 0,
    };
  }

  componentDidMount() {
    model.getOperationUserInfo().then(res => {
      const { name = '', count = 0 } = res;
      this.setState({
        name,
        notificationCount: count,
      });
    });
  }

  onItemClick(itemText) {
    switch (itemText) {
      case '我的提醒':
        this.props.history.push('/my-notification');
        break;
      case '修改密码':
        this.props.history.push(`/forget-pwd?type=${2}&title=修改密码`);
        break;
      case '退出系统':
        this.onLogout();
        break;
      default:
        break;
    }
  }

  onLogout() {
    model.operationLogout().then(() => {
      this.props.history.replace('/operation-login');
    });
  }

  render() {
    const { name, notificationCount } = this.state;
    return (
      <div className="operation-mine-container">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <div className="top">
            <Header hidden={false} hasBack={false} isNoBorder>
              个人中心
            </Header>
            <div className="operation-mine-head-container">
              <div className="operation-mine-avatar">
                <Image scale={154 / 154} src={userImg} />
              </div>
              <span className="operation-mine-user-name">{name}</span>
            </div>
          </div>

          <div className="operation-mine-content-container">
            {list.map((item, index) => {
              const { icon, itemText, color, itemTextColor } = item;
              return (
                <div
                  className={classNames("operation-mine-item-container", index + 1 < list.length ? 'border' : '')}
                  onClick={() => this.onItemClick(itemText)}
                >
                  <FunIcon icon={icon} iconStyle={{ color: color }} />
                  <span className="operation-mine-item" style={{ color: itemTextColor }}>{itemText}</span>
                  {
                    notificationCount > 0 &&
                    '我的提醒' === itemText &&
                    <span className="operation-mine-notification-count">{notificationCount}</span>
                  }
                  <FunIcon icon="icon-arrow-right" />
                </div>
              );
            })}
          </div>
          <Footer>
            <TabBar activeIndex={2} items={OperationTabs} history={this.props.history} />
          </Footer>
        </FunLayout >
      </div >
    );
  }
}