import Platform from './platform';
import { request } from '../http';
import Utils from './utils';

const defaultApiList = [
  'checkJsApi',
  'updateAppMessageShareData',
  'updateTimelineShareData',
  'onMenuShareTimeline',
  'onMenuShareAppMessage',
];

export function wechatShare(title, desc, imgUrl) {
  if (Platform.isWechat) {
    setWechatSdk(window.location.href.split('#')[0]).then(() => {
      updateTimelineShareData(title, imgUrl);
      updateAppMessageShareData(title, desc, imgUrl);
    });
  }
}

function updateTimelineShareData(title, imgUrl) {
  // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
  window.wx.updateTimelineShareData({
    title, // 分享标题
    link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    imgUrl: Utils.getUrl(imgUrl), // 分享图标
    success: function () {
      // 设置成功
    }
  });

  window.wx.onMenuShareTimeline({
    title, // 分享标题
    link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    imgUrl: Utils.getUrl(imgUrl), // 分享图标
    success: function () {
      // 用户点击了分享后执行的回调函数
    }
  });
}

function updateAppMessageShareData(title, desc, imgUrl) {
  // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
  window.wx.updateAppMessageShareData({
    title, // 分享标题
    desc, // 分享描述
    link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    imgUrl: Utils.getUrl(imgUrl), // 分享图标
    success: function () {
      // 设置成功
    },
  });

  window.wx.onMenuShareAppMessage({
    title, // 分享标题
    desc, // 分享描述
    link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    imgUrl: Utils.getUrl(imgUrl), // 分享图标
    type: 'link', // 分享类型,music、video或link，不填默认为link
    dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
    success: function () {
      // 用户点击了分享后执行的回调函数
    }
  });
}

export function setWechatSdk(url, jsApiList = defaultApiList) {
  return getWxSdkSign(url).then((res) => {
    console.log('sign:', res);
    return doWxConfig(res, jsApiList);
  });
}

function getWxSdkSign(url) {
  const data = { url };
  return request(`/wx/js_config`, {
    method: 'POST',
    data,
  });
}

function doWxConfig(sign, jsApiList) {
  return new Promise((resolve, reject) => {
    const { wx } = window;
    if (wx && Platform.isWechat) {
      wx.config({
        ...sign,
        // debug: true,
        jsApiList
      });

      wx.ready(() => {
        resolve();
      });

      wx.error((err) => reject(err));
    } else {
      reject(Error('must be wechat web page'));
    }
  });
}