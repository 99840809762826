import React, { useCallback, useEffect, useState } from 'react'
import FunCommonInput, { EnDataType } from '../../components/common-input';
import FunButton, { EnButtonSize, EnButtonType } from '../../components/fun-button';
import FunIcon from '../../components/fun-icon';
import FunLayout, { EnLayoutType } from '../../components/fun-layout';
import Header from '../../components/header';
import CoutDownTime from '../../components/count-down-time';
import Utils from '../../common/utils';
import FunToast from '../../components/fun-toast';
import model from './model';
import { eshiminSetTitleStyle } from '../../common/eshiminUtils';
import debounce from '../../common/utils/debounce';

import './index.scss'

const form = {
  name: '',
  mobile: '',
  otp: '',
  pwd: '',
  confirmPwd: '',
  companyName: '',
  companyAddr: '',
  hasProtocolChecked: false,
};
const sendOtpRef = React.createRef();

export default function Register(props) {
  const [checkProtocol, setCheckProtocol] = useState(false);
  const [pwdType, setPwdType] = useState('password');
  const [againPwdType, setAgainPwdType] = useState('password');
  const [companyAddr, setCompanyAddr] = useState('');

  useEffect(() => {
    eshiminSetTitleStyle('用户注册');
  }, [])

  /**
   * 发送验证码
   */
  const onSendOtp = () => {
    let { mobile } = form;
    let error = Utils.checkMobile(mobile);

    if (!error) {
      model.sendOtp({ mobile }).then(res => {
        console.log('otp:', res);
        sendOtpRef.current.startCountTime();
      });
    } else {
      FunToast.show(error);
    }
  }

  /**
   * 勾选同意协议
   */
  const onCheckProtocol = () => {
    form.hasProtocolChecked = !checkProtocol;
    setCheckProtocol(!checkProtocol)
  }

  const onClickRight = (type) => {
    if (type === 1) {
      setPwdType(pwdType === 'password' ? '' : 'password')
    } else {
      setAgainPwdType(againPwdType === 'password' ? '' : 'password');
    }
  }

  const onChangeField = (field, value) => {
    form[field] = value;
    if (field === 'companyName') {
      delayQueryCompanyAddress(value);
    }
  }

  const onCheckParam = () => {
    const { name, mobile, otp, pwd, confirmPwd, companyName, companyAddr, hasProtocolChecked } = form;
    if (!name) {
      return '请输入真实姓名';
    }
    if (!mobile) {
      return '请输入手机号码';
    }
    const error = Utils.checkMobile(mobile);
    if (error) {
      return error;
    }
    if (!otp) {
      return '请输入验证码';
    }
    if (!pwd) {
      return '请设置密码';
    }
    const pwdError = Utils.checkPassword(pwd);
    if (pwdError) {
      return pwdError;
    }
    if (!confirmPwd) {
      return '请确认密码';
    }
    const confirmPwdError = Utils.checkPassword(confirmPwd);
    if (confirmPwdError) {
      return confirmPwdError;
    }
    if (pwd !== confirmPwd) {
      return '两次输入的密码不一致';
    }
    if (!companyName) {
      return '请输入公司名称';
    }
    if (!companyAddr) {
      return '请输入公司地址';
    }
    if (!hasProtocolChecked) {
      return '请勾选协议';
    }
  }

  const delayQueryCompanyAddress = useCallback(debounce(() => onCompanySearch(form.companyName), 500), [form.companyName]);

  const onCompanySearch = (companyName) => {
    model.companySearch({ companyName }).then(res => {
      const { companyAddr } = res;
      setCompanyAddr(companyAddr)
    });
  }

  const onUserProtocol = () => {
    props.history.push('/user-protocol');
  }

  const onPrivacy = () => {
    props.history.push('/privacy');
  }

  /**
   * 下一步
   */
  const onNext = () => {
    const error = onCheckParam();
    if (error) {
      return FunToast.show(error, 2);
    }
    model.verifyOtp(form).then(res => {
      const { token } = res;
      props.history.push(`/choose-company?otpRes=${token}`);
    })
  }

  const onFinishRegister = () => {
    const error = onCheckParam();
    if (error) {
      return FunToast.show(error, 2);
    }
    model.finishRegister(form).then(res => {
      FunToast.show('注册成功');
      props.history.replace('/login');
    });
  }

  return (
    <div className="register-container">
      <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
        <Header hidden={false} hasBack={true} className={'top'} history={props.history}>
          用户注册
        </Header>

        <div className="register-content-container">
          <FunCommonInput
            dataType={EnDataType.none}
            placeholder={'请输入公司名称'}
            onChange={value => onChangeField('companyName', value)}
          />
          <FunCommonInput
            className={"register-item-container"}
            dataType={EnDataType.none}
            placeholder={'企业注册地址'}
            defaultValue={companyAddr}
            multiLine
            rows={3}
            onChange={(value) => onChangeField('companyAddr', value)}
          />
          <FunCommonInput
            className={"register-item-container"}
            maxLength={20}
            dataType={EnDataType.none}
            placeholder={'请输入真实姓名'}
            icon={'icon-user'}
            onChange={value => onChangeField('name', value)}
          />
          <FunCommonInput
            className={"register-item-container"}
            maxLength={11}
            type={'number'}
            dataType={EnDataType.none}
            placeholder={'请输入手机号'}
            icon={'icon-mobile'}
            onChange={value => onChangeField('mobile', value)}
          />
          <div className="register-otp-container">
            <FunCommonInput
              maxLength={6}
              type={'number'}
              className={"register-otp-item-container"}
              dataType={EnDataType.none}
              placeholder={'请输入验证码'}
              onChange={value => onChangeField('otp', value)}
            />
            <CoutDownTime
              ref={sendOtpRef}
              onClickSend={() => onSendOtp()}
              className={'register-send-otp'}
              defaultText={'发送验证码'}></CoutDownTime>
          </div>
          <FunCommonInput
            className={"register-item-container"}
            type={pwdType}
            dataType={EnDataType.none}
            placeholder={'请设置密码'}
            icon={pwdType === 'password' ? 'icon-hide' : 'icon-show'}
            onClickRight={() => onClickRight(1)}
            onChange={value => onChangeField('pwd', value)}
          />
          <FunCommonInput
            className={"register-item-container"}
            type={againPwdType}
            dataType={EnDataType.none}
            placeholder={'请确认密码'}
            icon={againPwdType === 'password' ? 'icon-hide' : 'icon-show'}
            onClickRight={() => onClickRight(2)}
            onChange={value => onChangeField('confirmPwd', value)}
          />


          <div className="register-protocol-container">
            <div onClick={() => onCheckProtocol()}>
              <FunIcon icon={'icon-checked-solid'} iconStyle={{ color: checkProtocol ? '#CBB486' : '', fontSize: '20px' }} iconFontSize={20} />
            </div>
            <span className="protocol">我同意平台的
              <span className="protocol-link" onClick={() => onUserProtocol()}>《用户协议》</span>和
              <span className="protocol-link" onClick={() => onPrivacy()}>《隐私协议》</span>
            </span>
          </div>

          {/* <FunButton
              className="register-btn"
              type={EnButtonType.primary}
              size={EnButtonSize.big}
              onClick={this.onNext.bind(this)}
            >
              下一步
            </FunButton> */}

          {/*2022.08.28根据需求改为如此*/}
          <FunButton
            className="register-btn"
            type={EnButtonType.primary}
            size={EnButtonSize.big}
            onClick={() => onFinishRegister()}
          >
            完成
          </FunButton>
        </div>

      </FunLayout>
    </div>
  )
}
