import classNames from 'classnames';
import React, { Component } from 'react'
import CommonButton from '../../components/common-button';
import FunIcon from '../../components/fun-icon';
import FunLayout, { EnLayoutType } from '../../components/fun-layout';
import Header from '../../components/header';
import Image from '../../components/image';
import model from './model';

import './index.scss'
import { IndustryEnum } from '../../common/constant';
import Utils from '../../common/utils';
import { eshiminSetTitleStyle } from '../../common/eshiminUtils';

export default class CompanyInfo extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    const companyId = query.get('companyId');
    this.state = {
      companyId,
      companyInfo: {},
    };
  }
  componentDidMount() {
    const { companyId } = this.state;
    model.getCompanyDetail(companyId).then(res => {
      this.setState({ companyInfo: res || {} });
    });
    eshiminSetTitleStyle('企业信息');
  }

  onEditCompany() {
    const { companyId } = this.state;
    this.props.history.push(`/edit-company?companyId=${companyId}`);
  }

  render() {
    const { companyInfo = {} } = this.state;
    const {
      logo_url,
      name,
      industry_id,
      labels,
      mobile,
      email,
      profile,
    } = companyInfo;
    return (
      <div className="company-info-container">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <Header hidden={false} hasBack={true} className={'top'} history={this.props.history}>
            企业信息
          </Header>
          <div className="company-info-gap" />

          <div className="company-info-head-container">
            <div className="company-info-avatar-name-container">
              <div className="company-info-avatar">
                <Image scale={1} src={Utils.getUrl(logo_url)} />
              </div>
              <div className="company-info-name-container">
                <span className="company-info-name">{name}</span>
                <span className="company-info-industry">{IndustryEnum[industry_id]}</span>
              </div>
            </div>
            <div className="company-info-tag-container">
              {labels && labels.map(item => {
                return <span className="company-info-tag" key={item.id}>{item.label}</span>;
              })}
            </div>
          </div>

          <div className="company-info-gap" />

          <div className="company-info-profile-container">
            <span className="company-info-profile-title">企业简介</span>
            <span className="company-info-profile">{profile}</span>
          </div>

          <div className="company-info-gap" />

          <div className="company-info-contact-container">
            <span className="company-info-contact-title">联系方式</span>
            <div className="company-info-contact-item">
              <FunIcon icon={'icon-phone'} />
              <span className="company-info-contact">企业电话：{mobile}</span>
            </div>
            <div className="company-info-contact-item">
              <FunIcon icon={'icon-email'} />
              <span className="company-info-contact">企业邮箱：{email}</span>
            </div>
          </div>

          <div className={classNames("company-info-bottom-container", "bottom")}>
            <CommonButton
              className="company-info-bottom-button"
              icon={'icon-edit'}
              text={'编辑企业'}
              type='primary'
              onClick={() => this.onEditCompany()}
            />
            <a className="company-info-bottom-button" href={`tel:${mobile}`}>
              <CommonButton
                className="company-info-bottom-button"
                icon={'icon-phone'}
                text={'联系客户'}
                type='primary'
              />
            </a>
          </div>
        </FunLayout>
      </div>
    )
  }
}
