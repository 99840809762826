import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.scss';
/**
 *
 * 字体图标 组件
 * 使用方法：
 * <FunIcon icon="angle-left"></FunIcon>
 *
 */
export default class FunIcon extends Component {
  static propTypes = {
    icon: PropTypes.string,
    iconStyle: PropTypes.object,
    iconFontSize: PropTypes.number,
  };

  static defaultProps = {};

  render() {
    const { iconStyle, iconFontSize } = this.props;
    return (
      <span style={iconStyle} className={classNames(`icon-${this.props.icon}`, iconFontSize ? '' : 'fun-icon-size')}></span>
    )
  }
}