import { Component } from "react";
import Image from "../../../components/image";
import classNames from "classnames";
import './index.scss';
import FunIcon from "../../../components/fun-icon";
import Utils from "../../../common/utils";

export default class CompanyItem extends Component {
  onItemClick() {
    const { onSelect, companyId } = this.props;
    if (onSelect && onSelect instanceof Function) {
      onSelect(companyId);
    }
  }
  render() {
    const { companyName, companyAvatarImg, isSelected = false } = this.props;
    return (
      <div className={classNames("company-item-container", isSelected ? "company-item-selected" : "")} onClick={() => this.onItemClick()}>
        <div className="company-item-avatar">
          <Image className="company-item-avatar" scale={1} src={Utils.getUrl(companyAvatarImg)} />
        </div>
        <span className={isSelected ? "company-item-name-selected" : "company-item-name"}>{companyName}</span>
        {isSelected && <FunIcon icon={'icon-select'} iconStyle={{ color: '#CBB486' }} />}
      </div>
    );
  }
}