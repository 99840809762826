import { request } from '../../http';
class Model {
  getCompanyUserInfo(data) {
    return request('/company/personal', {
      method: 'POST',
      data,
    });
  }

  logout(data) {
    return request('/company/logout', {
      method: 'POST',
      data,
    });
  }

  updateAvatar(data) {
    return request('/company/update', {
      method: 'POST',
      data,
    });
  }

  getMessageList(data) {
    return request('/company/message/list', {
      method: 'POST',
      data,
    });
  }
}

export default new Model();
