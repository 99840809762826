import { Component } from 'react';
import FunIcon from '@/components/fun-icon';
import FunLayout, { EnLayoutType } from '@/components/fun-layout';
import Header from '@/components/header';
import Image from '@/components/image';
import FunToast from '@/components/fun-toast';
import ScrollView from '@/components/scroll-view';
import Utils from '@/common/utils';
import { eshiminSetTitleStyle } from '@/common/eshiminUtils';
import avatarImg from "@/public/images/icon-customer-service-avatar.png";
import model from './model';

import './index.scss'


const StatusEnum = {
  0: '新建',
  1: '已完成',
  2: '已联系',
  3: '取消退还积分',
};
export default class MyConsultation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consultationList: [],
      page: 1,
      pageSize: 15,
      total: 0,
    };
  }
  componentDidMount() {
    const { page, pageSize } = this.state;
    model.getMyConsultation(page, pageSize).then(res => {
      const { data = [], total } = res;
      const { consultationList, page } = this.state;
      if (page === 1) {
        consultationList.splice(0, consultationList.length);
      }
      this.setState({ consultationList: consultationList.concat(data), page, total });
      if (data.length <= 0) {
        FunToast.show('暂无咨询哦～');
      }
    });
    eshiminSetTitleStyle('进度查询');
  }

  getMyConsultation(page) {
    model.getMyConsultation(page).then(res => {
      const { data = [], total } = res;
      const { consultationList, page } = this.state;
      if (page === 1) {
        consultationList.splice(0, consultationList.length);
      }
      this.setState({ consultationList: consultationList.concat(data), page, total });
      if (data.length <= 0) {
        FunToast.show('暂无咨询哦～');
      }
    });
  }

  onShowOrHideContent(id) {
    const { consultationList = [] } = this.state;
    consultationList.forEach(item => {
      if (item.id === id) {
        item.isShowContent = !item.isShowContent
      }
    });
    this.setState({ consultationList });
  }

  onLoad() {
    const { consultationList, page, total } = this.state;
    if (consultationList.length < total) {
      const _page = page + 1;
      this.setState({ page: _page }, () => {
        this.getMyConsultation(_page);
      });
    }
  }

  render() {
    const { consultationList = [], total } = this.state;
    return (
      <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
        <Header hidden={false} hasBack={true} className={'top'} history={this.props.history}>
          进度查询
        </Header>
        <div className="my-consultation-container">
          <ScrollView
            onLoad={this.onLoad.bind(this)}
            noMore={consultationList.length === total}
          >
            {
              consultationList.map(item => {
                let { id, isShowContent, create_at, status, consultation_type, consultation_type_name, content, type, policy_name } = item;
                // 获取申报材料
                if (type === 5) {
                  if (consultation_type === 1) {
                    content = `获取${policy_name}的申报材料,发送到${content}`;
                  } else if (consultation_type === 2 || consultation_type === 3) {
                    content = `${policy_name}\n咨询：${content}`;
                  }
                  create_at = `${Utils.formatDate(create_at, 'yyyy-MM-dd')} 咨询`;
                  consultation_type_name = `咨询类型：${consultation_type_name}`;
                } else if (type === 6) {
                  create_at = `${Utils.formatDate(create_at, 'yyyy-MM-dd')} 会员服务`;
                  consultation_type_name = `类型：${consultation_type_name}`;
                }
                return (
                  <div className="consultation-item" key={id}>
                    <div className="consultation-normal" onClick={() => this.onShowOrHideContent(id)}>
                      <div className="consultation-company-avatar-img">
                        <Image scale={1} src={avatarImg} />
                      </div>
                      <span className="consultation-time" style={{ flex: 1 }}>{create_at}</span>
                      <div>
                        <span className="consultation-status" style={{ marginRight: '10px' }}>{StatusEnum[status]}</span>
                        <FunIcon icon={isShowContent ? 'icon-arrow-up' : 'icon-arrow-down'} />
                      </div>
                    </div>

                    {isShowContent && <div className="consultation-type-container">
                      <span className="consultation-type-content">{consultation_type_name}</span>
                    </div>}

                    {isShowContent && <span className="consultation-content">{content}</span>}

                  </div>
                );
              })
            }
          </ScrollView>
        </div>

      </FunLayout>
    )
  }
}
