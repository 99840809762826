import React, { Component } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import FunIcon from "../fun-icon";
import FunDynamicCore from "../fun-dynamic-core";
import Utils from "../../common/utils";
import classNames from "classnames";

export const EnDataType = {
  idCard: 'idCard', // 身份证
  phone: 'phone', // 手机号
  bankAccountNo: 'bankAccountNo', // 银行卡号
  none: 'none' // 无格式
};

/**
 *  输入框组件
 *
 */
export default class FunCommonInput extends Component {

  static propTypes = {
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.any,
    type: PropTypes.string,
    name: PropTypes.string,
    bind: PropTypes.object,
    maxLength: PropTypes.number,
    onChange: PropTypes.func,
    dataType: PropTypes.string,
    icon: PropTypes.string,
    leftIcon: PropTypes.string,
    className: PropTypes.string,
    noBorder: PropTypes.bool,
    noFocus: PropTypes.bool,
    required: PropTypes.bool,
    multiLine: PropTypes.bool,
    cols: PropTypes.number,
    rows: PropTypes.string,
  };

  static defaultProps = {
    type: 'text',
    multiLine: false,
    cols: 30,
    rows: '6',
  };

  static setTextValuePosition(input, position) {
    if (position < 0) {
      position = input.value.length;
    }

    if (input.setSelectionRange) { //兼容火狐,谷歌
      setTimeout(function () {
        input.setSelectionRange(position, position);
        input.focus();
      }, 0);
    } else if (input.createTextRange) { //兼容IE
      let rng = input.createTextRange();
      rng.move('character', position);
      rng.select();
    }
  }

  value = '';

  constructor(props) {
    super(props);

    this.inputRef = React.createRef();

    this.state = {
      isFocus: false,
      disabled: false
    };

    this.value = props.defaultValue || '';
  }

  onFocus() {
    this.setState({
      isFocus: true
    });
  }

  onBlur() {
    if (this.state.isFocus) {
      this.setState({
        isFocus: false
      });
    }
  }

  setValue(v, dataType = this.props.dataType) {
    this.value = v;
    this.inputRef.current.value = this.getDisplayValue(v, dataType);
  }

  disable() {
    this.setState({
      disabled: true
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    let { bind, name } = this.props;
    if ((bind && bind[name] !== this.value) || nextProps.dataType !== this.props.dataType) {
      let value;
      if (bind) {
        value = bind[name];
      } else {
        value = this.value;
      }
      this.setValue(value, nextProps.dataType);
    }
    return true;
  }

  onChangeValue(event) {
    let bind = this.props.bind, displayValue, value, maxLength, target;

    maxLength = this.props.maxLength;
    target = event.target;
    displayValue = target.value;
    value = this.value = this.getRealValue(displayValue);

    if (!!value && maxLength && value.length > maxLength) {
      value = this.value = value.slice(0, maxLength);
    }

    if (bind) {
      bind[this.props.name] = value;
    }

    displayValue = this.getDisplayValue(value);
    if (target.value !== displayValue) {
      target.value = displayValue;
      // FunCommonInput.setTextValuePosition(target, -1);
    }

    let func = this.props.onChange;
    if (typeof func === "function") {
      func(value, this.props.name);
    }

    this.onFocus();
  }

  onClickRemove() {
    let bind = this.props.bind;

    if (this.state.isFocus) {
      this.setValue('');
      if (bind) {
        bind[this.props.name] = '';
      }
      FunDynamicCore.doCall(this.props.onChange, '', this.props.name);
    }
  }

  onClickRight() {
    const { onClickRight } = this.props;
    if (onClickRight instanceof Function) {
      FunDynamicCore.doCall(onClickRight());
    }
  }

  onClick() {
    const { onClick } = this.props;
    if (onClick instanceof Function) {
      FunDynamicCore.doCall(onClick());
    }
  }

  getRealValue(displayValue) {
    let realValue = displayValue, dataType = this.props.dataType;
    if (dataType === EnDataType.bankAccountNo ||
      dataType === EnDataType.idCard ||
      dataType === EnDataType.phone) {
      realValue = displayValue.replace(/ /gi, "");
    }
    return realValue;
  }

  getDisplayValue(value = this.value, dataType = this.props.dataType) {
    let displayValue;
    switch (dataType) {
      case EnDataType.bankAccountNo:
        displayValue = Utils.formatBankCardNo(value);
        break;
      case EnDataType.idCard:
        displayValue = Utils.formatIdCardNo(value);
        break;

      case EnDataType.phone:
        displayValue = Utils.formatPhone(value);
        break;

      default:
        displayValue = value;
        break;
    }
    return displayValue;
  }

  render() {
    const {
      className,
      type,
      name,
      placeholder,
      defaultValue,
      icon,
      leftIcon,
      noBorder,
      noFocus,
      required,
      multiLine,
      maxLength,
      cols,
      rows,
    } = this.props;
    const { isFocus } = this.state;
    const inputFoucusStyle = (!noFocus && isFocus) ? {
      boxShadow: '0px 3.85px 7.69px rgba(203, 180, 134, 0.4)',
      border: '1.92px solid rgba(203, 180, 134, 1)',
    } : {
      border: noBorder ? 'none' : '1.92px solid rgba(201, 201, 201, 1)'
    };
    const iconStyle = isFocus ? {
      // color: '#CBB486',
    } : {
    };
    const customPlaceholder = (!noFocus && isFocus) ? '' : placeholder;

    return (
      <div className={classNames('fun-common-input', className)} style={inputFoucusStyle} _fun-common-input="true">
        {leftIcon && <div className='left-con'>
          <FunIcon icon={leftIcon} iconStyle={iconStyle} />
        </div>}
        {required && <div className="required">*</div>}
        {multiLine ?
          <div className="input-inner-con" onClick={this.onClick.bind(this)}>
            <textarea
              ref={this.textRef}
              style={{ resize: 'none', flex: 1, border: 'none' }}
              placeholder={customPlaceholder}
              maxLength={maxLength}
              cols={cols}
              rows={rows}
              onChange={this.onChangeValue.bind(this)}
              onFocus={this.onFocus.bind(this)}
              onBlur={this.onBlur.bind(this)}
            />
          </div>
          :
          <div className="input-inner-con" onClick={this.onClick.bind(this)}>
            <input ref={this.inputRef}
              className={'input'}
              type={type}
              name={name}
              defaultValue={defaultValue}
              placeholder={customPlaceholder}
              disabled={this.state.disabled || this.props.disabled}
              onChange={this.onChangeValue.bind(this)}
              onFocus={this.onFocus.bind(this)}
              onBlur={this.onBlur.bind(this)}
            // onClick={this.onClick.bind(this)}
            />

          </div>}

        {icon && <div className='right-con' onTouchStart={this.onClickRight.bind(this)}>
          <FunIcon icon={icon} iconStyle={iconStyle} />
        </div>}
      </div>
    )
  }

}