import { Component } from "react";
import { eshiminSetTitleStyle } from "../../common/eshiminUtils";
import FunLayout, { EnLayoutType } from "../../components/fun-layout";
import Header from "../../components/header";
import Image from "../../components/image";
import './index.scss';

export default class SeeOriginImg extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    const title = query.get('title') || '';
    const url = query.get('url') || '';

    this.state = {
      title,
      url,
    }
  }

  componentDidMount() {
    const { title } = this.state;
    eshiminSetTitleStyle(title);
  }

  render() {
    const { title, url } = this.state;

    return (
      <div className="see-origin-img-container">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <Header hidden={false} hasBack isNoBorder className={'top'} history={this.props.history}>{title}</Header>
          <div className="see-origin-img-container">
            <Image src={url} />
          </div>
        </FunLayout>
      </div>
    );
  }
}