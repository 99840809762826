import { request } from '../../http';
class Model {
  getMessageList(data) {
    return request('/company/message/list', {
      method: 'POST',
      data,
    });
  }
}

export default new Model();
