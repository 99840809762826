import React, { Component } from 'react'
import FunLayout, { EnLayoutType } from '../../components/fun-layout';
import Header from '../../components/header';
import Tabs from '../../components/tabs';
import CompanyList from '../company-list';
import ScrollView from "../../components/scroll-view";
import { eshiminSetTitleStyle } from '../../common/eshiminUtils';
import defaultImg from '../../public/images/icon-company-service-default.png';
import Utils from '../../common/utils';
import model from './model';

import './index.scss'
import Image from '../../components/image';

const tabs = [
  { tabType: 1, tabName: '园区楼宇', tabIcon: 'icon-intellectual-property' },
  { tabType: 2, tabName: '重点企业', tabIcon: 'icon-marketing' },
];
export default class CompanyBuilding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs,
      companyList: [],
      tabType: '',
      hasLogin: Utils.hasLogin(),
      page: 1,
      pageSize: 30,
      total: 0,
    };
  }
  componentDidMount() {
    eshiminSetTitleStyle('企业楼宇');
    this.onTabClick(0);
  }

  onGetBuildingList(content, type, page, pageSize) {
    const hasLogin = Utils.hasLogin();
    model.getBuildingList(content, type, page, pageSize).then(res => {
      const { companyList = [] } = this.state;
      const { data = [], total } = res;
      if (page === 1) {
        companyList.splice(0, companyList.length);
      }
      this.setState({
        companyList: companyList.concat(data),
        hasLogin,
        total,
        page,
      });
    }).catch(() => {
      this.setState(hasLogin);
    });
  }

  onTabClick(value) {
    const { pageSize } = this.state;
    const type = value + 1;
    this.onGetBuildingList('', type, 1, pageSize);
    this.setState({ tabType: type });
  }

  onContactNow(value) {
    Utils.goLogin();
  }

  onLoad() {
    let { tabType, page, pageSize, total, companyList } = this.state;
    if (companyList.length < total) {
      page = page + 1;
      this.onGetBuildingList('', tabType, page, pageSize);
    }
  }

  render() {
    const { companyList, hasLogin } = this.state;
    return (
      <div className="company-building-container">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <Header className="top" hasBack isNoBorder history={this.props.history}>
            企业楼宇
          </Header>

          <div className="company-building-container">
            <Tabs
              className='company-building-tabs'
              items={['园区楼宇', '重点企业']}
              type={'full'}
              onChange={this.onTabClick.bind(this)}
            />

            <ScrollView
              className='company-building-content'
              onLoad={this.onLoad.bind(this)}
            >
              {
                (companyList && companyList.length > 0) ?
                  <CompanyList
                    companyList={companyList}
                    icon='icon-phone'
                    text='立即联系'
                    callMobile={hasLogin}
                    onClick={this.onContactNow.bind(this)} />
                  :
                  <div className='company-building-default-img'>
                    <Image scale={404 / 540} src={defaultImg} />
                  </div>
              }
            </ScrollView>
          </div>
        </FunLayout>
      </div>
    )
  }
}
