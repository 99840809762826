import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import FunCommonInput, { EnDataType } from '@/components/common-input';
import FunLayout, { EnLayoutType } from '@/components/fun-layout';
import Header from '@/components/header';
import Image from '@/components/image';
import FunToast from '@/components/fun-toast';
import TabBar from '@/components/tab-bar';
import Footer from '@/components/footer';
import ImageGallery from '@/components/image-gallery';
import { VipInfoHome } from '@/components/vip-info-home';
// import FunIcon from '@/components/fun-icon';
// import CompanyIntro from '@/components/company-intro';
import FunButton, { EnButtonSize, EnButtonType } from '@/components/fun-button';
import FunSwiper from '@/components/fun-swiper';

import { Tabs } from '@/common/constant';
import { eshiminSetTitleStyle } from '@/common/eshiminUtils';
import Utils from '@/common/utils';

import industryIntroImg from '@/public/images/icon-industry-intro.png';
// import policyAnalyzeImg from '@/public/images/icon-policy-analyze.png';
import policyApplyImg from '@/public/images/icon-policy-apply.png';
// import companyServiceImg from '@/public/images/icon-company-service.png';
// import companyBuildingImg from '@/public/images/icon-company-building.png';
// import comminicateImg from '@/public/images/icon-comminicate.png';
import expertReservationImg from '@/public/images/icon-expert-reservation.png';
// import readingSalonImg from '@/public/images/icon-reading-salon.png';
// import companyIntroImg from '@/public/images/icon-company-intro1.png';
import homeBottomImg from '@/public/images/icon-home-bottom.png';
import featuredImg from '@/public/images/icon-featured.webp';
import giftImg from '@/public/images/icon-company-in-gift.png';
import giftImg1 from '@/public/images/icon-company-in-gift-img1.png';
import giftImg2 from '@/public/images/icon-company-in-gift-img2.png';
import giftImg3 from '@/public/images/icon-company-in-gift-img3.png';
import giftImg4 from '@/public/images/icon-company-in-gift-img4.png';
import giftImg5 from '@/public/images/icon-vip-bank-business.webp';
import giftImg6 from '@/public/images/icon-vip-startup-company.png';
import questionImg from '@/public/images/icon-question.png';
import homeTopImg from '@/public/images/icon-home-top.webp';

import model from './model';

import './index.scss'

const tabs = [
  { id: 1, tabName: '产业介绍', tabIcon: industryIntroImg },
  { id: 2, tabName: '政策申报', tabIcon: policyApplyImg },
  // { id: 3, tabName: '企业楼宇', tabIcon: companyBuildingImg },
  // { id: 4, tabName: '服务事项', tabIcon: comminicateImg },
  { id: 3, tabName: '咨询预约', tabIcon: expertReservationImg },
  // { id: 6, tabName: '会员活动', tabIcon: readingSalonImg },
];
export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs,
      bannerUrls: [],
      bannerImg2: '',
      bannerImg3: '',
      infoList: [],
      questionList: [],
      loading: false,
      companyUserInfo: {},
      isHp: false,
    };
  }
  componentDidMount() {
    this.showLoading();
    Promise.all([this.onQueryBanner(), this.onQueryQuestion(), this.onGetCompanyUserInfo()]).then(() => {
      this.hideLoading();
    }).catch(() => {
      this.hideLoading();
    });
    eshiminSetTitleStyle('创盈企服');
  }

  onQueryBanner() {
    return model.queryBannerList({ status: 1 }).then(res => {
      const { data = [] } = res;
      let { bannerUrls = [], bannerImg2 = '', bannerImg3 = '', } = this.state;
      data.forEach(dataItem => {
        const { position, thumb_url, type_content } = dataItem;
        if (position === 1) {
          bannerUrls.push({ src: Utils.getUrl(thumb_url), id: type_content })
        } else if (position === 2) {
          bannerImg2 = thumb_url;
        } else if (position === 3) {
          bannerImg3 = thumb_url;
        }
      });

      this.setState({
        bannerUrls,
        bannerImg2,
        bannerImg3,
      });
    });
  }

  onGetCompanyUserInfo() {
    if (Utils.hasLogin()) {
      model.getCompanyUserInfo().then(res => {
        this.setState({
          companyUserInfo: res || {},
          isHp: res && res.company && (res.company.is_hp === 2),
        });
      });
    }
  }

  onQueryInformation() {
    return model.queryInformationList().then(res => {
      const { data = [] } = res;
      this.setState({
        infoList: data || [],
      });
    });
  }

  onQueryQuestion() {
    return model.queryQuestionList({ page: 1, pageSize: 10 }).then(res => {
      const { data = [] } = res;
      // const temp = [];
      // let tempItem = {};
      // data && data.forEach((item, index) => {
      //   const i = index + 1;
      //   if (i % 2 === 0) {
      //     tempItem.qa1 = item;
      //     temp.push(tempItem);
      //     tempItem = {};
      //   } else {
      //     tempItem.qa = item;
      //     if (i === data.length) {
      //       temp.push(tempItem);
      //     }
      //   }
      // });
      // console.log(temp)
      // this.setState({ questionList: temp });
      this.setState({ questionList: data })
    })
  }

  // 产业介绍、政策解读、服务事项、服务咨询、专家预约、会员活动 点击跳转事件
  onTabClick(tabName) {
    let routeUrl = '';
    let needLogin = false;
    switch (tabName) {
      case '产业介绍':
        routeUrl = '/industry-introduction';
        break;
      case '政策申报':
        routeUrl = '/policy-apply';
        break;
      case '企业楼宇':
        routeUrl = '/company-building';
        break;
      case '服务事项':
        routeUrl = '/company-service';
        break;
      case '咨询预约':
        routeUrl = '/expert';
        break;
      case '会员活动':
        routeUrl = '/salon';
        break;
      default:
        break;
    }
    if (!routeUrl) {
      FunToast.show('敬请期待');
      return;
    }
    this.props.history.push(routeUrl);
  }

  onPolicyList() {
    this.props.history.push('./policy-list');
  }

  onCompanyLocation() {
    this.props.history.push('/company-location');
  }

  onPolicyApply() {
    this.props.history.push('/policy-apply');
  }

  onNewsItemClick(id) {
    this.props.history.push(`/detail?infoId=${id}`);
  }

  showLoading() {
    this.setState({ loading: true });
  }

  hideLoading() {
    this.setState({ loading: false });
  }

  onSwiperItemClick(value) {
    const { id } = value;
    if (id) {
      this.onNewsItemClick(id);
    }
  }

  onJoinNow = () => {
    if (!Utils.hasLogin()) {
      Utils.goLogin();
      return;
    }
    this.props.history.push('/point-change');
  }

  render() {
    const {
      tabs,
      infoList = [],
      loading,
      bannerUrls,
      questionList,
      bannerImg2,
      bannerImg3,
      companyUserInfo,
      isHp,
    } = this.state;
    return (
      <div className="home-container">
        {loading && <div className="home-loading">
          <ReactLoading type="bars" color="#CBB486" width={'10%'} />
        </div>}
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <Header className="top" hidden={false} isNoBorder>
            <div className="home-top-img-container" style={{ width: '100%', height: '44px' }}>
              <img className="home-top-img" scale={193 / 48} src={homeTopImg} />
            </div>
          </Header>
          <div className="home-content-container">
            <FunCommonInput
              dataType={EnDataType.none}
              placeholder='搜索政策'
              leftIcon={'icon-search'}
              noBorder
              noFocus
              onClick={this.onPolicyList.bind(this)}
            />

            {bannerUrls && bannerUrls.length > 0 && <div className="home-picture-one">
              {/* <Image scale={642 / 250} src={Utils.getUrl(bannerImg1)} /> */}
              <ImageGallery itemClassName='item-class-name' images={bannerUrls} effect={'normal'} onSwiperItemClick={value => this.onSwiperItemClick(value)} />

            </div >}
            {questionList && questionList.length > 0 &&
              <FunSwiper
                className="question-class-name"
                effect={'normal'}
                onSwiperItemClick={value => this.onSwiperItemClick(value)}
                loop={questionList.length >= 2}
              >
                {questionList.map((item, index) => {
                  return (
                    <div className="swiper-slide" key={index}>
                      <div className="swiper-zoom-container">
                        <div className='question-container'>
                          <Image className='icon-question' src={questionImg} />
                          <div className='question-content'>
                            <span onClick={() => { this.props.history.push(`/question-detail?id=${item.id}`) }}>
                              {`Q: ${item.title}`}
                            </span>

                            {/* <span className='span0'
                              onClick={() => { this.props.history.push(`/question-detail?id=${item.qa.id}`) }}
                            >
                              {`Q: ${item.qa.title}`}
                            </span>
                            {item.qa1 &&
                              <span
                                className='span1'
                                onClick={() => { this.props.history.push(`/question-detail?id=${item.qa1.id}`) }}
                              >
                                {`Q: ${item.qa1.title}`}
                              </span>} */}

                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </FunSwiper>}


            <div className="home-tab-item-container">
              {tabs.map(item => {
                const { id, tabName, tabIcon } = item;
                return (
                  <div className="home-tab-item" key={id} onClick={() => this.onTabClick(tabName)}>
                    <div className="home-tab-img">
                      <Image scale={1} src={tabIcon} />
                    </div>
                    <span className="home-tab">{tabName}</span>
                  </div>
                );
              })}
            </div>

            <div className='home-vip-container'>
              <VipInfoHome
                companyUserInfo={companyUserInfo}
                history={this.props.history}
              />
            </div>

            <>
              {
                isHp &&
                <div className='featured-img-container'>
                  <div className='line-left' />
                  <Image className='featured-img' src={featuredImg} />
                  <div className='line-right' />
                </div>
              }

              <div className='home-featured'>
                <Image className='title-img' src={giftImg} />
                <div className='label-container '>
                  <div className='div-row'>
                    <Image className='gift-img4' src={giftImg4} />
                    <span className='short-label' style={{ marginRight: '10px' }}>工商注册</span>
                    <span className='short-label'>税务登记</span>
                    <Image className='gift-img1' src={giftImg1} />
                  </div>
                  <div className='div-row'>
                    <span className='short-label' style={{ marginRight: '10px' }}>银行开户</span>
                    <span className='middle-label' style={{ marginRight: '10px', fontWeight: 'bolder' }}>惠企贷款</span>
                    <span className='long-label2'>软件企业认定</span>
                  </div>
                  <div className='div-row'>
                    <span className='long-label' style={{ marginRight: '10px' }}>高新技术企业认定</span>
                    <span className='long-label'>专精特新企业认定</span>
                  </div>
                  <div className='div-row'>
                    <span className='long-label1'>创业补贴申请</span>
                  </div>
                  <div className='div-row'>
                    <span className='long-label1' style={{ marginRight: '25px' }}>代理记账服务</span>
                    <span className='long-label1'>办公选址服务</span>
                  </div>
                  <div className='div-row'>
                    <Image className='gift-img3' src={giftImg3} />
                    <span className='law'>商标注册</span>
                    <Image className='gift-img2' src={giftImg2} />
                  </div>
                  <div className='div-row'>
                    <Image className='gift-img5' src={giftImg5} />
                    <span className='knowledge'>社保公积金管理</span>
                    <Image className='gift-img6' src={giftImg6} />
                  </div>
                </div>

                {companyUserInfo && companyUserInfo.member_status === 0 &&
                  <div className="join-now">
                    <FunButton
                      type={EnButtonType.primary}
                      size={EnButtonSize.default}
                      onClick={() => this.onJoinNow()}
                    >
                      立即入驻
                    </FunButton>
                  </div>}
              </div>
            </>

            {/* <div className="home-banner-two">
              <div className="home-picture-two">
                <Image scale={308 / 212} src={Utils.getUrl(bannerImg2)} onClick={this.onCompanyLocation.bind(this)} />
              </div>
              <div className="home-picture-three" onClick={this.onPolicyApply.bind(this)}>
                <Image scale={308 / 212} src={Utils.getUrl(bannerImg3)} />
              </div>
            </div> */}

            {/* <div className="home-breaking-news-container">
              <div className="home-news-title-container">
                <div className="home-news-title-label" />
                <span className="home-news-title">热点关注</span>
              </div>
              <div className="home-news-container">
                {infoList && infoList.map(item => {
                  const { id, cover_url, title, create_at } = item;
                  return (
                    <div className="home-news-item" key={id} onClick={() => this.onNewsItemClick(id)}>
                      <div className="home-news-img">
                        <Image scale={154 / 115} src={Utils.getUrl(cover_url)} />
                      </div>
                      <div className="home-news-content-container">
                        <span className="home-news-content">{title}</span>
                        <span className="home-news-create-time">{create_at}</span>
                      </div>

                    </div>
                  );
                })}
              </div> */}
            {/* <div className="home-company-intro-container">
                <div className="home-company-intro-img">
                  <Image scale={642 / 250} src={companyIntroImg} />
                </div>
                <div className="home-company-intro-item-container">
                  <div className="home-company-intro-item" style={{ alignItems: 'flex-start' }}>
                    <FunIcon icon={'icon-company'} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span className="home-company-intro-item-bold-text">上海市黄浦区招商安商服务平台</span>
                      <span className="home-company-intro-item-bold-text" style={{ marginTop: '10px' }}>上海春申江创盈企业服务有限公司</span>
                    </div>
                  </div>
                  <div className="home-company-intro-item" style={{ alignItems: 'center' }}>
                    <FunIcon icon={'icon-location'} />
                    <span className="home-company-intro-item-bold-text1">上海黄浦区浙江中路400号春申江大厦18楼</span>
                  </div>
                  <div className="home-company-intro-item" style={{ alignItems: 'center' }}>
                    <FunIcon icon={'icon-phone'} />
                    <span className="home-company-intro-item-bold-text1">021-63511018</span>
                    <a className="home-contact-now" href='tel:021-63511018'>
                      <div>立即联系</div>
                    </a>
                  </div>
                </div>
              </div> */}
            {/* </div> */}

            {/* <CompanyIntro
              companyIntroClassName="home-company-intro-container"
              companyBgInfo={{ companyIcon: companyIntroImg, width: 642, height: 250 }}
              companyIntroContentClassName="home-company-intro-content"
              companyNameClassName="home-company-intro-name"
            /> */}
            <div className='home-company-intro-container'>
              <Image scale={642 / 250} src={homeBottomImg} />
              <a className="home-company-intro-contact" href='tel:021-63511018'>
                <div />
              </a>
            </div>
            <div className="home-company-icp-container">
              <a className="home-company-icp" onClick={() => window.location.href = 'https://beian.miit.gov.cn/'}>
                沪ICP备2021032302号
              </a>
            </div>
          </div >
          <Footer>
            <TabBar activeIndex={0} items={Tabs} history={this.props.history} />
          </Footer>
        </FunLayout >
      </div >
    )
  }
}
