import React, { Component } from 'react'
import FunLayout, { EnLayoutType } from '@/components/fun-layout';
import Header from '@/components/header';
import Image from '@/components/image';
import FunToast from '@/components/fun-toast';
import { eshiminSetTitleStyle } from '@/common/eshiminUtils';
import activityLabelImg from '@/public/images/icon-activity-label.png';
import model from './model';

import './index.scss'

export default class MyActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activityList: [],
    };
  }

  componentDidMount() {
    model.getMyActivity().then(res => {
      const { data = [] } = res;
      this.setState({ activityList: data || [] });
      if (data.length <= 0) {
        FunToast.show('您还没有参加活动哦～');
      }
    });
    eshiminSetTitleStyle('我的活动');
  }

  render() {
    const { activityList } = this.state;
    return (
      <div className="my-activity-container">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <Header hidden={false} hasBack={true} className={'top'} history={this.props.history}>
            我的活动
          </Header>
          <div className="my-activity-container">
            {
              activityList && activityList.map(item => {
                const { id, activityName = '', activityTime = '', startTime = '', endTime = '', activityProfile = '' } = item;
                return (
                  <div className="my-activity-item" key={id}>
                    <span className="my-activity-name">{activityName}</span>
                    <span className="my-activity-profile">活动简介：</span>
                    <span className="my-activity-profile-content">{activityProfile}</span>
                    <span className="my-activity-time">{`活动时间：${activityTime}  下午${startTime}-${endTime}`}</span>
                    <div className="my-activity-label-img">
                      <Image scale={63 / 63} src={activityLabelImg} />
                    </div>
                  </div>
                );
              })
            }
          </div>
        </FunLayout>
      </div>
    )
  }
}
