import { request } from '../../http';

class Model {

  getNotificationList(data) {
    return request('/h5/companyActivity/list', {
      method: 'POST',
      data,
    });
  }
}

export default new Model();
