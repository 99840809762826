import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.scss';

/**
 * 底部 Footer 栏
 */
export default class Footer extends Component {
  constructor(props) {
    super(props);

    this.content = React.createRef();
  }

  componentDidMount() {
    // 设置容器的高度为 Fixed 元素的高度，这样可以撑开页面，不会由于有 fixed 元素的存在导致页面高度混乱
    const contentEl = this.content.current;
    if (contentEl) {
      setTimeout(() => {
        contentEl.parentNode.style.height = contentEl.offsetHeight + 'px';
      }, 100);
    }
  }

  render() {
    const { className, children, bgColor } = this.props;

    return (
      <div className={classNames('fun-footer', className)}>
        <div className="footer-content" ref={this.content}>
          {children}
          <div className="placeholder" style={{ backgroundColor: bgColor }} />
        </div>
      </div>
    );
  }
}

Footer.propTypes = {
  className: PropTypes.string, // 自定义的 className
  bgColor: PropTypes.string // 背景色
};

Footer.defaultProps = {
  bgColor: 'transparent'
};