import { request } from '../../http';
class Model {

  // 企业登录
  login(data) {
    return request('/login/company', {
      method: 'POST',
      data,
    });
  }
}

export default new Model();
