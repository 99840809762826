import classNames from "classnames";
import { Component } from "react";
import FunLayout, { EnLayoutType } from '../../components/fun-layout';
import Header from '../../components/header';
import { eshiminSetTitleStyle } from '../../common/eshiminUtils';

import './index.scss';

export default class Privacy extends Component {

  componentDidMount() {
    eshiminSetTitleStyle('创盈企服用户协议');
  }

  render() {
    return (
      <div className="protocol-container">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <Header hidden={false} hasBack={true} className={'top'} history={this.props.history}>
            创盈企服隐私政策
          </Header>

          <div className="protocol-content-container">
            <div className="protocol-privacy-title">创盈企服隐私政策</div>
            <div className={classNames("protocol-title-bold", "protocol-p-top")}>生效日期：2021年12月1日</div>
            <div className={classNames("protocol-title-bold", "protocol-p-top")}>提示条款</div>

            <div className={classNames("protocol-p", "protocol-p-top")}>
              您的信任对我们非常重要，我们深知<span className="protocol-red">您（在本政策中亦称为“用户”）个人</span>
              信息的重要性。我们将按照本隐私政策的规定收集、使用、共享、存储及保护您的个人信息。
            </div>

            <div className={classNames("protocol-p", "protocol-p-top")}>
              本<span className="protocol-bold">《隐私政策》</span>是<span className="protocol-bold">《创盈企服用户协议》</span>
              不可分割的组成部分，构成一个统一的整体，您接受其中任一协议则代表接受本政策。在使用上海春申江创盈企业服务有限公司（以下简称“本公司”）各项服务前，请您务必仔细阅读并透彻理解本政策。
            </div>

            <div className={classNames("protocol-p", "protocol-p-top")}>
              如对本政策或本公司其他协议及政策内容有任何疑问，您可通过本政策末尾（第12条）公布的联系方式向本公司咨询或寻求帮助。
              <span className="protocol-bold">如对本政策或本公司其他协议及政策内容有任何疑问，您可通过本政策末尾（第12条）公布的联系方式向本公司咨询或寻求帮助。</span>
            </div>

            <div className={classNames("protocol-bold", "protocol-p-top")}>第一部分  定义</div>
            <div className={classNames("protocol-bold", "protocol-p-top")}>
              <div><span className="protocol-bold">个人信息：</span><span className="protocol-red"> 个人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。</span></div>
              <div><span className="protocol-bold">敏感个人信息：是指</span><span className="protocol-red">一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。</span></div>
              <div><span className="protocol-bold">删除个人信息：</span><span className="protocol-red"> 个人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。</span></div>
            </div>

            <div className={classNames("protocol-bold", "protocol-p-top")}>第二部分  隐私政策</div>
            <div className={classNames("protocol-bold", "protocol-p-top")}>第1条  适用范围</div>
            <div className={classNames("protocol-p", "protocol-p-top")}>
              为用户提供更专业、更优质、更个性化的服务是本公司坚持不懈的追求，也希望通过盈创企服平台提供的服务可以更方便用户的工作。本政策适用于盈创企服平台提供的所有服务。
            </div>
            <div className={classNames("protocol-bold", "protocol-p-top")}>第2条  用户个人信息的收集</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>2.1 盈创企服平台收集用户个人信息是为了向用户提供更专业、更优质、更个性化的服务。</div>
            <div className={classNames("protocol-p", "protocol-p-top")}>
              当用户注册盈创企服平台账户时，需要填写及/或提交<span className="protocol-bold">手机号码、地址、工作证明</span>拟使用的用户名和密码。如果用户仅需使用浏览、搜索服务，不需要注册盈创企服平台账户及提供上述信息。收集频率为使用该功能时一次性收集。
            </div>

            <div className={classNames("protocol-text", "protocol-p-top")}>
              2.2 用户在注册时提供自己的真实、有效的个人信息，并保证诸如<span className="protocol-bold">手机号码、地址、工作证明</span>信息等内容的真实性、有效性、完整性和及时更新，以便盈创企服平台为用户提供服务，并与用户进行及时、有效的联系。您理解并同意，<span className="protocol-red">活动预约、企业服务、专家预约等核心功能服务需要您正确提供上述个人信息，如</span>因您拒绝提供上述个人信息，或者提供的信息不真实、不完整或已失效，活动预约、企业服务、专家预约等核心功能服务<span className="protocol-red">将无法正确提供</span>；如果您拒绝提供其他个人信息的，或者通过盈创企服平台取消相关授权的，不影响您使用我们的核心功能，但是可能无法使用我们的部分非核心功能（具体以相关产品或服务的页面提示为准）。
            </div>

            <div className={classNames("protocol-text", "protocol-p-top")}>2.3 以下情形中，盈创企服平台收集、使用您的个人信息不必征得您的授权同意：</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（1）与个人信息控制者履行法律法规规定的义务相关的；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（2）与国家安全、国防安全直接相关的；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（3）与公共安全、公共卫生、重大公共利益直接相关的；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（4）与犯罪侦查、起诉、审判和判决执行等直接相关的；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（5）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（6）所涉及的个人信息是个人信息主体自行向社会公众公开的；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（7）根据个人信息主体要求签订和履行合同所必需的；注：隐私政策的主要功能为公开个人信息控制者收集、使用个人信息范围和规则，不应将其视为本条中的合同。</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（8）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（9）维护所提供产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</div>

            <div className={classNames("protocol-text", "protocol-p-top")}>2.4 用户了解并同意，以下信息不适用本政策：</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（1）用户在使用盈创企服平台提供的搜索服务时输入的关键字信息；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（2）信用评价、违反法律规定或违反盈创企服平台规则行为及盈创企服平台已对用户采取的措施；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（3）应法律法规要求需公示的企业名称等相关工商注册信息以及自然人经营者的信息。</div>

            <div className={classNames("protocol-bold", "protocol-p-top")}>第3条  用户个人信息的使用</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>3.1 因收集用户的信息是为了向用户提供服务及提升服务质量的目的，为了实现这一目的，盈创企服平台会把用户的信息用于下列用途：</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（1）盈创企服平台可能使用用户的个人信息以预防、发现、调查欺诈、危害安全、非法或违反与盈创企服平台或其关联方协议、政策或规则的行为，以保护用户、其他盈创企服平台用户，或盈创企服平台或其关联方的合法权益；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（2）用于审计、数据分析、研究等内部目的，以便用户可以更好地享受本公司提供的全面服务，同时改进本公司的产品与服务。</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（3）经用户许可的其他用途。</div>

            <div className={classNames("protocol-text", "protocol-p-top")}>3.2 盈创企服平台用户个人信息使用的规则：</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（1）我们会根据本政策的约定并为实现我们的产品与/或服务功能对所收集的个人信息进行使用。</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>
              （2）在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别主体。<span className="protocol-bold">请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。</span>
            </div>
            <div className={classNames("protocol-bold", "protocol-p-top")}>（3）请您注意，您在使用我们的产品与/或服务时所提供的所有个人信息，除非您删除或通过系统设置拒绝我们收集，否则将在您使用我们的产品与/或服务期间持续授权我们使用。在您注销账号时，我们将停止使用并删除您的个人信息。</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（4）我们会对我们的产品与/或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的产品与/或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（5）当我们展示您的个人信息时，我们会采用包括内容替换、匿名处理方式对您的信息进行脱敏，以保护您的信息安全。</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（6）当我们要将您的个人信息用于本政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会通过您主动做出勾选的形式事先征求您的同意。</div>

            <div className={classNames("protocol-bold", "protocol-p-top")}>第4条  用户个人信息的共享、提供和披露</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>4.1 盈创企服平台对用户的信息承担保密义务，不会违反法律规定向第三方出售用户的信息。盈创企服平台共享、转让个人信息的目的、数据接收方的类型如下：</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（1）事先获得用户的同意或授权，共享、转让经去标识化处理的个人信息，且确保数据接收方无法重新识别个人信息主体的除外；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（2）根据法律法规的规定或行政或司法机构的要求；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（3）因业务合作、拆分或合并目的，向盈创企服平台的关联方（包括但不限于控股子公司、受其控制的公司、受共同控制的兄弟公司等）分享、提供用户的个人信息；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（4）如用户出现违反中国有关法律、法规或者盈创企服平台相关协议或相关规则的情况，需要向包括但不限于权利人（包括盈创企服平台）、行政、司法机关等第三方披露等（包括进行公证、见证等证据保全）；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>
              （5）如用户是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露用户的<span className="protocol-bold">姓名、联系方式</span>信息，以便双方处理可能的权利纠纷；
            </div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（6）在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受此隐私权政策的约束，否则我们会要求该公司、组织重新向您征求授权同意；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（7）为维护盈创企服平台及其关联方或用户的合法权益。</div>

            <div className={classNames("protocol-text", "protocol-p-top")}>4.2 若用户希望了解数据接收方对其个人信息的保存、使用等情况，要求实现个人信息主体的权利，例如，访问、更正、删除、注销账户等，您还可以随时通过本政策末尾（第12条）公示的联系方式联系我们，我们将在30天内通过邮箱回复您的请求。</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>4.3 盈创企服平台不会将您的个人信息用于非法目的，也不会向任何用于非法目的的第三方分享您的个人信息。</div>

            <div className={classNames("protocol-text", "protocol-p-top")}>4.4 以下情形中，盈创企服平台共享、转让、公开披露个人信息无需事先征得用户的授权同意：</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（1）与个人信息控制者履行法律法规规定的义务相关的；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（2）与国家安全、国防安全直接相关的；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（3）与公共安全、公共卫生、重大公共利益直接相关的；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（4）与犯罪侦查、起诉、审判和判决执行等直接相关的；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（5）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（6）个人信息主体自行向社会公众公开的个人信息；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（7）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</div>

            <div className={classNames("protocol-bold", "protocol-p-top")}>第5条  自动收集工具的使用</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>5.1 为使用户获得更轻松的访问体验，用户访问盈创企服平台相关网站或使用盈创企服平台提供的服务时，盈创企服平台可能会通过小型数据文件识别用户的身份，这么做是帮用户省去重复输入注册信息的步骤，或者帮助判断用户的账户安全。这些数据文件可能是Cookie，Flash Cookie，或用户的浏览器或关联应用程序提供的其他本地存储（统称“Cookie”）。</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>5.2 请用户理解，盈创企服平台的某些服务只能通过使用“Cookie”才可得到实现。如果用户的浏览器或浏览器附加服务允许，用户可以修改对Cookie的接受程度或者拒绝盈创企服平台的Cookie，但这一举动在某些情况下可能会影响用户安全访问盈创企服平台相关网站和使用盈创企服平台提供的服务。</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>5.3 网页上常会包含一些电子图象（称为"单像素" GIF 文件或 "网络 Beacon"），使用网络Beacon可以帮助网站计算浏览网页的用户或访问某些Cookie，盈创企服平台会通过网络Beacon收集用户浏览网页活动的信息，例如用户访问的页面地址、用户先前访问的援引页面的位址、用户停留在页面的时间、用户的浏览环境以及显示设定等。</div>

            <div className={classNames("protocol-bold", "protocol-p-top")}>第6条  用户个人信息的存储</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>6.1 您的个人信息储存于中华人民共和国境内。除非获得您的特别同意，或者满足法律规定的评估及许可程序，我们不会跨境转移您的个人信息。</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>6.2 除非法律法规另有规定，我们将按如下期间保存您的信息</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（1）您使用我们的平台服务期间，我们将根据业务需要在法律许可的最长时间范围内持续为您保存个人信息，除非您自主取消授权、删除这些信息或者注销账户；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（2）您使用我们的平台服务期间，我们将根据业务需要在法律许可的最长时间范围内持续为您保存个人信息，除非您自主取消授权、删除这些信息或者注销账户；</div>

            <div className={classNames("protocol-bold", "protocol-p-top")}>第7条  用户个人信息的保护</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>7.1 为保障用户的信息安全，盈创企服平台努力采取各种合理的物理、电子和管理方面的安全措施来保护用户的信息，使用户的信息不会被泄漏、毁损或者丢失，包括但不限于SSL、信息加密存储、数据中心的访问控制。盈创企服平台对可能接触到用户的信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施。盈创企服平台会按现有技术提供相应的安全措施来保护用户的信息，提供合理的安全保障，盈创企服平台将尽力做到使用户的信息不被泄漏、毁损或丢失。</div>
            <div className={classNames("protocol-bold", "protocol-p-top")}>7.2 用户的账户均有安全保护功能，请妥善保管用户的账户及密码信息。盈创企服平台将通过向其它服务器备份、对用户密码进行加密等安全措施最大程度确保用户的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请用户理解在信息网络上不存在“完善的安全措施”。盈创企服平台无法承诺用户数据及信息的完全安全。</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>7.3 在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</div>

            <div className={classNames("protocol-bold", "protocol-p-top")}>第8条  未成年人保护</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>8.1 盈创企服平台的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，儿童（不满 14 周岁）不得创建自己的用户账户。</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>8.2 对于经父母同意而收集儿童个人信息的情况，盈创企服平台只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>8.3 如果盈创企服平台发现在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。</div>

            <div className={classNames("protocol-bold", "protocol-p-top")}>第9条  您的查询、更正及删除个人信息及申诉的权利</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>9.1 访问、更正、删除您的个人信息</div>
            <div className={classNames("protocol-p", "protocol-p-top")}>
              您有权访问、更正、删除您的个人信息，法律法规规定的例外情况除外。您可以随时通过本政策末尾（第12条）公示的联系方式联系我们，我们将在15天内通过邮箱回复您的访问、更正、删除请求。<span className="protocol-yellow-bg">您也可以通过邮箱请求获取您的个人信息副本，包括已收集的个人基本资料、身份信息、工作信息等。</span>
            </div>
            <div className={classNames("protocol-text", "protocol-p-top")}>9.2 在以下情形中，您可以向我们提出删除个人信息的请求：</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（1）如果我们违反法律法规规定，收集、使用个人信息的；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（2）如果我们违反与个人信息主体的约定，收集、使用个人信息的，违反法律法规规定或与个人信息主体的约定，共享、转让或公开披露个人信息的；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（3）如果您不再使用我们的产品或服务，或您注销了账号；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（4）如果我们不再为您提供产品或服务。</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>当您从我们的服务中删除信息后，我们可能不会立即在备份系统中删除相应的信息，但会在备份更新时删除这些信息。</div>

            <div className={classNames("protocol-text", "protocol-p-top")}>9.3 注销您的账户</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>您可以随时通过本政策末尾公示的联系方式联系我们，我们将在15天内通过邮箱回复您的注销的请求。</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>在注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息，法律法规另有规定的除外。</div>

            <div className={classNames("protocol-text", "protocol-p-top")}>9.4 对信息系统自动决策结果进行申诉</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>您可以随时通过本政策末尾公示的联系方式联系我们，说明您的申诉事件及理由，我们将在15天内通过邮箱回复您申诉处理结果。</div>

            <div className={classNames("protocol-text", "protocol-p-top")}>9.5 我们如何响应您的上述请求</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。我们将在<span className="protocol-yellow-bg">15</span>天内通过邮箱响应您的请求。</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝，但您仍然可以通过向行政部门投诉、或者向人民法院起诉的方式主张您的权利。</div>

            <div className={classNames("protocol-text", "protocol-p-top")}>9.6 在以下情形中，按照法律法规要求，我们将无法响应您的请求：</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（1）与我们履行法律法规规定的强制性义务相关的；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（2）与国家安全、国防安全直接相关的；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（3）与公共安全、公共卫生、重大公共利益直接相关的；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（4）与犯罪侦查、起诉、审判和判决执行等直接相关的；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（5）有充分证据表明您存在主观恶意或滥用权利的；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（6）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（7）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>（8）涉及商业秘密的。</div>

            <div className={classNames("protocol-bold", "protocol-p-top")}>第10条  <span className={classNames("protocol-bold", "protocol-underline")}>免责声明（非常重要）</span></div>
            <div className={classNames("protocol-bold", "protocol-p-top")}>10.1 您理解并同意，您自愿委托盈创企服平台使用您的个人信息。</div>
            <div className={classNames("protocol-bold", "protocol-p-top")}>10.2 请您理解，由于计算机及互联网技术的飞速发展及同步速度的限制，可能存在或出现各种恶意或非恶意的攻击手段。虽然我们持续致力于提升和加强安全措施，以保护您的个人信息免遭意外或破坏，但仍无法始终保证您的个人信息的百分之百安全。</div>
            <div className={classNames("protocol-bold", "protocol-p-top")}>10.3 请您理解，您使用产品或服务时所用的系统和通讯网络，或硬件设备等我们均无法控制，请您了解并注意保护您的个人信息安全。</div>
            <div className={classNames("protocol-bold", "protocol-p-top")}>10.4 例如所有的企业都可能面临的问题一样，考虑到信息技术、网络空间本身的特点，在某些特殊情况下（例如黑客攻击、电信故障等），尽管我们采取了法律、法规等规定的必要的措施，但仍然存在个人信息泄露的风险。除非本隐私政策有其他规定，为了保护您的人身及财产安全，我们特别提醒您不要通过公共网络，向我们或任何以我们名义为您提供服务的第三方，提供您的账户密码、验证码、通信内容等信息。</div>

            <div className={classNames("protocol-bold", "protocol-p-top")}>第11条  本政策如何更新</div>
            <div className={classNames("protocol-bold", "protocol-p-top")}>11.1 本隐私政策在页首载明生效日更新。</div>
            <div className={classNames("protocol-bold", "protocol-p-top")}>11.2 当适用的法律政策发生变更，或者我们的产品或服务功能发生重大变化的，我们的隐私政策可能变更。但是，未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。</div>
            <div className={classNames("protocol-bold", "protocol-p-top")}>11.3 对于本政策的重大变更，我们会提供显著的通知（包括会通过电子邮件、站内信等方式发送通知，或者页面弹窗的方式，等等）。</div>

            <div className={classNames("protocol-bold", "protocol-p-top")}>第12条  您如何联系盈创企服平台</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>12.1 如您对本隐私政策的内容，或对我们如何处理您的个人信息，有任何疑问、意见、建议、担忧、投诉或举报，请按照如下方式联系我们：</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>联系地址：上海市黄浦区浙江中路400号春申江大楼18楼</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>信息安全负责人：涂黄晨</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>联系电话：18601722786</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>联系邮箱：tuhc@shigu-info.com</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>我们会在15日内响应您的请求，并依据本隐私政策及法律法规处理您的请求。</div>
            <div className={classNames("protocol-text", "protocol-p-top")}>12.2 在经与我们沟通后，如您对我们的答复不满意，您可以联系负责个人信息保护相关投诉的有关行业协会、主管部门，或者依据适用的法律向<span className="protocol-red">我们所在地的</span>具有管辖权的法院提起诉讼。</div>
          </div>
        </FunLayout>
      </div>
    );
  }
}