import React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import './index.scss';
import PropTypes from 'prop-types';
import FunDynamicCore from "../fun-dynamic-core";
import classNames from 'classnames';


/**
 *
 * 使用方法
 *
 * FunDialog.confirm();
 * FunDialog.alert(“测试版的消息”);
 *
 * 注：无法使用props控制mask 已经被废弃 否则会导致重复渲染
 *
 */
export default class FunDialog extends FunDynamicCore {
  static propTypes = {
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    noTitle: PropTypes.bool,
    noCancel: PropTypes.bool,
    noConfirm: PropTypes.bool,
    backgroundColor: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string,
    toggle: PropTypes.bool, // 可以点击背景隐藏
    cancelText: PropTypes.string,
    confirmText: PropTypes.any,
    isShow: PropTypes.bool,
    customShow: PropTypes.bool,
    mainClassName: PropTypes.string,
  };

  static defaultProps = {
    noTitle: false,
    noCancel: false,
    noConfirm: false,
    backgroundColor: '#fff',
    toggle: true,
    title: '提示',
    cancelText: '取消',
    confirmText: '确定',
    customShow: false,
    isShow: true
  };

  constructor(props) {
    super(props);
    this.state = {
      isOkActive: false,
      isCancelActive: false
    }

  }

  onClickMask() {
    if (this.props.toggle) {
      super.hide();
    }
  }

  onClickClose() {
    super.hide();
    FunDynamicCore.doCall(this.props.onCancel);
  }

  onClickConfirm() {
    super.hide();
    FunDynamicCore.doCall(this.props.onConfirm);
  }

  onTouchStartCancel() {
    this.setState({
      isCancelActive: true
    })
  }

  onTouchEndCancel() {
    this.setState({
      isCancelActive: false
    })
  }

  onTouchStartOk() {
    this.setState({
      isOkActive: true
    })
  }

  onTouchEndOk() {
    this.setState({
      isOkActive: false
    })
  }

  render() {
    const isShow = this.props.customShow ? this.props.isShow : this.state.isShow;

    return (
      <div className={'dia-part'}>
        <CSSTransition
          in={isShow}
          timeout={300}
          classNames="fun-dialog"
          unmountOnExit
          onEnter={this.onEnter.bind(this)}
          onExit={this.onExit.bind(this)}
          onExited={this.onExited.bind(this)}
          onEntered={this.onEntered.bind(this)}
        >
          <div className="fun-dialog">
            <div className='fun-dialog-main' style={{ backgroundColor: this.props.backgroundColor }}>
              <div>
                {!this.props.noTitle && <div className="header-part">
                  <div className="fun-dialog-title">{this.props.title}</div>
                </div>}
              </div>
              <div className="fun-dialog-wrapper">
                <div className="fun-dialog-content">
                  {this.props.message}{this.props.children}
                </div>
              </div>
              {(!this.props.noCancel || !this.props.noConfirm) && <div className="line-bottom"> </div>}
              {(!this.props.noCancel || !this.props.noConfirm) &&
                <div className="fun-dialog-action">
                  {!this.props.noCancel ? <div className={"transition fun-dialog-cancel line-right" + (this.state.isCancelActive ? ' active' : '')}
                    onTouchMove={this.onTouchEndCancel.bind(this)}
                    onTouchEnd={this.onTouchEndCancel.bind(this)}
                    onTouchCancel={this.onTouchEndCancel.bind(this)}
                    onClick={this.onClickClose.bind(this)} onTouchStart={this.onTouchStartCancel.bind(this)}>
                    {this.props.cancelText}
                  </div> : ''}
                  {!this.props.noConfirm ? <div className={"transition fun-dialog-confirm" + (this.state.isOkActive ? ' active' : '')}
                    style={{ width: !this.props.noCancel ? '50%' : '100%' }}
                    onTouchMove={this.onTouchEndOk.bind(this)}
                    onTouchEnd={this.onTouchEndOk.bind(this)}
                    onTouchCancel={this.onTouchEndOk.bind(this)}
                    onTouchStart={this.onTouchStartOk.bind(this)}
                    onClick={this.onClickConfirm.bind(this)}>
                    {this.props.confirmText}
                  </div> : ''}
                </div>}

            </div>
            <div className={'fun-dialog-back'} onClick={this.onClickMask.bind(this)}></div>
          </div>
        </CSSTransition>
      </div>);
  }

  static dismiss() {
    this.setState({ isShow: false });
  }

  /**
   *
   * @param messageObj
   * messageObj
   * title, message, onHide
   */
  static alert(messageObj) {
    let message, title, onHide, isAutoDispose = true;
    if (typeof messageObj === "object") {
      message = messageObj.message;
      title = messageObj.title;
      onHide = messageObj.onHide;
      isAutoDispose = messageObj.isAutoDispose !== undefined ? messageObj.isAutoDispose : true;
    } else {
      message = messageObj;
    }

    return FunDialog.show({
      isAutoDispose,
      title,
      message,
      noCancel: true,
      onHide: onHide
    });

    FunDynamicCore.blur();
  }

  /**
   *
   * @param title 消息标题
   * @param message 消息内容
   * @param onConfirm 确定回掉
   * @param onCancel 取消回掉
   *
   */
  static confirm({ title, message, onConfirm, onCancel, isAutoDispose = true, cancelText, confirmText }) {
    return FunDialog.show({
      isAutoDispose,
      title,
      message,
      onConfirm,
      onCancel,
      cancelText,
      confirmText,
      noCancel: false
    });
  }

  /**
   *
   * @param cancelText
   * @param confirmText
   * @param title
   * @param message
   * @param onConfirm
   * @param onCancel
   * @param onHide
   * @param isAutoDispose
   * @param noCancel
   * @returns {{current, hide, show}}
   *
   */
  static show({ title,
    message,
    cancelText,
    confirmText,
    onConfirm,
    onCancel,
    onHide,
    isAutoDispose = true,
    noTitle,
    noCancel,
    noConfirm,
    toggle,
    children,
    backgroundColor }) {

    let container = document.createElement("div"), instance = FunDynamicCore.getInstanceRef();
    container.className = "_dialog-container";
    document.body.appendChild(container);

    ReactDOM.render(<FunDialog
      instance={instance}
      isAutoDispose={isAutoDispose}
      title={title}
      message={message}
      cancelText={cancelText}
      confirmText={confirmText}
      onConfirm={onConfirm}
      onCancel={onCancel}
      onHide={onHide}
      noTitle={noTitle}
      noCancel={noCancel}
      noConfirm={noConfirm}
      container={container}
      toggle={toggle}
      children={children}
      backgroundColor={backgroundColor}
    />, container);

    return instance;
  }
}