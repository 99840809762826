import { Component } from "react";
import FunIcon from "../fun-icon";
import classNames from "classnames";
import "./index.scss";
export default class CommonButton extends Component {
  onButtonClick(e) {
    const { onClick, type, stopPropagation = true } = this.props;
    if (onClick && onClick instanceof Function && type !== 'disabled') {
      onClick();
    }
    if (stopPropagation) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  onGetIconColor() {
    const { type } = this.props;
    if (type === 'normal') {
      return '#CBB486';
    } else if (type === 'primary') {
      return '#fff';
    } else if (type === 'disabled') {
      return '#66604c'
    }
  }

  render() {
    const { className, iconColor, textClassName, style, icon, text, type = "normal" } = this.props;
    return (
      <div
        style={style}
        className={classNames("common-button-container", "common-button-" + type, className)}
        onClick={this.onButtonClick.bind(this)
        }
      >
        <div className={"common-button-content"} >
          <FunIcon icon={icon} iconStyle={{ color: iconColor || this.onGetIconColor(type), fontSize: '12px' }} iconFontSize={12} />
          <span className={classNames("common-button-text-" + type, textClassName)}>{text}</span>
        </div>
      </div >
    );
  }
}