import { Component } from "react";
import CommonButton from "../../components/common-button";
import FunIcon from "../../components/fun-icon";
import Image from "../../components/image";
import Utils from "../../common/utils";
import './index.scss';
export default class CompanyList extends Component {

  onItemButtonClick(item) {
    const { onClick } = this.props;
    if (onClick && onClick instanceof Function) {
      onClick(item);
    }
  }
  render() {
    const { companyList = [], icon, text, showTime = false, callMobile = false } = this.props;

    return (
      <div className="company-list-content-container">
        {companyList.map(item => {
          const { id, avatar, name, profile, create_at, mobile, labels, } = item;
          return (
            <div className="company-list-content-item" key={id}>
              <div className="company-list-content-top">
                <div className="company-list-avater">
                  <Image scale={1} src={Utils.getUrl(avatar)} />
                </div>
                <div className="company-list-name-profile">
                  <span className="company-list-name">{name}</span>
                  <div
                    className="company-list-profile"
                    dangerouslySetInnerHTML={{
                      __html:
                        profile.replace(/(\r\n|\n|\r)/gm, '<br />'),
                    }}
                  />
                  <div className="company-list-tag-container">
                    {labels && labels.map(item => {
                      const { id, label } = item;
                      return label && label.split('，') && label.split('，').map(labelItem => {
                        return <span className="company-list-tag" id={id}>{labelItem}</span>
                      })
                    })}
                  </div>
                </div>
              </div>

              <div
                className="company-list-bottom"
                style={{ justifyContent: showTime ? "space-between" : "flex-end" }}
              >
                {showTime && <div className="company-list-time-container">
                  <FunIcon icon="icon-date" />
                  <span>{create_at}</span>
                </div>}

                {
                  callMobile ?
                    <a href={`tel:${mobile}`}>
                      <CommonButton
                        icon={icon}
                        text={text}
                        type='primary'
                        stopPropagation={false}
                      />
                    </a> :
                    <CommonButton
                      icon={icon}
                      text={text}
                      type='primary'
                      onClick={() => this.onItemButtonClick(item)}
                    />
                }
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}