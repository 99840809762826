import { request } from '../../http';
class Model {
  register(data) {
    return request('/companyAccount/add', {
      method: 'POST',
      data,
    });
  }
}

export default new Model();
