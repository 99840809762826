import React, { Component } from 'react'
import FunCommonInput, { EnDataType } from '../../components/common-input';
import FileUpload from '../../components/file-upload';
import FunButton, { EnButtonSize, EnButtonType } from '../../components/fun-button';
import FunIcon from '../../components/fun-icon';
import FunLayout, { EnLayoutType } from '../../components/fun-layout';
import FunPicker from '../../components/fun-picker';
import Header from '../../components/header';
import Image from '../../components/image';
import uploadImg from '../../public/images/icon-company-upload.png';
import model from './model';

import './index.scss'
import { IndustryEnum, IndustryList } from '../../common/constant';
import Utils from '../../common/utils';
import upload from '../../common/upload';
import FunToast from '../../components/fun-toast';
export default class EditCompany extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    const companyId = query.get('companyId');
    this.state = {
      industryName: '企业所属行业',
      companyId,
      companyInfo: {},
      logoUrls: [],
      licenseUrls: [],
    };
    this.industryPickerRef = React.createRef();
    this.tagRef = React.createRef();
    this.profileRef = React.createRef();
    this.form = {
      companyName: '',
      profile: '',
      industry: '',
      phoneNumber: '',
      email: '',
      logo: '',
      businessLicense: '',
      labels: [],
    };
  }

  componentDidMount() {
    const { companyId, logoUrls = [], licenseUrls = [] } = this.state;
    model.getCompanyDetail(companyId).then(res => {
      const {
        name,
        labels = [],
        profile,
        industry_id,
        mobile,
        email,
        logo_url,
        license_url,
      } = res;
      this.form = {
        companyName: name,
        profile: profile,
        labels: labels.map(item => item.label),
        industry: industry_id,
        phoneNumber: mobile,
        email: email,
        logo: logo_url,
        businessLicense: license_url,
      }
      logoUrls.splice(0, logoUrls.length);
      logoUrls.push(logo_url);
      licenseUrls.splice(0, licenseUrls.length);
      licenseUrls.push(license_url);
      this.setState({
        companyInfo: res || {},
        industryName: IndustryEnum[industry_id],
        logoUrls,
        licenseUrls,
      });
    });
  }

  onShowIndustrySelect() {
    this.industryPickerRef.current.show();
  }


  onSelectIndustry(_, value) {
    console.log('onSelectIndustry', value);
    const { id, name } = value;
    this.form.industry = id;
    this.setState({ industryName: name });
  }

  onUploadLogo(files) {
    if (!files?.length) {
      this.form.logo = '';
      this.setState({ logoUrls: [] });
      return;
    }
    const file = files[0];
    upload.uploadImg(file).then(res => {
      const { code, data: { scr } } = res;
      if (code === 200) {
        const { logoUrls = [] } = this.state;
        logoUrls.splice(0, logoUrls.length);
        logoUrls.push(scr);
        this.form.logo = scr;
        this.setState({ logoUrls });
      }
    });
  }

  onUploadLicense(files) {
    if (!files?.length) {
      this.form.businessLicense = '';
      this.setState({ licenseUrls: [] });
      return;
    }
    const file = files[0];
    upload.uploadImg(file).then(res => {
      const { code, data: { scr } } = res;
      if (code === 200) {
        const { licenseUrls = [] } = this.state;
        licenseUrls.splice(0, licenseUrls.length);
        licenseUrls.push(scr);
        this.form.businessLicense = scr;
        this.setState({ licenseUrls });
      }
    });
  }

  onChangeField(field, value) {
    if (field === 'companyName') {
      this.form.companyName = value;
    } else if (field === 'phoneNumber') {
      this.form.phoneNumber = value;
    } else if (field === 'email') {
      this.form.email = value;
    }
  }

  onTagChange(e) {
    const target = e ? e.target : this.tagRef.current;
    const value = target.value || '';
    this.form.labels = value.split('、');
  }

  onProfileChange(e) {
    const target = e ? e.target : this.profileRef.current;
    const value = target.value || '';
    this.form.profile = value;
  }

  onCheckParam() {
    const { companyName, labels = [], profile, industry, phoneNumber, email, logo, businessLicense } = this.form;
    if (!companyName) {
      return '请输入企业名称';
    }
    if (labels && labels.length <= 0) {
      return '请输入企业标签';
    }
    if (!profile) {
      return '请输入企业简介';
    }
    if (!industry) {
      return '请选择企业所属行业';
    }
    if (!phoneNumber) {
      return '请输入企业电话';
    }
    if (!email) {
      return '请输入企业邮箱';
    }
    if (!logo) {
      return '请上传企业LOGO';
    }
    if (!businessLicense) {
      return '请上传营业执照';
    }
  }


  onSave() {
    const error = this.onCheckParam();
    if (error) {
      return FunToast.show(error, 2);
    }
    const { companyInfo = {} } = this.state;
    const { id } = companyInfo;
    model.saveCompanyDetail({ id, ...this.form }).then(() => {
      this.props.history.goBack();
    })
  }

  render() {
    const { industryName, companyInfo = {}, logoUrls = [], licenseUrls = [] } = this.state;
    const {
      name,
      labels = [],
      mobile,
      email,
      profile,
    } = companyInfo;
    return (
      <div className="edit-company-container" >
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <Header hidden={false} hasBack={true} className={'top'} history={this.props.history}>
            编辑企业信息
          </Header>

          <FunCommonInput
            dataType={EnDataType.none}
            placeholder={'填写企业名称'}
            icon={'icon-home'}
            defaultValue={name}
            onChange={value => this.onChangeField('companyName', value)}
          />

          <textarea
            ref={this.tagRef}
            onChange={this.onTagChange.bind(this)}
            placeholder={'填写企业标签，以“、”分隔，例：软件研发，品牌设计'}
            cols="30"
            rows="4"
            defaultValue={labels.map(item => item.label).join('、')}
          />

          <textarea
            ref={this.profileRef}
            onChange={this.onProfileChange.bind(this)}
            placeholder={'编辑企业简介'}
            cols="30"
            defaultValue={profile}
            rows="6"
          />

          <div className="edit-company-industry-select-container" onClick={this.onShowIndustrySelect.bind(this)}>
            <span className="edit-company-industry-select-text">{industryName}</span>
            <FunIcon icon={"icon-arrow-down-filling"} />
          </div>

          <FunCommonInput
            className={"edit-company-item"}
            dataType={EnDataType.none}
            placeholder={'企业电话'}
            icon={'icon-phone'}
            defaultValue={mobile}
            onChange={value => this.onChangeField('phoneNumber', value)}
          />

          <FunCommonInput
            className={"edit-company-item"}
            dataType={EnDataType.none}
            placeholder={'企业邮箱'}
            icon={'icon-email'}
            defaultValue={email}
            onChange={value => this.onChangeField('email', value)}
          />

          <div className="edit-company-upload">
            <div className='edit-company-upload-item'>
              <span>上传企业LOGO</span>
              <FileUpload
                fileList={(logoUrls || []).map((item) => ({
                  url: Utils.getUrl(item),
                }))}
                selectable={!logoUrls.length}
                onChange={this.onUploadLogo.bind(this)}
                accept="image/jpeg,image/jpg,image/png"
                FileListItem={Image}
                fileListItemClassName="edit-company-upload-img"
                uploadComponent={
                  <div className="edit-company-upload-img">
                    <Image scale={1} src={uploadImg} />
                  </div>
                }
              />
            </div>
            <div className='edit-company-upload-item'>
              <span>上传营业执照</span>
              <FileUpload
                fileList={(licenseUrls || []).map((item) => ({
                  url: Utils.getUrl(item),
                }))}
                selectable={!licenseUrls.length}
                onChange={this.onUploadLicense.bind(this)}
                accept="image/jpeg,image/jpg,image/png"
                FileListItem={Image}
                fileListItemClassName="edit-company-upload-img"
                uploadComponent={
                  <div className="edit-company-upload-img">
                    <Image scale={1} src={uploadImg} />
                  </div>
                }
              />
            </div>
          </div>

          <div className="edit-company-button-cotnainer">
            <FunButton
              className="edit-company-register"
              type={EnButtonType.white}
              size={EnButtonSize.big}
              onClick={() => this.props.history.goBack()}
            >
              取消
            </FunButton>
            <FunButton
              className="edit-company-register"
              type={EnButtonType.primary}
              size={EnButtonSize.big}
              onClick={this.onSave.bind(this)}
            >
              确定
            </FunButton>
          </div>

        </FunLayout>

        <FunPicker
          onChange={this.onSelectIndustry.bind(this)}
          ref={this.industryPickerRef}
          title="选择所属行业"
          displayFiled="name"
          valueFiled="id"
          defaultValue={industryName}
          data={[{
            options: IndustryList
          }]}
        />
      </div>
    )
  }
}
