import { request } from '../../http';
class Model {
  getCompanyUserInfo(data) {
    return request('/company/personal', {
      method: 'POST',
      data,
    });
  }
}

export default new Model();
