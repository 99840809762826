import React, { Component, Fragment } from "react";
import FunCommonInput, { EnDataType } from "@/components/common-input";
import FunIcon from "@/components/fun-icon";
import FunLayout, { EnLayoutType } from "@/components/fun-layout";
import FunPicker from "@/components/fun-picker";
import Header from "@/components/header";
import ScrollView from "@/components/scroll-view";
import Footer from "@/components/footer";
import TabBar from "@/components/tab-bar";
import PolicyItem from "@/pages/policy/component/policy-item";
import { IndustryList, Tabs } from "@/common/constant";
import LocalStore from "@/store/localStore";
import { eshiminSetTitleStyle } from "@/common/eshiminUtils";
import model from "../model";

import './index.scss';

export const PolicyTypeList = [
  { id: 1, name: '企业培育政策' },
  { id: 2, name: '产业促进政策' },
  { id: 3, name: '创新扶持政策' },
  { id: 4, name: '评估认定政策' },
  { id: 5, name: '人才发展政策' },
  { id: 6, name: '知识产权政策' },
  { id: 99, name: '其他政策' },
];
export default class PolicyList extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    const industryType = query.get('industryId') || '';
    let industryName = '筛选产业';
    const selectList = IndustryList.filter(item => `${item.id}` === industryType);
    if (selectList && selectList.length > 0) {
      industryName = selectList[0].name;
    }

    this.state = {
      policyList: [],
      itemData: null,
      isCollect: false,
      title: '',
      policyType: '',
      policyTypeName: '筛选分类',
      industryType,
      industryName,
      page: 1,
      pageSize: 30,
      total: 0,
    };
    this.typePickerRef = React.createRef();
    this.industryPickerRef = React.createRef();

    const { title = '', policyType = '', page, pageSize } = this.state;
    this.onGetPolicyList(title, policyType, industryType, page, pageSize);
  }

  componentDidMount() {
    const { title = '' } = this.state;
    // this.onGetPolicyList(title, policyType, industryType, page, pageSize);
    eshiminSetTitleStyle(title);
  }

  onGetPolicyList(title, policyType, industry, page, pageSize) {
    model.getPolicyList(title, policyType, industry, page, pageSize).then(res => {
      const { policyList = [] } = this.state;
      if (page === 1) {
        policyList.splice(0, policyList.length);
      }
      const hasLogin = LocalStore.get('hasLogin');
      const { data = [], total } = res;
      LocalStore.set('policyList', data);
      if (hasLogin) {
        const ids = [];
        data.map(item => {
          return ids.push(item.id || '');
        });
        model.checkIfCollect({ ids }).then(res => {
          const collectList = res || [];
          const newData = [];
          data.map((item, index) => {
            return newData.push({ ...item, isCollect: collectList[index][item.id] });
          });
          this.setState({ policyList: policyList.concat(newData), total, page });
          console.log(policyList.concat(newData))

        }).catch(() => {
          this.setState({ policyList: policyList.concat(data), total, page });
        });
      } else {
        this.setState({ policyList: policyList.concat(data), total, page });
      }
    });
  }

  onSelectPolicyType(_, value) {
    const { id, name } = value;
    const { title = '', industryType, pageSize } = this.state;
    this.onGetPolicyList(title, id, industryType, 1, pageSize);
    this.setState({ policyType: id, policyTypeName: name })
  }

  onSelectIndustryType(_, value) {
    const { id, name } = value;
    const { title = '', policyType = '', pageSize } = this.state;
    this.onGetPolicyList(title, policyType, id, 1, pageSize);
    this.setState({ industryType: id, industryName: name });
  }

  onChange(value) {
    const { policyType = '', industryType = '', pageSize } = this.state;
    this.onGetPolicyList(value, policyType, industryType, 1, pageSize);
  }

  onShowPolicyTypeSelect() {
    this.typePickerRef.current.show();
  }

  onShowIndustryelect() {
    this.industryPickerRef.current.show();
  }

  onShowOriginal(item) {
    this.props.history.push(`/policy-original?id=${item?.id}`);
  }

  // onCheckIfCollect(id, item) {
  //   const param = {
  //     ids: [id],
  //   };
  //   model.checkIfCollect(param).then(res => {
  //     if (res && res.length > 0) {
  //       this.setState({ isShowOriginal: true, itemData: item, isCollect: res[0][id] });
  //     } else {
  //       this.setState({ isShowOriginal: true, itemData: item, isCollect: false });
  //     }
  //   }).catch(() => {
  //     this.setState({ isShowOriginal: true, itemData: item, isCollect: false });
  //   });
  // }

  onAnalyze(id) {
    this.props.history.push(`/policy-analyze?id=${id}`);
  }

  onLoad() {
    let { title = '', id, policyType = '', page, pageSize, policyList = [], total } = this.state;
    if (policyList.length < total) {
      page = page + 1;
      this.onGetPolicyList(title, policyType, id, page, pageSize);
    }
  }

  render() {
    const { policyList, total, policyTypeName, industryName } = this.state;
    return (
      <div className="policy-list-container">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <Header hidden={false} hasBack={false} className={'top'} history={this.props.history}>政策解读</Header>

          <div className="policy-list-container">
            <ScrollView
              onLoad={this.onLoad.bind(this)}
              noMore={policyList.length === total}
            >
              <Fragment>
                <div className="policy-search-container">
                  <FunCommonInput
                    dataType={EnDataType.none}
                    placeholder={'搜索政策'}
                    leftIcon={'icon-search'}
                    noBorder
                    noFocus
                    onChange={this.onChange.bind(this)}
                  />
                  <div className="policy-search">
                    <div className="policy-list-type-container" onClick={this.onShowIndustryelect.bind(this)}>
                      <FunIcon icon='icon-arrow-down-filling' />
                      <span className="policy-list-type">{industryName}</span>
                    </div>
                    <div style={{ width: '12px' }} />
                    <div className="policy-list-type-container" onClick={this.onShowPolicyTypeSelect.bind(this)}>
                      <FunIcon icon='icon-arrow-down-filling' />
                      <span className="policy-list-type">{policyTypeName}</span>
                    </div>
                  </div>
                </div>
                <PolicyItem
                  type={1}
                  policyList={policyList}
                  history={this.props.history}
                  onPolicyClick={item => this.onShowOriginal(item)}
                  onAnalyze={this.onAnalyze.bind(this)}
                />
                <div style={{ height: '17px' }} />
              </Fragment>
            </ScrollView>
          </div>

          <Footer>
            <TabBar activeIndex={1} items={Tabs} history={this.props.history} />
          </Footer>
        </FunLayout>

        <FunPicker
          onChange={this.onSelectPolicyType.bind(this)}
          ref={this.typePickerRef}
          title="选择筛选分类"
          displayFiled="name"
          valueFiled="id"
          defaultValue={policyTypeName}
          data={[{
            options: PolicyTypeList
          }]}
        />
        <FunPicker
          onChange={this.onSelectIndustryType.bind(this)}
          ref={this.industryPickerRef}
          title="选择产业分类"
          displayFiled="name"
          valueFiled="id"
          defaultValue={industryName}
          data={[{
            options: IndustryList
          }]}
        />
      </div>
    );
  }
}