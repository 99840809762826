import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FunModal from "../fun-modal";
import PickerWheel from "./PickerWheel";

import './icon/style.css';
import './style.scss';

/**
 *  时间选择器
 */

const genYears = () => {
  //生成年份选项
  let dateVal = new Date();
  const currentYear = dateVal.getFullYear() + 100;
  let years = [];
  for (let i = 0; i < 200; i++) {
    let id = dateVal.getFullYear() * 10000 + dateVal.getMonth() * 100 + dateVal.getDate();
    let optionYear = currentYear - 200 + i + 1;
    let yearItem = {
      id: id,
      value: i,
      year: optionYear,
      content: (<div className="date-container">
        <span className="date">{`${optionYear}年`}</span>
      </div>)
    };
    years.push(yearItem);
  }
  return years;
}

const genMonths = () => {
  //生成月份选项
  let months = [];
  for (let i = 0; i < 12; i++) {
    let monthItem = {
      id: i,
      value: i,
      content: (i + 1) + '月'
    };
    months.push(monthItem);
  }
  return months;
}

export default class TimePicker extends Component {
  static propTypes = {
    //class prefix
    prefixCls: PropTypes.string,
    //标题
    title: PropTypes.string,
    //(indexArr, data) {Function} 确认时的回调
    ensureHandle: PropTypes.func,
    //{Function} 关闭时的回调
    cancelHandle: PropTypes.func,
    //初始时间(2018-08-09 09:00)，默认选择第：1, 0, 0
    initValues: PropTypes.string,
  };

  static defaultProps = {
    prefixCls: "date-picker",
    title: "时选择",
  };

  constructor(props) {
    super(props);

    this.state = {
      //日期，小时，分钟三个滑轮的index
      yearIndex: 59,
      monthIndex: 0,
      dateIndex: 0
    };

    //选项数据
    this.years = genYears();
    this.months = genMonths();
    this.dates = [];

    //modal init
    this.modalRef = React.createRef();

    //是否展示数据
    this.show = false;
  }

  showMe() {
    this.modalRef.current.show();
  }

  onVisible() {
    const { initValues } = this.props;
    const [year, month, date] = initValues.split('-');
    this.datasUpdate([year * 1, month * 1, date * 1]);
    this.show = true;
  }

  onDismiss() {
    let value = this.getValue();
    this.props.cancelHandle && this.props.cancelHandle(value);
    this.show = false;
  }

  onOk() {
    let value = this.getValue();
    this.props.ensureHandle && this.props.ensureHandle(value);
    this.show = false;
  }

  /**
   * 数据更新
   * @param {Array} initValues 初始值，不能匹配时，选最近的值
   * @param {Number} type 0-所有，1-小时/分钟，2-分钟 
   */
  datasUpdate(initValues, type = 0) {
    let initValue = initValues[2];
    let { yearIndex, monthIndex, dateIndex } = this.state;
    if (initValues[0]) {
      yearIndex = initValues[0];
    }
    if (initValues[1]) {
      monthIndex = initValues[1];
    }

    //生成日期选项
    this.dates = [];
    let currentYear = this.years[yearIndex].year || new Date().getFullYear();
    let currentMonth = monthIndex;
    if (`${monthIndex}` === '11') {
      currentYear++;
      currentMonth = 0;
    } else {
      currentMonth++;
    }
    const countDays = new Date(new Date(currentYear, currentMonth, 1) - 1).getDate();
    let found = false;
    for (let i = 0; i < countDays; i++) {
      let dateItem = {
        id: i,
        value: i,
        date: i + 1,
        content: (i + 1) + '日'
      };
      //默认值
      if (!!initValue && dateItem.value == initValue) {
        dateIndex = i;
        found = true;
      }
      this.dates.push(dateItem);
    }
    this.dates.length = countDays;
    !found && (!initValue ? dateIndex = 0 : (dateIndex = countDays - 1));

    this.setState({ yearIndex, monthIndex, dateIndex });
  }

  getValue = () => {
    const { yearIndex, monthIndex, dateIndex } = this.state;
    return [yearIndex, monthIndex, dateIndex];
  }

  /**
   * 日期时间格式化
   */
  dateFtt = function (fmt, date) {
    var o = {
      "M+": date.getMonth() + 1,                 //月份   
      "d+": date.getDate(),                    //日   
      "h+": date.getHours(),                   //小时   
      "m+": date.getMinutes(),                 //分   
      "s+": date.getSeconds(),                 //秒   
      "q+": Math.floor((date.getMonth() + 3) / 3), //季度   
      "S": date.getMilliseconds()             //毫秒   
    };
    if (/(y+)/.test(fmt))
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
      if (new RegExp("(" + k + ")").test(fmt))
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
  }

  onValueChange(value, index) {
    const { yearIndex, monthIndex, dateIndex } = this.state;
    if (index === 0 && value !== yearIndex) {
      this.setState({
        yearIndex: value
      })
      this.datasUpdate([null, null, dateIndex], index);
    } else if (index === 1 && value !== monthIndex) {
      this.setState({
        monthIndex: value
      })
      this.datasUpdate([null, null, dateIndex], index);
    } else if (index === 2 && value !== dateIndex) {
      this.setState({
        dateIndex: value
      })
    }
  }

  getContent() {
    const { yearIndex, monthIndex, dateIndex } = this.state;
    let columns = [];

    for (let i = 0; i < 3; i++) {
      columns.push(
        <PickerWheel
          key={i}
          value={i === 0 ? yearIndex : (i === 1 ? monthIndex : dateIndex)}
          listData={i === 0 ? this.years : (i === 1 ? this.months : this.dates)}
          onChange={(value) => this.onValueChange(value, i)}
        />
      );
    }

    return columns;
  }

  render() {
    const { prefixCls, title } = this.props;
    return (
      <div className={prefixCls}>
        <FunModal wrapperStyle={{ minHeight: '100px' }} ref={this.modalRef}
          hasConfirm={true}
          onConfirm={() => this.onOk()}
          isShow={false}
          unmountOnExit={false}
          onShow={() => this.onVisible()}
          title={title}
          onHide={() => this.onDismiss()}>
          {this.show ?
            <div className={`${prefixCls}-container`}>
              {this.getContent()}
            </div> : null
          }
        </FunModal>
        {this.props.children}
      </div>
    )
  }

}