import dayjs from 'dayjs';
import isLeapYear from 'dayjs/plugin/isLeapYear'; // 导入插件
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import 'dayjs/locale/zh-cn'; // 导入本地化语言

dayjs.extend(isLeapYear); // 使用插件
dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.locale('zh-cn'); // 使用本地化语言

export default dayjs;

export const dayFormat = (date, formatString) => {
  return dayjs(date, formatString);
};

export const format = (date, formatString) => {
  if (!date) {
    return '';
  }
  return dayjs(date).format(formatString);
};


export const dateFormat = (date) => {
  if (!date) {
    return '';
  }
  return dayjs(date).format('YYYY.MM.DD');
};


export const dateValueOf = (date) => {
  return dayjs(date).valueOf();
};

export const dateAdd = (date, value, unit) => {
  return dayjs(date).add(value, unit).valueOf();
};

export const uniteDate = (baseDate, selectDate): number => {
  const year = dayjs(baseDate).get('year');
  const month = dayjs(baseDate).get('month');
  const date = dayjs(baseDate).get('date');

  return dayjs(selectDate).set('year', year).set('month', month).set('date', date).valueOf();
};
