import { Component } from "react";
import FileUpload from "../../components/file-upload";
import CompanyItem from "../choose-company/company-item";
import uploadImg from '../../public/images/icon-company-upload.png';
import Image from "../../components/image";
import FunLayout, { EnLayoutType } from "../../components/fun-layout";
import Header from "../../components/header";
import FunButton, { EnButtonSize, EnButtonType } from '../../components/fun-button';
import upload from "../../common/upload";
import model from './model';
import "./index.scss";
import FunToast from "../../components/fun-toast";
import { eshiminSetTitleStyle } from "../../common/eshiminUtils";
import Utils from "../../common/utils";

export default class BusinessCard extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    const token = query.get('otpRes') || '';
    const companyId = query.get('companyId') || '';
    const companyName = query.get('companyName') || '';
    const companyAvatarImg = query.get('companyAvatarImg') || '';
    this.state = {
      pictures: [],
      token,
      companyId,
      companyName,
      companyAvatarImg,
    };
  }

  componentDidMount() {
    eshiminSetTitleStyle('上传名片');
  }

  onUploadAvatar(files) {
    if (!files?.length) {
      this.setState({ pictures: [] });
      return;
    }
    const file = files[0];
    upload.uploadImg(file).then(res => {
      const { code, data: { scr } } = res;
      if (code === 200) {
        const { pictures = [] } = this.state;
        pictures.splice(0, pictures.length);
        pictures.push(scr);
        this.setState({ pictures });
      }
    });
  }

  onRegister() {
    const { pictures = [], token, companyId = '' } = this.state;
    const param = {
      token,
      companyId,
      businessCard: (pictures && pictures.length > 0 && pictures[0]) || '',
    }
    model.register(param).then(res => {
      FunToast.show('注册成功');
      this.props.history.replace('/login');
    });
  }
  render() {
    const { pictures = [], companyId = '', companyName = '', companyAvatarImg = '' } = this.state;
    return (
      <div className="business-card-container">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <Header className="top" hidden={false} hasBack={true} history={this.props.history}>
            上传名片
          </Header>
          <div>
            <CompanyItem
              companyId={companyId}
              companyName={companyName}
              companyAvatarImg={companyAvatarImg}
              isSelected
            />

            <div className="business-card-upload-container">
              <span className="business-card-upload-text">上传您的个人名片</span>
              <FileUpload
                fileList={(pictures || []).map((item) => ({
                  url: Utils.getUrl(item),
                }))}
                selectable={!pictures.length}
                onChange={this.onUploadAvatar.bind(this)}
                accept="image/jpeg,image/jpg,image/png"
                FileListItem={Image}
                fileListItemClassName="business-card-upload-img"
                uploadComponent={
                  <div className="business-card-upload-img">
                    <Image scale={1} src={uploadImg} />
                  </div>
                }
              />
            </div>
            <FunButton
              className="business-card-register"
              type={EnButtonType.primary}
              size={EnButtonSize.big}
              onClick={this.onRegister.bind(this)}
            >
              完成注册
            </FunButton>
          </div>
        </FunLayout>
      </div>
    );
  }
}