import { Component } from "react";
import Image from "@/components/image";
import FunIcon from "@/components/fun-icon";
import FunDialog from "@/components/fun-dialog";
import ImageGallery from "@/components/image-gallery";
import Platform from "@/common/platform";
import { eshiminSetTitleStyle } from "@/common/eshiminUtils";
import topImg from "@/public/images/icon-industry-introduction-top.webp";
// import topMaskImg from "../../public/images/icon-industry-introduction-top-mask.png";
import bridgeImg from "@/public/images/icon-bridge.png";
import subwayImg from "@/public/images/icon-subway.png";
import highwayImg from "@/public/images/icon-highway.png";
import tunnelImg from "@/public/images/icon-tunnel.png";
import economicStrengthImg from "@/public/images/icon-economic-strength.png";
import advancedIndustryImg from "@/public/images/icon-advanced-industry.png";
import headquartersImg from "@/public/images/icon-headquarters.png";
import serviceEconomicImg from "@/public/images/icon-service-economic.png";
import buildingEconomicImg from "@/public/images/icon-building-economic.png";
import industryMapImg from "@/public/images/icon-industry-map.webp";
import industryIntroOneImg from "@/public/images/icon-industry-intro-one.png";
import industryIntroTwoImg from "@/public/images/icon-industry-intro-two.png";
import industryIntroThreeImg from "@/public/images/icon-industry-intro-three.png";
import industryIntroFourImg from "@/public/images/icon-industry-intro-four.png";
import comoanyLeftImg from "@/public/images/icon-industry-company-left.png";
import comoanyRightImg from "@/public/images/icon-industry-company-right.png";
import comoanyImg from "@/public/images/icon-industry-company-bg.png";
import cityCardOneImg from "@/public/images/icon-industry-city-card-one.webp";
import cityCardTwoImg from "@/public/images/icon-industry-city-card-two.webp";
import cityCardThreeImg from "@/public/images/icon-industry-city-card-three.webp";
import cityCardFourImg from "@/public/images/icon-industry-city-card-four.webp";
import cityCardFiveImg from "@/public/images/icon-industry-city-card-five.webp";
import cityCardSixImg from "@/public/images/icon-industry-city-card-six.webp";
import industryBottomImg from "@/public/images/icon-industry-intro-bottom.png";

import "./index.scss";

const transportationList = [
  { id: 1, des: '2条', name: '跨江大桥', imgUrl: bridgeImg },
  { id: 2, des: '9条', name: '轨道境内相连', imgUrl: subwayImg },
  { id: 3, des: '3个方向', name: '城市高架道路', imgUrl: highwayImg },
  { id: 4, des: '5条', name: '越江隧道', imgUrl: tunnelImg },
];

const imageGallery = [
  { src: cityCardOneImg },
  { src: cityCardTwoImg },
  { src: cityCardThreeImg },
  { src: cityCardFourImg },
  { src: cityCardFiveImg },
  { src: cityCardSixImg },
];

const economyList = [
  { id: 1, des: '2021年全年黄浦区经济密度达每平方公里141.44亿元，全国领先。', imgUrl: economicStrengthImg },
  { id: 2, des: '2021年，以金融服务业为代表的高端服务业占据总税收的约80%', imgUrl: advancedIndustryImg },
  { id: 3, des: '2021年，黄浦区跨国公司地区总部63家，总部型企业总税收277.12亿元，占比达36.94%。', imgUrl: headquartersImg },
];

const economyList1 = [
  { id: 4, des: '2021年，黄浦区首店经济、节庆经济、夜间经济活力十足，近年来共引进国内外知名品牌首入店、旗舰店近300家。', imgUrl: serviceEconomicImg },
  { id: 5, des: '黄浦区税收亿元楼宇：70幢、区级税收亿元楼宇：36幢', imgUrl: buildingEconomicImg },
];

const industryIntroList = [
  { id: 1, color: 'sandybrown', name: '“一带”：', des: '融入金融科技的外滩金融集聚带', imgUrl: industryIntroOneImg, },
  { id: 2, color: '#C48989', name: '“两区”：', des: '以广慈-思南国家转化医学创新产业园、东方国际高端健康园为核心的生物医药集聚区', imgUrl: industryIntroTwoImg, },
  { id: 3, color: '#8AD1D1', name: '“两翼”：', des: '以北京东路地区、打浦路地区为核心的人工智能发展翼', imgUrl: industryIntroThreeImg, },
  { id: 4, color: '#BAAB82', name: '“N空间”：', des: '若干众创空间、孵化基地', imgUrl: industryIntroFourImg },
];

const industryList = [
  { id: 1, name: '科技产业', icon: 'icon-technology-industry', backgroundColor: '#6585C2' },
  { id: 2, name: '金融产业', icon: 'icon-finance-industry', backgroundColor: '#BAAB82' },
  { id: 3, name: '商贸产业', icon: 'icon-mall-industry', backgroundColor: '#C48989' },
  { id: 4, name: '文创产业', icon: 'icon-travel-industry', backgroundColor: '#80B87F' },
  { id: 5, name: '健康产业', icon: 'icon-health-industry', backgroundColor: '#8AD1D1' },
  { id: 7, name: '专业服务业', icon: 'icon-other-industry', backgroundColor: '#8A90D1' },
];
export default class IndustryIntroduction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollY: 0,
    };
    this.windowScroll = this.handleScroll.bind(this)
  }

  onBack() {
    this.props.history.goBack();
  }

  componentDidMount() {
    window.addEventListener('scroll', this.windowScroll);
    eshiminSetTitleStyle('产业介绍');
  }

  handleScroll() {
    this.setState({ scrollY: window.scrollY });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.windowScroll);
  }

  onItemClick(id) {
    FunDialog.show({
      title: '了解政策·咨询专家',
      onCancel: () => {
        this.props.history.push('/expert')
      },
      onConfirm: () => {
        this.props.history.push(`/policy-list?industryId=${id}`)
      },
      cancelText: '咨询专家',
      confirmText: '了解政策',
    });
  }

  render() {
    const { scrollY } = this.state;
    return (
      <div className="instry-introduction-container">
        <div className="instry-introduction-top-container">
          {!Platform.isApp && <div className="instry-introduction-title-container" style={{ backgroundColor: scrollY > 0 ? '#CBB486' : '' }}>
            <div onClick={() => this.onBack()}>
              <FunIcon icon={"icon-angle-left"}
                iconStyle={{ paddingLeft: '21px', color: '#fff' }}
              />
            </div>
            <span className="instry-introduction-title">产业介绍</span>
          </div>}


          <div className="instry-introduction-top-bg">
            <Image scale={750 / 824} src={topImg} />
          </div>
          <div className="instry-introduction-top-mask">
            {/* <Image scale={750 / 733} src={topMaskImg} /> */}
          </div>

          <div className="instry-introduction-top-content">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <span className="industry-introduction-top-title">黄浦区</span>
              <FunIcon icon="icon-circles" iconStyle={{ color: '#fff' }} />
              <span className="industry-introduction-top-title">上海原点 城市之心</span>
            </div>
            <p className="industry-introduction-content">黄浦区是上海中心城区核心区，以上海的母亲河——黄浦江命名，是上海的经济、行政、文化中心所在地。习近平总书记担任上海市委书记时，将黄浦区定位为“上海的心脏、窗口和名片”。区域东南临黄浦江，北临苏州河，三面环水，总面积20.52平方公里。黄浦区是中国共产党的诞生地、海派文化的发源地、民族工业的发祥地，承载了上海700余年的建城史和170余年的开埠史，见证了上海国际大都市的发展变化。</p>
            <p className="industry-introduction-content">社会文化事业资源十分丰富，上海博物馆、上海大剧院、文化广场等全市标志性文化设施矗立其间，上海音乐厅、人民大舞台、天蟾逸夫舞台等具有代表性的剧场在此集聚，拥有上海社科院、交大医学院等一批知名科研院所、7所上海市实验性示范性高级中学和10家三级医院。城区面貌日新月异，外滩、人民广场、南京路步行街、淮海中路商业街、新天地、豫园、田子坊等一大批内涵丰富、特色鲜明的“城市地标”蜚声中外，全方位展示了上海国际大都市中心城区的独特魅力。</p>
          </div>

          <div className="industry-introduction-city-card-container">
            <ImageGallery images={imageGallery} index={2} itemImgClassName={"industry-introduction-city-card-item-img"} />
          </div>
        </div>

        <div className="industry-introduction-bottom-container">
          <div className="industry-introduction-bottom-item-title-container">
            <div className="industry-introduction-bottom-item-title-line" />
            <div className="industry-introduction-bottom-item-title">交通便捷</div>
            <div className="industry-introduction-bottom-item-title-line" />
          </div>
          <div className="industry-introduction-transportation-container">
            {
              transportationList.map(item => {
                return <div className="industry-introduction-transportation-item" key={item.id}>
                  <div className="industry-introduction-transportation-img">
                    <Image scale={1} src={item.imgUrl} />
                  </div>
                  <span className="industry-introduction-transportation-des">{item.des}</span>
                  <span className="industry-introduction-transportation-name">{item.name}</span>
                </div>
              })
            }
          </div>

          <div className="industry-introduction-bottom-item-title-container">
            <div className="industry-introduction-bottom-item-title-line" />
            <div className="industry-introduction-bottom-item-title">经济雄厚</div>
            <div className="industry-introduction-bottom-item-title-line" />
          </div>
          <div className="industry-introduction-economy-container">
            {
              economyList.map(item => {
                return <div className="industry-introduction-economy-item" key={item.id}>
                  <div className="industry-introduction-economy-img">
                    <Image scale={180 / 200} src={item.imgUrl} />
                  </div>
                  <span className="industry-introduction-economy-des">{item.des}</span>
                </div>
              })
            }
          </div>
          <div className="industry-introduction-economy-container1">
            {
              economyList1.map(item => {
                return <div className="industry-introduction-economy-item1" style={{ maxWidth: item.id === 4 ? '110px' : '' }} key={item.id}>
                  <div className="industry-introduction-economy-img1">
                    <Image scale={180 / 200} src={item.imgUrl} />
                  </div>
                  {item.id === 5 ?
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                      {
                        item.des.split('、').map((desItem, index) => {
                          return <div style={{ display: 'flex', alignItems: 'center' }} key={index}>
                            <span className="industry-introduction-economy-des1">· </span>
                            <span className="industry-introduction-economy-des1" >{'' + desItem}</span>
                          </div>;
                        })
                      }
                    </div>
                    :
                    <span className="industry-introduction-economy-des1">{item.des}</span>
                  }

                </div>
              })
            }
          </div>

          <div className="industry-introduction-bottom-item-title-container">
            <div className="industry-introduction-bottom-item-title-line" />
            <div className="industry-introduction-bottom-item-title">发展规划</div>
            <div className="industry-introduction-bottom-item-title-line" />
          </div>
          <Image className="industry-introduction-industry-map-img" src={industryMapImg} />

          {/* <div className="industry-introduction-industry-intro-container">
            {industryIntroList.map(item => {
              return <div className="industry-introduction-industry-intro-item-container" key={item.id}>
                <div className="industry-introduction-industry-intro-item-img">
                  <Image scale={67 / 67} src={item.imgUrl} />
                </div>
                <div className="industry-introduction-industry-intro-item-right">
                  <span className="industry-introduction-industry-intro-item-right-text" style={{ color: item.color }}>
                    {item.name}
                    <span className="industry-introduction-industry-intro-item-right-text1">
                      {item.des}
                    </span>
                  </span>
                </div>
              </div>;
            })}
          </div> */}

          {/* <div className="industry-introduction-bottom-item-title-container">
            <div className="industry-introduction-bottom-item-title-line" />
            <div className="industry-introduction-bottom-item-title">金融服务业介绍</div>
            <div className="industry-introduction-bottom-item-title-line" />
          </div>
          <div className="industry-introduction-finance-container">
            <div className="industry-introduction-finance">金融服务业作为黄浦区大力发展的核心优势产业，2020年继续站稳千亿规模，完成总税收177.9亿元，成为区域经济增长的首要动力支撑。</div>
            <div className="industry-introduction-finance">“十三五”期末，黄浦区内汇聚了6家国家级金融要素市场，共有持牌金融机构660余家，汇集了包括银行、证券、保险、信托、基金、期货在内的各类持牌金融机构，全球中央对手方协会CCP12、复旦泛海国际金融学院、上海金融与发展实验室等重大功能性项目和专业智库先后入驻，资产管理、财富管理、金融科技等细分领域稳健发展，已基本形成了多类型、全方位、立体式的机构集聚和金融发展格局。</div>
            <div className="industry-introduction-finance">立足上海国际金融中心核心功能区定位，围绕“资产管理”和“金融科技”两大增长极，黄浦区将加快建设成世界最具影响力的国际大都市中心城区和卓越的全球城市核心区。</div>
          </div> */}

          <div className="industry-introduction-bottom-item-title-container">
            <div className="industry-introduction-bottom-item-title-line" />
            <div className="industry-introduction-bottom-item-title">了解政策·咨询专家</div>
            <div className="industry-introduction-bottom-item-title-line" />
          </div>

          <div className="industry-introduction-policy-container">
            {industryList.map(item => {
              return <div className="industry-introduction-policy-item-container" key={item.id} style={{ backgroundColor: item.backgroundColor }}>
                <div className="industry-introduction-policy-item-content" onClick={() => this.onItemClick(item.id)}>
                  <FunIcon icon={item.icon} iconStyle={{ fontSize: '23px', color: '#fff' }} iconFontSize={23} />
                  <span className="industry-introduction-policy-item-text">{item.name}</span>
                </div>
              </div>
            })}
          </div>

          <div className="industry-introduction-bottom-item-title-container">
            <div className="industry-introduction-bottom-item-title-line" />
            <div className="industry-introduction-bottom-item-title">招商引资  安商稳商  企业服务</div>
            <div className="industry-introduction-bottom-item-title-line" />
          </div>


          <div className="industry-introduction-company-container">
            <div className="industry-introduction-company-img">
              <Image scale={394 / 285} src={comoanyImg} />
            </div>
            <div className="industry-introduction-company-left-img">
              <Image scale={60 / 133} src={comoanyLeftImg} />
            </div>
            <div className="industry-introduction-company-right-img">
              <Image scale={60 / 133} src={comoanyRightImg} />
            </div>

            <div className="industry-introduction-company-bottom-container">
              <Image scale={648 / 633} src={industryBottomImg} />

              <a className="industry-introduction-company-bottom-contact" href='tel:021-63511018'>
                <div />
              </a>
            </div>

            {/* <div className="industry-introduction-company-content-container">
              <div className="industry-introduction-company-content-title-container">
                <span className="industry-introduction-company-content-title">上海春申江创盈企业服务有限公司</span>
                <span className="industry-introduction-company-content-title-des">黄浦区国有招商引资服务平台</span>
              </div>
              <span className="industry-introduction-company-intro">上海春申江创盈企业服务有限公司是黄浦区国有招商引资服务平台，成立于2021年1月，主要业务是开展招商引资和安商留商工作，服务于在黄浦区和有意向到黄浦区投资发展的各类企业，为企业提供财税、产业、人才等各类政策咨询和相关企业服务，助力企业发展。</span>
              <div className="industry-introduction-company-contact">
                <div className="industry-introduction-company-item">
                  <FunIcon icon='icon-location' />
                  <span className="industry-introduction-company-item-address">上海市黄浦区浙江中路400号春申江大厦18楼</span>
                </div>
                <div className="industry-introduction-company-item">
                  <FunIcon icon='icon-phone' iconStyle={{ fontSize: '13px' }} />
                  <span className="industry-introduction-company-item-phone">021-63511018</span>
                  <a className="industry-introduction-company-contact-now" href='tel:021-63511018'>
                    <div>立即联系</div>
                  </a>
                </div>
              </div>
            </div> */}

          </div>
        </div>
      </div >
    );
  }
}