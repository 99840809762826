import { Component } from "react";
import Utils from "../../common/utils";
import FunCommonInput, { EnDataType } from "../../components/common-input";
import FunButton, { EnButtonSize, EnButtonType } from "../../components/fun-button";
import FunLayout, { EnLayoutType } from "../../components/fun-layout";
import Header from "../../components/header";
import Image from "../../components/image";
import userImg from "../../public/images/icon-user-avatar-default.png";
import model from './model.js';
import './index.scss';

export default class EditProfile extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    const companyId = query.get('companyId') || '';
    const companyName = query.get('companyName') || '';
    this.state = {
      avatarUrl: '',
      name: '',
      companyName,
    };
    this.form = {
      avatar: '',
      name: '',
      companyId,
    };
  }

  componentDidMount() {
    model.getCompanyUserInfo().then(res => {
      const { name, avatar_url } = res;
      this.form.name = name;
      this.setState({ avatarUrl: avatar_url, name });
    });
  }

  onCompanyClick() {
    this.props.history.push(`/choose-company?isEditProfile=${true}`);
  }

  onChangeField(field, value) {
    if (field === 'name') {
      this.form.name = value;
    }
  }

  onEditProfile() {
    const { avatarUrl } = this.state;
    this.form.avatar = avatarUrl;
    model.updateProfile(this.form).then(() => {
      this.props.history.replace('./mine');
    })
  }

  render() {
    const { avatarUrl, name, companyName } = this.state;
    return (
      <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
        <Header hidden={false} hasBack={true} className={'top'} history={this.props.history}>
          编辑资料
        </Header>

        <div className="edit-profile-container">
          <div className="edit-profile-avatar-container">
            <div className="edit-profile-avatar">
              <Image scale={154 / 154} src={Utils.getUrl(avatarUrl) ? Utils.getUrl(avatarUrl) : userImg} />
            </div>
          </div>

          <FunCommonInput
            className={"edit-profile-item-container"}
            maxLength={20}
            dataType={EnDataType.none}
            placeholder={'请输入真实姓名'}
            icon={'icon-user'}
            defaultValue={name}
            onChange={value => this.onChangeField('name', value)}
          />
          <FunCommonInput
            className={"edit-profile-item-container"}
            maxLength={20}
            dataType={EnDataType.none}
            placeholder={'所属公司'}
            icon={'icon-arrow-down-filling'}
            disabled
            defaultValue={companyName}
            onClick={() => this.onCompanyClick()}
          />

          <FunButton
            className="edit-profile-btn"
            type={EnButtonType.primary}
            size={EnButtonSize.big}
            onClick={this.onEditProfile.bind(this)}
          >
            完成
          </FunButton>

        </div>
      </FunLayout>
    );
  }
}
