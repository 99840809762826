import { useEffect, useState } from "react";
import Image from "@/components/image";
import vipLabelImg from "@/public/images/icon-vip-label.png";
import vipFontImg from "@/public/images/icon-cy-vip-font.png";
import FunIcon from "../fun-icon";
import CommonButton from "../common-button";
import { dateFormat } from "@/common/dateUtils";
import Utils from '@/common/utils';

import './index.scss';

export function VipInfo(props) {
  const [companyLabels, setCompanyLabels] = useState([]);
  const {
    className,
    history,
    disabled,
    showLabel,
    companyUserInfo: {
      companyName,
      member_status,
      member_points,
      member_end_time,
      company_labels = [],
      company: {
        is_hp,
      } = {},
    },
  } = props;

  useEffect(() => {
    company_labels && setCompanyLabels(company_labels.length > 3 ? company_labels.splice(0, 3) : company_labels);
  }, [company_labels]);

  const pointChange = () => {
    if (Utils.hasLogin()) {
      !disabled && history.push('/point-change');
    } else {
      Utils.goLogin();
    }
  };

  const vipRight = () => {
    if (Utils.hasLogin()) {
      history.push('/vip-right')
    } else {
      Utils.goLogin();
    }
  };

  return (

    <div className={['vip-info-container'].concat(className || '').join(' ')} onClick={() => pointChange()}>
      <div className="vip-container">
        <Image className="labelImg" src={vipLabelImg} />
        {(member_status === 0 || member_status === 1 || !Utils.hasLogin()) && <div className="no-vip-info-container">
          <Image className="fontImg" src={vipFontImg} />
          <span className="vip-right-number">{`尊享${(is_hp === 2 || !Utils.hasLogin()) ? 8 : 7}项专属特权`}</span>
        </div>}
        {member_status === 2 && <div className="vip-info-content-container">
          <span className="vip-company-name">{companyName}</span>
          <div className="vip-content">
            <FunIcon icon={'icon-point'} iconFontSize={23} />
            <span className="vip-point">{member_points}积分</span>
            <span className="vip-expire-date">{dateFormat(member_end_time * 1000)}到期</span>
          </div>
          <div className="vip-btn-container">
            {showLabel ?
              companyLabels.map((item, index) =>
                <div key={index}>
                  <CommonButton
                    className='label-change-btn'
                    style={{ marginRight: '6px' }}
                    textClassName='btn'
                    iconColor='#9F5300'
                    text={item}
                    type='normal' />
                </div>)
              :
              <>
                <CommonButton
                  className='point-change-btn'
                  style={{ marginRight: '21px' }}
                  textClassName='btn'
                  iconColor='#9F5300'
                  icon='icon-point-change'
                  text='线上积分兑换'
                  type='normal'
                  onClick={() => pointChange()}
                />
                <CommonButton
                  className='vip-right-btn'
                  textClassName='btn'
                  iconColor='#9F5300'
                  icon='icon-vip-label'
                  text='会员权益'
                  onClick={() => vipRight()}
                />
              </>}
          </div>
        </div>}
        {!disabled && <FunIcon icon={'icon-arrow-right'} iconStyle={{ color: '#9F5300' }} />}
      </div>
    </div >
  );
}