import React, { Component } from 'react'
import FunButton, { EnButtonSize, EnButtonType } from '@/components/fun-button';
import FunLayout, { EnLayoutType } from '@/components/fun-layout';
import Header from '@/components/header';
import Image from '@/components/image';
import customerServiceImg from "@/public/images/icon-customer-service.png";
import avatarImg from "@/public/images/icon-customer-service-avatar.png";
import FunToast from '@/components/fun-toast';
import { eshiminSetTitleStyle } from '@/common/eshiminUtils';
import Utils from '@/common/utils';
import model from './model';

import './index.scss'

const consultationTypes = [
  { consultationId: 1, consultationType: '场地咨询' },
  { consultationId: 2, consultationType: '企业开办咨询', },
  { consultationId: 3, consultationType: '变更办理咨询', },
  { consultationId: 4, consultationType: '政策咨询', },
  { consultationId: 5, consultationType: '其他', },
];
export default class Comminicate extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    const type = query.get('type') || '';
    const expertId = query.get('expertId') || '';

    const policyId = query.get('policyId') || '';

    this.state = {
      customerService: {},
      consultationType: '选择咨询类型',
    };
    this.textRef = React.createRef()
    this.typePickerRef = React.createRef()

    this.form = {
      consultationContent: '',
      consultationType: type,
      employeeId: '',
      expertId,
      policyId,
    };
  }

  componentDidMount() {
    if (Utils.hasLogin()) {
      model.getStaff().then(res => {
        const { id } = res;
        this.form.employeeId = id;
        this.setState({ customerService: res || {} });
      });
    }
    eshiminSetTitleStyle('服务咨询');
  }

  onShowConsultationTypeSelect() {
    this.typePickerRef.current.show();
  }

  onSelectConsultationType(_, value) {
    const { consultationId, consultationType } = value;
    this.form.consultationType = consultationId;
    this.setState({ consultationType })
  }

  onContentChange(e) {
    const target = e ? e.target : this.profileRef.current;
    const value = target.value || '';
    this.form.consultationContent = value;
  }


  onCheckParam() {
    const {
      // consultationType,
      consultationContent,
    } = this.form;
    // if (!consultationType) {
    //   return '请选择咨询类型';
    // }
    if (!consultationContent) {
      return '请输入咨询内容';
    }
  }

  onSubmitConsultation() {
    if (!Utils.hasLogin()) {
      Utils.goLogin('comminicate');
      return;
    }
    const error = this.onCheckParam();
    if (error) {
      return FunToast.show(error, 2);
    }
    if (this.form.policyId) {
      const { policyId, consultationType, consultationContent } = this.form;
      const params = { policyId, consultationType, consultationContent };
      model.consultPolicy(params).then(() => {
        FunToast.show('小创会在1-2个工作日内联系您');
        this.props.history.goBack();
      });
      return;
    }
    if (this.form.expertId) {
      model.reserveExpert(this.form).then(() => {
        FunToast.show('小创会在1-2个工作日内联系您');
        this.props.history.goBack();
      });
    } else {
      model.submitConsultation(this.form).then(() => {
        FunToast.show('小创会在1-2个工作日内联系您');
        this.props.history.goBack();
      });
    }
  }

  render() {
    const { customerService: { avatar = avatarImg, name = '小创', tags = [] }, consultationType } = this.state;
    return (
      <div className="comminicate-container">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <Header className={'top'} hidden={false} hasBack isNoBorder history={this.props.history}>
            服务咨询
          </Header>

          <div className="comminicate-container">
            <div className="comminicate-customer-service-container">
              <div className="comminicate-customer-service-content-container">
                <div className="comminicate-customer-service-img">
                  <Image scale={1} src={avatar} />
                </div>
                <div className="comminicate-customer-service-name-container">
                  <span className="comminicate-customer-service-name">小创</span>
                  <span className="comminicate-customer-service-slogan">企业服务专员“小创”为您服务</span>
                  <div className="comminicate-customer-service-tag-container">
                    {tags && tags.map(item => {
                      return <span className="comminicate-customer-service-tag">{item}</span>
                    })}
                  </div>
                </div>
                <div className="comminicate-customer-service-label">
                  <Image scale={63 / 63} src={customerServiceImg} />
                </div>
              </div>
            </div>

            {/* <div className="comminicate-select-type-container" onClick={() => { this.onShowConsultationTypeSelect() }}>
              <span className="comminicate-select-type">{consultationType}</span>
              <FunIcon icon={"icon-arrow-down-filling"} />
            </div> */}

            <div className={"comminicate-content"}>
              <textarea
                ref={this.textRef}
                style={{ resize: 'none' }}
                onChange={this.onContentChange.bind(this)}
                placeholder={'简述咨询内容：'}
                maxLength={500}
                cols="30"
                rows="6"
              />
            </div>

            <div className="comminicate-submit">
              <FunButton
                type={EnButtonType.primary}
                size={EnButtonSize.big}
                onClick={() => this.onSubmitConsultation()}
              >提交咨询
              </FunButton>
            </div>
          </div>
        </FunLayout >

        {/* <FunPicker
          onChange={this.onSelectConsultationType.bind(this)}
          ref={this.typePickerRef}
          title="选择咨询类型"
          displayFiled="consultationType"
          valueFiled="consultationId"
          defaultValue={""}
          data={[{
            options: consultationTypes
          }]}
        /> */}
      </div >
    )
  }
}
