import { request } from '../../http';
class Model {

  getOperationUserInfo() {
    return request('/h5/personal', {
      method: 'GET',
    });
  }

  operationLogout() {
    return request('/h5/logout', {
      method: 'POST',
    });
  }
}

export default new Model();
