import React, { Component } from 'react'
import FunCommonInput, { EnDataType } from '../../components/common-input';
import FunButton, { EnButtonSize, EnButtonType } from '../../components/fun-button';
import FunLayout, { EnLayoutType } from '../../components/fun-layout';
import FunToast from '../../components/fun-toast'
import Header from '../../components/header';
import Utils from '../../common/utils';
import model from './model'

import './index.scss'
export default class OperationLogin extends Component {
  form = {
    mobile: '',
    pwd: '',
  };
  constructor(props) {
    super(props);
    this.state = {
      pwdType: 'password',
    };
  }
  componentDidMount() {

  }

  onClickRight() {
    this.setState({ pwdType: this.state.pwdType === 'password' ? '' : 'password' });
  }

  onForgetPwd() {
    this.props.history.push(`/forget-pwd?type=${2}`);
  }

  onChangeField(field, value) {
    if (field === 'mobile') {
      this.form.mobile = value;
    } else if (field === 'pwd') {
      this.form.pwd = value;
    }
  }

  onCheckParam() {
    const { mobile, pwd } = this.form;
    if (!mobile) {
      return '请输入手机号码';
    }
    const error = Utils.checkMobile(mobile);
    if (error) {
      return error;
    }
    if (!pwd) {
      return '请输入密码';
    }
    // const pwdError = Utils.checkPassword(pwd);
    // if (pwdError) {
    //   return pwdError;
    // }
  }

  onLogin() {
    console.log(this.form);
    const error = this.onCheckParam();
    if (error) {
      return FunToast.show(error, 2);
    }
    const { mobile, pwd } = this.form;
    model.login({ wholeResponse: true, mobile, pwd }).then(res => {
      const { data: { code } } = res;
      if (code === 200) {
        const { redirectUrl } = this.state;
        this.props.history.replace(redirectUrl ? redirectUrl : '/customer-list');
      }
    });
  }

  onRegister() {
    this.props.history.push('/register');
  }

  render() {
    const { pwdType } = this.state;
    return (
      <div className="operation-login-container">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <Header hidden={false} className={'top'} history={this.props.history}>
            用户登录
          </Header>

          <div className="operation-login-content-container">
            <FunCommonInput
              maxLength={11}
              type={'number'}
              dataType={EnDataType.none}
              placeholder={'请输入手机号'}
              icon={'icon-mobile'}
              onChange={value => this.onChangeField('mobile', value)}
            />
            <FunCommonInput
              className={"operation-login-item-container"}
              maxLength={8}
              type={pwdType}
              dataType={EnDataType.none}
              placeholder={'请输入密码'}
              icon={pwdType === 'password' ? 'icon-hide' : 'icon-show'}
              onClickRight={this.onClickRight.bind(this)}
              onChange={value => this.onChangeField('pwd', value)}
            />
            <span className="operation-login-forget-pwd" onClick={this.onForgetPwd.bind(this)}>忘记密码?</span>

            <FunButton
              className="operation-login-btn"
              type={EnButtonType.primary}
              size={EnButtonSize.big}
              onClick={this.onLogin.bind(this)}
            >
              登录
            </FunButton>
          </div>

        </FunLayout>
      </div>
    )
  }
}
