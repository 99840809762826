export const OperationTabs = [
  {
    text: '客户',
    path: `/customer-list`,
    icon: 'home'
  },
  {
    text: '任务',
    path: `/task-list`,
    icon: 'task'
  },
  {
    text: '我的',
    path: `/operation-mine`,
    icon: 'mine'
  },
];

export const Tabs = [
  {
    text: '首页',
    path: `/home`,
    icon: 'home'
  },
  {
    text: '政策',
    path: `/policy-list`,
    icon: 'policy'
  },
  {
    text: '我的',
    path: `/mine`,
    icon: 'mine'
  },
]

export const IndustryEnum = {
  1: '科技',
  2: '金融',
  4: '文创',
  7: '专业服务',
  3: '商贸',
  5: '大健康',
  6: '其他',
}

export const IndustryList = [
  { id: 1, name: '科技' },
  { id: 2, name: '金融' },
  { id: 4, name: '文创' },
  { id: 7, name: '专业服务' },
  { id: 3, name: '商贸' },
  { id: 5, name: '大健康' },
  { id: 6, name: '其他' },
];

export const LocationList = [
  { id: 1, name: '散位' },
  { id: 2, name: '独立办公（有窗）' },
  { id: 3, name: '独立办公（无窗）' },
];

export const LocationList1 = [
  { id: 1, name: '外滩/十六铺' },
  { id: 2, name: '南京东路' },
  { id: 3, name: '人民广场' },
  { id: 4, name: '豫园' },
  { id: 5, name: '淮海中路' },
  { id: 6, name: '新天地' },
  { id: 7, name: '打浦桥' },
  { id: 8, name: '五里桥' },
  { id: 9, name: '老西门' },
  { id: 10, name: '董家渡' },
  { id: 11, name: '黄浦世博滨江' },
  { id: 12, name: '复兴公园' },
  { id: 13, name: '鲁班路' },
  { id: 14, name: '南浦大桥' },
  { id: 15, name: '蓬莱公园' },
  { id: 16, name: '西藏南路' },
  { id: 17, name: '斜桥' },
];
