import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Query from '../../common/query';

import './index.scss';

/**
 * 选项卡组件
 */
export default class Tabs extends Component {
  constructor(props) {
    super(props);

    const activeIndex = (props.hash && parseInt(Query.getHashQuery(props.hash))) || props.activeIndex;
    this.state = {
      activeIndex
    };

    this.rootRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.indicator) {
      this.layout();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.items.length !== this.props.items.length || nextProps.activeIndex !== this.props.activeIndex) {
      this.setState({
        activeIndex: nextProps.activeIndex
      }, () => {
        if (this.props.indicator) {
          this.layout();
        }
      });
    }
  }

  onChange(index, tab) {
    const { activeIndex } = this.state;
    const { onBeforeChange, onChange, hash, indicator } = this.props;

    // 点击当前选中的 tab 项无效
    if (index === activeIndex) {
      return;
    }

    // 提供时机给切换前校验，如果校验返回 false 时，不执行切换
    if (onBeforeChange && onBeforeChange(index, tab) === false) {
      return;
    }

    onChange && onChange(index, tab);
    if (hash) {
      const query = {};
      query[hash] = index;
      Query.appendHashQuery(query);
    }

    this.setState({ activeIndex: index }, () => {
      if (indicator) {
        this.layout();
      }
    });
  }

  layout() {
    const rootEl = this.rootRef.current;
    const activeLineEl = rootEl && rootEl.querySelector('.tab-line');
    const activeEl = rootEl && rootEl.querySelector('.active');
    const activeTextEl = activeEl && activeEl.children[0];

    // 设置选中项底部横线动画
    if (activeTextEl && activeLineEl) {
      activeLineEl.style.width = activeTextEl.offsetWidth + 'px';
      activeLineEl.style.left = activeTextEl.offsetLeft + 'px';
    }

    if (activeEl) {
      const previousSibling = activeEl.previousElementSibling;
      const nextSibling = activeEl.nextElementSibling;
      const headerRect = rootEl.getBoundingClientRect();

      // 如果当前选中项的左侧 tab 没有完全出现在可视区域内，将其划到可视区域（tab 比较多的情况）
      if (previousSibling) {
        const previousRect = previousSibling.getBoundingClientRect();
        if (previousRect.left < headerRect.left) {
          rootEl.scrollLeft -= headerRect.left - previousRect.left;
          return;
        }
      }

      // 如果当前选中项的右侧 tab 没有完全出现在可视区域内，将其划到可视区域（tab 比较多的情况）
      if (nextSibling) {
        const nextRect = nextSibling.getBoundingClientRect();
        if (nextRect.right > headerRect.right) {
          rootEl.scrollLeft += nextRect.right - headerRect.width;
        }
      }
    }
  }

  render() {
    const { activeIndex } = this.state;
    const { className, items, indicator, type, ...restProps } = this.props;

    delete restProps.hash;
    delete restProps.activeIndex;
    delete restProps.onBeforeChange;
    delete restProps.onChange;

    return (
      <div className={classNames('fun-tabs', className)} {...restProps}>
        <div className="tab-content" ref={this.rootRef}>
          <ul className="tab-list">
            {
              items.map((item, index) => {
                const name = typeof item === 'object' ? item.name : item;
                const badge = typeof item === 'object' ? item.badge : null;

                const isActive = index === activeIndex;
                return (
                  <li
                    className={classNames((type === 'full' ? 'tab-item-full' : 'tab-item'), { 'active': isActive })}
                    key={index}
                    onClick={() => { this.onChange(index, item) }}>
                    <span className={type === 'full' ? "tab-name-full" : "tab-name"}>
                      {name}
                      {badge && <span className={type === 'full' ? "tab-badge-full" : "tab-badge"}>{badge}</span>}
                    </span>
                  </li>
                );
              })
            }
          </ul>
          {indicator && <div className="tab-line"></div>}
        </div>
      </div>
    );
  }
}

Tabs.propTypes = {
  className: PropTypes.string,    // 自定义class
  items: PropTypes.array,         // 切换项
  activeIndex: PropTypes.number,  // 当前项的索引
  indicator: PropTypes.bool,      // 是否显示指示符
  hash: PropTypes.string,         // 标记，有些场景下需要点击 Tab 页面的内容跳转后，后退回来需要记录选中的 Tab 项，没有便捷的方式实现，
  //    只能通过切换后记录到 hash 中实现（此方式需要不能基于使用 hash 实现的前端路由）
  onBeforeChange: PropTypes.func,    // 切换前事件（如可在这里做判断，当条件满足时才会做切换），返回 true 时进行切换，false 时停止切换
  onChange: PropTypes.func,        // 切换事件
  type: PropTypes.string, //显示样式 full--选项底部白线均等分
};

Tabs.defaultProps = {
  activeIndex: 0,
  indicator: true,
  items: []
};