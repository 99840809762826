import FunLayout, { EnLayoutType } from "@/components/fun-layout";
import Header from "@/components/header";
import model from "./model";

import './index.scss';
import { useEffect, useState } from "react";
export default function VipRight(props) {
  const [isHp, setIsHp] = useState(false);

  useEffect(() => {
    getCompanyUserInfo();
  }, []);

  const getCompanyUserInfo = async () => {
    const res = await model.getCompanyUserInfo();
    setIsHp(res.company && (res.company.is_hp === 2));
  };

  const HpTable = () => {
    return <table >
      <tr>
        <td className="td1" style={{ width: '20%' }}>服务分类</td>
        <td className="td1" style={{ width: '40%' }}>服务项目</td>
        <td className="td1" style={{ width: '20%' }}>黄浦企业积分分值</td>
        <td className="td1" style={{ width: '20%' }}>特殊说明</td>
      </tr>
      <tr>
        <td className="td2">工商注册</td>
        <td>工商登记注册和税务登记</td>
        <td>0</td>
        <td></td>
      </tr>
      <tr>
        <td className="td2" rowSpan={10}>证照变更</td>
        <td>公司名称</td>
        <td>800</td>
        <td></td>
      </tr>
      <tr>
        <td>经营范围</td>
        <td>800</td>
        <td></td>
      </tr>
      <tr>
        <td>营业期限延长</td>
        <td>800</td>
        <td></td>
      </tr>
      <tr>
        <td>注册地址</td>
        <td>800</td>
        <td></td>
      </tr>
      <tr>
        <td>法定代表人、董监事、经理、财务人员等</td>
        <td>800</td>
        <td></td>
      </tr>
      <tr>
        <td>注册资本增资（不含股东变更）</td>
        <td>800</td>
        <td></td>
      </tr>
      <tr>
        <td>注册资本减资（不含股东变更）</td>
        <td>1500</td>
        <td></td>
      </tr>
      <tr>
        <td>股东（不含自然人股东变更）</td>
        <td>1000</td>
        <td></td>
      </tr>
      <tr>
        <td>股东（含自然人股东变更）</td>
        <td>1500</td>
        <td></td>
      </tr>
      <tr>
        <td>股东（含内外资转变）</td>
        <td>1500</td>
        <td></td>
      </tr>
      <tr>
        <td className="td2" rowSpan={3}>初创企业扶持政策（适用运营场地在黄浦企业）</td>
        <td>社会保险费补贴咨询</td>
        <td>0</td>
        <td></td>
      </tr>
      <tr>
        <td>首次创业一次性补贴咨询</td>
        <td>0</td>
        <td></td>
      </tr>
      <tr>
        <td>认定创业孵化示范基地</td>
        <td>5000</td>
        <td>签署服务协议约定评估服务费</td>
      </tr>
      <tr>
        <td className="td2" rowSpan={5}>科技创新政策</td>
        <td>科技型中小企业评价</td>
        <td>2000</td>
        <td>签署协议约定服务费</td>
      </tr>
      <tr>
        <td>软件企业认定（不含规费）</td>
        <td>15000</td>
        <td>签署协议约定服务费</td>
      </tr>
      <tr>
        <td>软件产品登记（不含规费）（个）</td>
        <td>1000</td>
        <td>签署协议约定服务费</td>
      </tr>
      <tr>
        <td>高新技术企业</td>
        <td>10000</td>
        <td>签署协议约定服务费</td>
      </tr>
      <tr>
        <td>专精特新企业</td>
        <td>10000</td>
        <td>签署协议约定服务费</td>
      </tr>
      <tr>
        <td className="td2" rowSpan={2}>选址服务</td>
        <td>孵化载体、联办、办公楼</td>
        <td>0</td>
        <td></td>
      </tr>
      <tr>
        <td>商铺</td>
        <td>-</td>
        <td>签署服务协议约定佣金（租赁合同约定的1个月租金）</td>
      </tr>
      <tr>
        <td className="td2" rowSpan={2}>银行业务服务（限合作银行）</td>
        <td>办理银行开户等</td>
        <td>0</td>
        <td></td>
      </tr>
      <tr>
        <td>协助申请各类银行助企优惠贷款及财政贴息贷款</td>
        <td>0</td>
        <td></td>
      </tr>
      <tr>
        <td className="td2" rowSpan={12}>知识产权服务</td>
        <td>商标注册（一标一类）</td>
        <td>1200</td>
        <td>签署协议约定服务费</td>
      </tr>
      <tr>
        <td>发明专利-专利检索报告</td>
        <td>1000</td>
        <td>签署协议约定服务费</td>
      </tr>
      <tr>
        <td>发明专利-专利申请（含专利申请初步检索以及实质审查）</td>
        <td>8500</td>
        <td>签署协议约定服务费</td>
      </tr>
      <tr>
        <td>发明专利-驳回复审</td>
        <td>4000</td>
        <td>签署协议约定服务费</td>
      </tr>
      <tr>
        <td>实用新型专利申请</td>
        <td>3500</td>
        <td>签署协议约定服务费</td>
      </tr>
      <tr>
        <td>实用新型-实质审查</td>
        <td>800</td>
        <td>签署协议约定服务费</td>
      </tr>
      <tr>
        <td>实用新型-驳回复审</td>
        <td>3000</td>
        <td>签署协议约定服务费</td>
      </tr>
      <tr>
        <td>外观设计-专利申请（普通）</td>
        <td>1200</td>
        <td>签署协议约定服务费</td>
      </tr>
      <tr>
        <td>外观设计-驳回复审</td>
        <td>1500</td>
        <td>签署协议约定服务费</td>
      </tr>
      <tr>
        <td>著录项目变更（申请人变更/发明人变更/专利申请权转让/专利权转让）</td>
        <td>500</td>
        <td>签署协议约定服务费</td>
      </tr>
      <tr>
        <td>软件著作权登记（3-4 个月取证）</td>
        <td>720</td>
        <td>签署协议约定服务费</td>
      </tr>
      <tr>
        <td>软件著作权登记（35 个工作日取证）</td>
        <td>1600</td>
        <td>签署协议约定服务费</td>
      </tr>
      <tr>
        <td className="td2">其他服务</td>
        <td>代理记账、社保公积金管理、高新企业认定专项审计等</td>
        <td>-</td>
        <td>签署协议约定服务费</td>
      </tr>
    </table>
  };
  return (
    <div className="vip-right-container">
      <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
        <Header hidden={false} hasBack={true} className={'top'} history={props.history}>
          会员权益
        </Header>
        <span className="page-title">创盈企服平台会员积分制方案</span>
        <p className="subTitle">一、目的</p>
        <p className="subContent">
          1、扩大招商信息来源，提升促转化力度；<br />
          2、提高客户黏度、忠诚度； <br />
          3、稳定客户，提高满意度；<br />
          4、积累会员，沉淀客户信息，以便提供精准服务。<br />
        </p>
        <p className="subTitle">二、适用范围</p>
        <p className="subContent">创盈企服平台会员及相关服务使用者。</p>

        <p className="subTitle">三、会员注册</p>
        <p className="subContent">
          1、创盈企服平台会员，实行线上注册，一企一号。<br />
          2、注册必须实名注册（包括姓名、手机号）。<br />
          3、注册流程<br />
          1）随申办-黄浦旗舰店-创盈企服，按照提示填报注册信息，经后台审核完成注册。<br />
          2）春申江创盈公众号-企服平台，按照提示填报注册信息，经后台审核完成注册。<br />
        </p>

        <p className="subTitle">四、积分分配及兑换规则</p>
        <p className="subContent">
          1、积分有效期2年，2年后自动清零。<br />
          2、积分不可兑换现金，不可找零。<br />
          3、积分赋予对象为平台招商引进企业、平台合作载体及渠道内部分企业。<br />
          4、新注册的黄浦企业会员即享1000积分。<br />
          5、会员使用会员权益消耗对应服务积分。<br />
          6、会员如签署服务协议的，按服务协议约定金额所对应的积分值，后台抵扣使用。<br />
          7、会员如积分余额不足，按服务对应的积分值或积分差额线下付费购买积分使用。<br />
          8、会员权益内认定创业孵化示范基地、科技型中小企业评价、软件企业认定（不含规费）、软件产品登记（不含规费）、高新技术企业、专精特新、商场选址、商标注册服务扣除对应积分外，需线下签署服务协议。<br />
        </p>

        <p className="subTitle">五、会员权益（不定期更新）</p>
        {isHp ? HpTable() :
          <table >
            <tr>
              <td className="td1" style={{ width: '20%' }}>服务分类</td>
              <td className="td1" style={{ width: '30%' }}>服务项目</td>
              <td className="td1" style={{ width: '15%' }}>黄浦企业积分分值</td>
              <td className="td1" style={{ width: '15%' }}>非黄浦企业积分分值</td>
              <td className="td1" style={{ width: '20%' }}>特殊说明</td>
            </tr>
            <tr>
              <td className="td2">工商注册</td>
              <td>工商登记注册和税务登记</td>
              <td>0</td>
              <td>1200</td>
              <td></td>
            </tr>
            <tr>
              <td className="td2" rowSpan={10}>证照变更</td>
              <td>公司名称</td>
              <td>800</td>
              <td>1000</td>
              <td></td>
            </tr>
            <tr>
              <td>经营范围</td>
              <td>800</td>
              <td>1000</td>
              <td></td>
            </tr>
            <tr>
              <td>营业期限延长</td>
              <td>800</td>
              <td>1000</td>
              <td></td>
            </tr>
            <tr>
              <td>注册地址</td>
              <td>800</td>
              <td>1000</td>
              <td></td>
            </tr>
            <tr>
              <td>法定代表人、董监事、经理、财务人员等</td>
              <td>800</td>
              <td>1000</td>
              <td></td>
            </tr>
            <tr>
              <td>注册资本增资（不含股东变更）</td>
              <td>800</td>
              <td>1000</td>
              <td></td>
            </tr>
            <tr>
              <td>注册资本减资（不含股东变更）</td>
              <td>1500</td>
              <td>1800</td>
              <td></td>
            </tr>
            <tr>
              <td>股东（不含自然人股东变更）</td>
              <td>1000</td>
              <td>1200</td>
              <td></td>
            </tr>
            <tr>
              <td>股东（含自然人股东变更）</td>
              <td>1500</td>
              <td>1800</td>
              <td></td>
            </tr>
            <tr>
              <td>股东（含内外资转变）</td>
              <td>1500</td>
              <td>1800</td>
              <td></td>
            </tr>
            <tr>
              <td className="td2" rowSpan={3}>初创企业扶持政策（适用运营场地在黄浦企业）</td>
              <td>社会保险费补贴咨询</td>
              <td>0</td>
              <td>-</td>
              <td></td>
            </tr>
            <tr>
              <td>首次创业一次性补贴咨询</td>
              <td>0</td>
              <td>-</td>
              <td></td>
            </tr>
            <tr>
              <td>认定创业孵化示范基地</td>
              <td>5000</td>
              <td>-</td>
              <td>签署服务协议约定评估服务费</td>
            </tr>
            <tr>
              <td className="td2" rowSpan={5}>科技创新政策</td>
              <td>科技型中小企业评价</td>
              <td>2000</td>
              <td>3000</td>
              <td>签署协议约定服务费</td>
            </tr>
            <tr>
              <td>软件企业认定（不含规费）</td>
              <td>15000</td>
              <td>22500</td>
              <td>签署协议约定服务费</td>
            </tr>
            <tr>
              <td>软件产品登记（不含规费）（个）</td>
              <td>1000</td>
              <td>1500</td>
              <td>签署协议约定服务费</td>
            </tr>
            <tr>
              <td>高新技术企业</td>
              <td>10000</td>
              <td>15000</td>
              <td>签署协议约定服务费</td>
            </tr>
            <tr>
              <td>专精特新企业</td>
              <td>10000</td>
              <td>15000</td>
              <td>签署协议约定服务费</td>
            </tr>
            <tr>
              <td className="td2" rowSpan={2}>选址服务</td>
              <td>孵化载体、联办、办公楼</td>
              <td>0</td>
              <td>0</td>
              <td></td>
            </tr>
            <tr>
              <td>商铺</td>
              <td>-</td>
              <td>-</td>
              <td>签署服务协议约定佣金（租赁合同约定的1个月租金）</td>
            </tr>
            <tr>
              <td className="td2" rowSpan={2}>银行业务服务（限合作银行）</td>
              <td>办理银行开户等</td>
              <td>0</td>
              <td>0</td>
              <td></td>
            </tr>
            <tr>
              <td>协助申请各类银行助企优惠贷款及财政贴息贷款</td>
              <td>0</td>
              <td>0</td>
              <td></td>
            </tr>
            <tr>
              <td className="td2" rowSpan={12}>知识产权服务</td>
              <td>商标注册（一标一类）</td>
              <td>1200</td>
              <td>1300</td>
              <td>签署协议约定服务费</td>
            </tr>
            <tr>
              <td>发明专利-专利检索报告</td>
              <td>1000</td>
              <td>1500</td>
              <td>签署协议约定服务费</td>
            </tr>
            <tr>
              <td>发明专利-专利申请（含专利申请初步检索以及实质审查）</td>
              <td>8500</td>
              <td>9500</td>
              <td>签署协议约定服务费</td>
            </tr>
            <tr>
              <td>发明专利-驳回复审</td>
              <td>4000</td>
              <td>4500</td>
              <td>签署协议约定服务费</td>
            </tr>
            <tr>
              <td>实用新型专利申请</td>
              <td>3500</td>
              <td>4000</td>
              <td>签署协议约定服务费</td>
            </tr>
            <tr>
              <td>实用新型-实质审查</td>
              <td>800</td>
              <td>1000</td>
              <td>签署协议约定服务费</td>
            </tr>
            <tr>
              <td>实用新型-驳回复审</td>
              <td>3000</td>
              <td>3500</td>
              <td>签署协议约定服务费</td>
            </tr>
            <tr>
              <td>外观设计-专利申请（普通）</td>
              <td>1200</td>
              <td>1500</td>
              <td>签署协议约定服务费</td>
            </tr>
            <tr>
              <td>外观设计-驳回复审</td>
              <td>1500</td>
              <td>1500</td>
              <td>签署协议约定服务费</td>
            </tr>
            <tr>
              <td>著录项目变更（申请人变更/发明人变更/专利申请权转让/专利权转让）</td>
              <td>500</td>
              <td>500</td>
              <td>签署协议约定服务费</td>
            </tr>
            <tr>
              <td>软件著作权登记（3-4 个月取证）</td>
              <td>720</td>
              <td>800</td>
              <td>签署协议约定服务费</td>
            </tr>
            <tr>
              <td>软件著作权登记（35 个工作日取证）</td>
              <td>1600</td>
              <td>1800</td>
              <td>签署协议约定服务费</td>
            </tr>
            <tr>
              <td className="td2">其他服务</td>
              <td>代理记账、社保公积金管理、高新企业认定专项审计等</td>
              <td>-</td>
              <td>-</td>
              <td>签署协议约定服务费</td>
            </tr>
          </table>
        }

        {/* <span className="subContent">备注：浮动价格会员权益服务包括协助认定创业孵化示范基地&评估咨询、软件产品登记（不含规费）、商场选址服务、高新企业认定专项审计、商标注册。</span> */}
        <p className="subTitle">六、平台积分分配及兑换规则、积分有效期、会员权益的最终解释权归上海春申江创盈企业服务有限公司所有。</p>
      </FunLayout>
    </div >
  );
}