import { request } from '../../http';
class Model {
  getActivitySalon() {
    return request('/activity/list', {
      method: 'GET',
    });
  }

  getActivitySalonDetail(id) {
    return request(`/activity/detail/${id}`, {
      method: 'GET',
    });
  }

  joinActivity(data) {
    return request('/company/activity/appointment', {
      method: 'POST',
      data,
    });
  }

  getBookList() {
    return request(`/book/list`, {
      method: 'GET',
    });
  }
}

export default new Model();
