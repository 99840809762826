import { request } from '../../http';

class Model {

  getQuestionDetail(infoId) {
    return request(`/qa/detail/${infoId}`, {
      method: 'GET',
    });
  }
}

export default new Model();
