import { request } from '../../http';
class Model {

  getStaff() {
    return request('/company/staff', {
      method: 'POST',
    });
  }

  submitConsultation(data) {
    return request('/company/consultation/commit', {
      method: 'POST',
      data,
    });
  }

  reserveExpert(data) {
    return request('/company/expert/appointment', {
      method: 'POST',
      data,
    });
  }

  consultPolicy(data) {
    return request('/company/consultation/policy', {
      method: 'POST',
      data,
    });
  }
}

export default new Model();
