import { request } from '../../http';

class Model {

  // 校验验证码
  verifyOtp(data) {
    return request(`/companyAccount/check`, {
      method: 'POST',
      data,
    });
  }

  // 发送验证码
  sendOtp(data) {
    return request(`/index/sendCaptcha`, {
      method: 'POST',
      data,
    });
  }

  // 完成注册
  finishRegister(data) {
    return request(`/companyAccount/add_new`, {
      method: 'POST',
      data,
    });
  }

  companySearch(data) {
    return request(`/company/get_by_name`, {
      method: 'POST',
      data,
    });
  }
}

export default new Model();
