import { request } from '../../http';
class Model {
  getCompanyDetail(id) {
    return request(`/h5/company/detail/${id}`, {
      method: 'GET',
    });
  }
}

export default new Model();
