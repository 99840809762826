import React, { Component } from 'react'
import FunLayout, { EnLayoutType } from '@/components/fun-layout';
import Header from '@/components/header';
import FunToast from '@/components/fun-toast';
import { eshiminSetTitleStyle } from '@/common/eshiminUtils';
import model from './model';

import './index.scss'

export default class MyCollect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collectList: [],
    };
  }

  componentDidMount() {
    model.getMyPolicyCollect().then(res => {
      const { data = [] } = res;
      this.setState({ collectList: data });
      if (data <= 0) {
        FunToast.show('快去收藏吧～');
      }
    });
    eshiminSetTitleStyle('我的收藏');
  }

  onShowOrHideProfile(id) {

  }

  onGoToDetail(policy_id) {
    this.props.history.push(`/policy-original?id=${policy_id}`);
  }

  render() {
    const { collectList = [] } = this.state;
    return (
      <div className="my-collect-container">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <Header hidden={false} hasBack={true} className={'top'} history={this.props.history}>
            我的收藏
          </Header>

          {
            collectList && collectList.map(item => {
              const { id, title, policy_id } = item;
              return (
                <div className="my-collect-item-container" key={id} onClick={() => this.onGoToDetail(policy_id)}>
                  <span className="my-collect-policy-title" >{title}</span>
                </div>
              );
            })
          }
        </FunLayout>
      </div>
    )
  }
}
