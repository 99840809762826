import { useEffect, useState } from 'react';
import FunLayout, { EnLayoutType } from '@/components/fun-layout';
import FunButton, { EnButtonSize, EnButtonType } from "@/components/fun-button";
import Header from '@/components/header';
import Image from '@/components/image';
import FunIcon from '@/components/fun-icon';
import FunDialog from '@/components/fun-dialog';
import { VipInfo } from '@/components/vip-info';
import VipDialogCommon from '@/components/vip-dialog-common';
import FunCommonInput, { EnDataType } from '@/components/common-input';
import FunToast from '@/components/fun-toast';
import vipRightImg from "@/public/images/icon-vip-right.webp";
import vipRightHpImg from "@/public/images/icon-vip-right-hp.webp";
import bgImg1 from "@/public/images/icon-vip-right-bg1.png";
import bgImg2 from "@/public/images/icon-vip-right-bg2.png";
import businessRegistration from "@/public/images/icon-vip-business-registration.png";
import startupCompanyImg from "@/public/images/icon-vip-startup-company.png";
import technologyInnovationImg from "@/public/images/icon-vip-technology-innovation.png";
import bookkeepingImg from "@/public/images/icon-vip-bookkeeping.png";
import hrServicceImg from "@/public/images/icon-vip-hr-service.png";
import locationServicceImg from "@/public/images/icon-vip-location-service.png";
import bankBusinessImg from "@/public/images/icon-vip-bank-business.webp";
import auditImg from "@/public/images/icon-vip-audit.png";
import intellectualPropertyImg from "@/public/images/icon-vip-intellectual-property.png";
import joinVipImg from "@/public/images/icon-vip-join.png";
import applyVipImg from "@/public/images/icon-apply-vip-title.png";
import vipUseServiceImg from "@/public/images/icon-vip-use-service.png";
import useServiceImg from "@/public/images/icon-use-service.png";
import classNames from "classnames";

import model from './model';

import './index.scss';
import Utils from '@/common/utils';

const MemberRightList = [
  { "name": "工商注册-工商登记注册和税务登记", "vipPoint": 0, "point": 1200, "id": 1, remark: '', material: '1、公司名称、经营范围、注册资本、注册地址，纳税人类别\n2、股东、法人、董事、监事、财务等人员信息和证件资料\n3、租赁合同等住所使用证明材料' },
  { "name": "证照变更-公司名称", "vipPoint": 800, "point": 1000, "id": 2, remark: '', material: '1、营业执照\n2、登记（备案）申请书\n3、章程修正案\n4、股东决定或股东会决议\n5、一网通名称核准告知书' },
  { "name": "证照变更-经营范围", "vipPoint": 800, "point": 1000, "id": 3, remark: '', material: '1、营业执照\n2、登记（备案）申请书\n3、章程修正案\n4、股东决定或股东会决议' },
  { "name": "证照变更-营业期限延长", "vipPoint": 800, "point": 1000, "id": 4, remark: '', material: '1、营业执照\n2、登记（备案）申请书\n3、章程修正案\n4、股东决定或股东会决议\n5、租赁合同等住所使用证明材料' },
  { "name": "证照变更-注册地址", "vipPoint": 800, "point": 1000, "id": 5, remark: '', material: '1、营业执照\n2、登记（备案）申请书\n3、章程修正案\n4、股东决定或股东会决议\n5、租赁合同等住所使用证明材料' },
  { "name": "证照变更-法定代表人、董监事、经理、财务人员等", "vipPoint": 800, "point": 1000, "id": 6, remark: '', material: '1、营业执照\n2、登记（备案）申请书\n3、章程修正案\n4、股东决定或股东会决议' },
  { "name": "证照变更-注册资本增资（不含股东变更）", "vipPoint": 800, "point": 1000, "id": 7, remark: '', material: '1、营业执照\n2、登记（备案）申请书\n3、章程修正案\n4、股东决定或股东会决议' },
  { "name": "证照变更-注册资本减资（不含股东变更）", "vipPoint": 1500, "point": 1800, "id": 8, remark: '', material: '1、营业执照\n2、登记（备案）申请书\n3、章程修正案\n4、股东决定或股东会决议\n5、债务情况担保说明' },
  { "name": "证照变更-股东（不含自然人股东变更）", "vipPoint": 1000, "point": 1200, "id": 9, remark: '', material: '1、营业执照\n2、登记（备案）申请书\n3、章程修正案\n4、股东决定或股东会决议\n5、股权转让协议\n6、股东受让方和出让方的相关证件资料' },
  { "name": "证照变更-股东（含自然人股东变更）", "vipPoint": 1500, "point": 1800, "id": 10, remark: '', material: '1、营业执照\n2、登记（备案）申请书\n3、章程修正案\n4、股东决定或股东会决议\n5、股权转让协议\n6、股东受让方和出让方的相关证件资料\n7、自然人股东股权变更完税情况表' },
  { "name": "证照变更-股东（含内外资转变）", "vipPoint": 1500, "point": 1800, "id": 11, remark: '', material: '1、营业执照\n2、登记（备案）申请书\n3、章程修正案\n4、股东决定或股东会决议\n5、股权转让协议\n6、股东受让方和出让方的相关证件资料' },

  { "name": "初创企业扶持政策（适用运营场地在黄浦企业）-社会保险费补贴咨询", "vipPoint": 0, "point": 0, "id": 12, remark: '' },
  { "name": "初创企业扶持政策（适用运营场地在黄浦企业）-首次创业一次性补贴咨询", "vipPoint": 0, "point": 0, "id": 13, remark: '' },
  { "name": "初创企业扶持政策（适用运营场地在黄浦企业）-认定创业孵化示范基地", "vipPoint": 5000, "point": 0, "id": 14, remark: '签署服务协议约定评估服务费' },

  { "name": "科技创新政策-科技型中小企业评价", "vipPoint": 2000, "point": 3000, "id": 15, remark: '签署协议约定服务费', material: '1、企业为在中国境内（不包括港、澳、台地区）注册的居民企业\n2、企业职工总数不超过500人、年销售收入不超过2亿元、资产总额不超过2亿元\n3、科技人员占比不低于10%\n4、上一年度有研发费用\n5、企业拥有自主知识产权' },
  { "name": "科技创新政策-软件企业认定（不含规费）", "vipPoint": 15000, "point": 22500, "id": 16, remark: '签署协议约定服务费', material: '1、具有一种以上由本企业开发或由本企业拥有知识产权的软件产品\n2、软件产品开发销售（营业）收入占企业收入总额的比例一般不低于50%（嵌入式软件产品和信息系统集成产品开发销售（营业）收入占企业收入总额的比例不低于40%），其中软件产品自主开发销售（营业）收入占企业收入总额的比例一般不低于40%（嵌入式软件产品和信息系统集成产品开发销售（营业）收入占企业收入总额的比例不低于30%\n3、企业应建立健全质量管理体系' },
  { "name": "科技创新政策-软件产品登记（不含规费）（个）", "vipPoint": 1000, "point": 1500, "id": 17, remark: '签署协议约定服务费', material: '1、软件产品生产单位所生产的软件产品应是本单位享有著作权\n2、软件评估名称应有三个部份组成：企业品牌 + 软件产品的用途和功能 + 版本号\n3、第三方软件测试机构（CNAS）所出具的软件产品检测证明材料' },
  { "name": "科技创新政策-高新技术企业", "vipPoint": 10000, "point": 15000, "id": 18, remark: '签署协议约定服务费', material: '1、注册成立满一年以上\n2、企业拥有知识产权\n3、企业从事研发和相关技术创新活动的科技人员占企业当年职工总数的比例不低于10%\n4、前三年研发费用总额至少占前三年销售总收入的5%\n5、近一年高新技术产品（服务）收入占企业同期总收入的比例不低于60%' },
  { "name": "科技创新政策-专精特新企业", "vipPoint": 10000, "point": 15000, "id": 19, remark: '签署协议约定服务费', material: '1、从事特定细分市场时间达到 2 年以上\n2、上年度研发费用总额不低于 100 万元，且占营业收入总额比重不低于 3%\n3、上年度营业收入总额在 1000 万元以上，或上年度营业收入总额在 1000万元以下，但近 2 年新增股权融资总额（合格机构投资者的实缴额）达到2000 万元以上' },

  { "name": "选址服务-孵化载体、联办、办公楼", "vipPoint": 0, "point": 0, "id": 20, remark: '' },
  { "name": "选址服务-商铺", "vipPoint": '-', "point": '-', "id": 21, remark: '签署服务协议约定佣金（租赁合同约定的1个月租金）' },

  { "name": "银行业务服务（限合作银行）-办理银行开户等", "vipPoint": 0, "point": 0, "id": 22, remark: '' },
  { "name": "银行业务服务（限合作银行）-协助申请各类银行助企优惠贷款及财政贴息贷款", "vipPoint": 0, "point": 0, "id": 23, remark: '' },

  { "name": "知识产权服务-商标注册（一标一类）", "vipPoint": 1200, "point": 1300, "id": 24, remark: '签署协议约定服务费' },
  { "name": "知识产权服务-发明专利-专利检索报告", "vipPoint": 1000, "point": 1500, "id": 25, remark: '签署协议约定服务费' },
  { "name": "知识产权服务-发明专利-专利申请（含专利申请初步检索以及实质审查）", "vipPoint": 8500, "point": 9500, "id": 26, remark: '签署协议约定服务费' },
  { "name": "知识产权服务-发明专利-驳回复审", "vipPoint": 4000, "point": 4500, "id": 27, remark: '签署协议约定服务费' },
  { "name": "知识产权服务-实用新型专利申请", "vipPoint": 3500, "point": 4000, "id": 28, remark: '签署协议约定服务费' },
  { "name": "知识产权服务-实用新型-实质审查", "vipPoint": 800, "point": 1000, "id": 29, remark: '签署协议约定服务费' },
  { "name": "知识产权服务-实用新型-驳回复审", "vipPoint": 3000, "point": 3500, "id": 30, remark: '签署协议约定服务费' },
  { "name": "知识产权服务-外观设计-专利申请（普通）", "vipPoint": 1200, "point": 1500, "id": 31, remark: '签署协议约定服务费' },
  { "name": "知识产权服务-外观设计-驳回复审", "vipPoint": 1500, "point": 1500, "id": 32, remark: '签署协议约定服务费' },
  { "name": "知识产权服务-著录项目变更（申请人变更/发明人变更/专利申请权转让/专利权转让）", "vipPoint": 500, "point": 500, "id": 33, remark: '签署协议约定服务费' },
  { "name": "知识产权服务-软件著作权登记（3-4 个月取证）", "vipPoint": 720, "point": 800, "id": 34, remark: '签署协议约定服务费' },
  { "name": "知识产权服务-软件著作权登记（35 个工作日取证）", "vipPoint": 1600, "point": 1800, "id": 35, remark: '签署协议约定服务费' },

  { "name": "其他服务-代理记账、社保公积金管理、高新企业认定专项审计等", "vipPoint": '-', "point": '-', "id": 36, remark: '签署协议约定服务费' },
]

const Content = (contentProps) => {
  const { style, img, title, content, className, contentItemClickFunc } = contentProps;
  return (<div className='content' style={style} onClick={contentItemClickFunc}>
    <Image className='service-img' src={img} />

    <div className='service-content-container'>
      <span className={classNames('service-title', className)}>{title}</span>
      <span className='service-content'>{content}</span>
    </div>

    <FunIcon icon={'icon-arrow-right'} iconStyle={{ color: '#9F5300' }} />
  </div>);
};

const SubContent = (contentProps) => {
  const { img, title, content, subContentItemClickFunc } = contentProps;
  return (<div className='sub-content' onClick={subContentItemClickFunc}>
    <Image className='sub-service-img' src={img} />

    <div className='sub-service-content-container'>
      <span className='sub-service-title'>{title}</span>
      <span className='sub-service-content'>{content}</span>
    </div>

    <FunIcon icon={'icon-arrow-right'} iconStyle={{ color: '#9F5300' }} />

  </div>);
};

export default function PointChange(props) {
  const [applyShow, setApplyShow] = useState(false);
  const [applyServiceTitle, setApplyServiceTitle] = useState(false);
  const [applySuccessShow, setApplySuccessShow] = useState(false);
  const [projectListShow, setProjectShow] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [itemProjectList, setItemProjectList] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});
  const [step, setStep] = useState(1);
  const [companyUserInfo, setCompanyUserInfo] = useState({});
  const [contactName, setContactName] = useState();
  const [contactMobile, setContactMobile] = useState();
  const [isHp, setIsHp] = useState(false);

  useEffect(() => {
    getCompanyUserInfo();
    getMemberRight();
  }, []);

  const getCompanyUserInfo = async () => {
    const res = await model.getCompanyUserInfo();
    setCompanyUserInfo(res);
    setContactName(res.name || '');
    setContactMobile(res.mobile || '');
    setIsHp(res.company && (res.company.is_hp === 2))
  };

  const getMemberRight = async () => {
    // const res = await model.getMemberRight();
    // res && res.map(item => {
    //   return item.value = item.point > 0 ? `${item.point}元/次` : '免费'
    // });
    // setProjectList(res)

    setProjectList(MemberRightList);
  };

  const applyMember = async () => {
    const { companyName, mobile, name } = companyUserInfo;
    const res = await model.applyMember({ wholeResponse: true, companyName, mobile, name });
    const { data: { code } } = res || {};
    if (code === 200) {
      setApplySuccessShow(true);
    }
  };

  const contentItemClick = (itemTitle) => {
    const { member_status } = companyUserInfo;
    if (member_status === 1) {
      FunToast.show('您已提交申请，请稍后～');
      return;
    }
    document.body.style.overflow = 'hidden';
    setApplyShow(true);
    setApplyServiceTitle(itemTitle);
    setStep(1);
    let list = [];
    list = projectList.filter(item => (item.name.indexOf(itemTitle) >= 0));
    if (itemTitle === '工商注册、证照变更') {
      list = projectList.filter(item => (item.name.indexOf('工商注册') >= 0 || item.name.indexOf('证照变更') >= 0));
    }
    setItemProjectList(list);
  };

  const generateApplyDialogContent = (companyName, mobile, name) => {
    if (applySuccessShow) {
      return <VipDialogCommon titleImg={applyVipImg}>
        <FunIcon icon={'icon-checked-solid'} iconStyle={{ fontSize: '50px', color: '#FFD582', marginTop: '85px' }} iconFontSize={50} />
        <span className='apply-success'>申请成功</span>
        <span className='apply-success-des'>客服稍后来电确认，请保持电话畅通</span>

        <div className='apply-btn'>
          <FunButton
            type={EnButtonType.primary}
            size={EnButtonSize.big}
            onClick={() => {
              setApplyShow(false);
              document.body.style.overflow = 'unset';
            }}
          >
            好的
          </FunButton>
        </div>
      </VipDialogCommon>;
    } else {
      return <VipDialogCommon titleImg={applyVipImg}>
        <FunCommonInput
          className='apply-vip-item'
          dataType={EnDataType.none}
          icon={'icon-home-no-fill'}
          defaultValue={companyName}
          disabled
          onChange={value => this.onChangeField('companyName', value)}
        />
        <FunCommonInput
          className='apply-vip-item'
          maxLength={11}
          type={'number'}
          dataType={EnDataType.none}
          icon={'icon-mobile'}
          defaultValue={mobile}
          disabled
          onChange={value => this.onChangeField('mobile', value)}
        />
        <FunCommonInput
          className='apply-vip-item'
          maxLength={100}
          dataType={EnDataType.none}
          icon={'icon-user'}
          defaultValue={name}
          disabled
          onChange={value => this.onChangeField('name', value)}
        />
        <div className='apply-vip-btn'>
          <FunButton
            type={EnButtonType.primary}
            size={EnButtonSize.big}
            onClick={() => applyMember()}
          >
            确定
          </FunButton>
        </div>
      </VipDialogCommon>
    }
  };

  const onProjectItemClick = (id) => {
    const _list = [...projectList];
    _list.map(item => {
      return item.isSelected = item.id === id;
    });
    setProjectList(_list);
  };

  const generateDialogContent = () => {
    const {
      member_status,
      member_points,
      member_end_time,
      companyName,
      mobile,
      name,
    } = companyUserInfo;
    if (member_status === 0) {
      return generateApplyDialogContent(companyName, mobile, name);
    } else if (member_status === 2) {
      return genetateVipUseServiceContent();
    }
  };

  const stepOne = () => {
    const selectList = itemProjectList.filter(item => item.isSelected);
    if (selectList && selectList.length > 0) {
      setStep(2);
    } else {
      FunToast.show(`请选择“${applyServiceTitle}”项目`)
    }
  };

  const stepTwo = () => {
    let error = '';
    if (!contactName) {
      error = '请输入联系人';
    }
    if (!contactMobile) {
      return '请输入联系人号码';
    }
    error = Utils.checkMobile(contactMobile);
    if (error) {
      return FunToast.show(error);
    }

    const list = itemProjectList.filter(item => item.isSelected);
    if (list && list.length > 0) {
      setSelectedProject(list[0]);
    }
    setStep(3);
  };

  const stepThree = async () => {
    const { id } = selectedProject;
    const res = await model.useService({
      wholeResponse: true,
      memberRightType: id,
      contact: `${contactName} ${contactMobile}`,
    });
    const { data: { code } } = res || {};
    if (code === 200) {
      setStep(4)
    }
  };

  const onGoToMemberDetail = () => {
    props.history.push('/vip-right');
  };

  const genetateVipUseServiceContent = () => {
    return <VipDialogCommon titleImg={vipUseServiceImg}>
      {step === 1 &&
        <div className='use-service-step-one'>
          <div className='user-service-img-container'>
            <Image className='user-service-img' src={useServiceImg} />
          </div>
          <span className='select-item'>{`请选择“${applyServiceTitle}”项目`}</span>
          <div className='project-select-container'>
            <FunCommonInput
              dataType={EnDataType.none}
              placeholder={'项目选择'}
              icon={'icon-arrow-down-filling'}
              noBorder
              disabled
              onClick={() => setProjectShow(true)}
            // onChange={this.onSelectIndustry}
            />
            {projectListShow &&
              <div className='project-list-container'>
                {itemProjectList.map(item => {
                  return <div
                    key={item.id}
                    className='project-list-item-container'
                    style={{ backgroundColor: item.isSelected ? '#CBB486' : '#fff' }}
                    onClick={() => onProjectItemClick(item.id)}
                  >
                    <span style={{ flex: 1 }} className={item.isSelected ? 'selected-text' : 'normal-text'}>{item.name}</span>
                    <span style={{ flex: 1 }} className={item.isSelected ? 'selected-text' : 'normal-text'}>{isHp ? item.vipPoint : item.point}</span>
                    <span style={{ flex: 1 }} className={item.isSelected ? 'selected-text' : 'normal-text'}>{item.remark}</span>
                  </div>
                })}
              </div>}

          </div>
          <FunButton
            style={{ marginTop: '15px' }}
            type={EnButtonType.primary}
            size={EnButtonSize.big}
            onClick={() => stepOne()}
          >
            确定
          </FunButton>
          <FunButton
            style={{ marginTop: '15px' }}
            type={EnButtonType.white}
            size={EnButtonSize.big}
            onClick={() => {
              setProjectShow(false);
              setStep(1);
              document.body.style.overflow = 'unset';
              setApplyShow(false);
            }}
          >
            取消
          </FunButton>
        </div>}
      {step === 2 &&
        <div className='use-service-step-two'>
          <span className='contact-people '>办理服务的联系电话：</span>
          <FunCommonInput
            className='contact-people-name'
            maxLength={100}
            dataType={EnDataType.none}
            placeholder={''}
            icon={'icon-user'}
            onChange={(value) => setContactName(value)}
            defaultValue={(companyUserInfo && companyUserInfo.name) || ''}
          />
          <FunCommonInput
            style={{ marginTop: '15px' }}
            maxLength={11}
            type={'number'}
            dataType={EnDataType.none}
            placeholder={''}
            icon={'icon-mobile'}
            onChange={(value) => { setContactMobile(value) }}
            defaultValue={(companyUserInfo && companyUserInfo.mobile) || ''}
          />
          <FunButton
            style={{ marginTop: '15px' }}
            type={EnButtonType.primary}
            size={EnButtonSize.big}
            onClick={() => stepTwo()}
          >
            确定
          </FunButton>
        </div>}
      {step === 3 &&
        <div className='use-service-step-three'>
          <FunIcon icon={'icon-financial'} iconStyle={{ fontSize: '50px', color: '#F7C868', marginTop: '30px' }} iconFontSize={50} />
          <span className='consume-detail'>
            确认消费
            <span style={{ color: '#BF7117' }}>{`${isHp ? selectedProject.vipPoint : selectedProject.point}积分`}</span>
            <br />
            使用
            <span style={{ color: '#000000', fontWeight: 'bold' }}>“{applyServiceTitle}”</span>服务</span>
          <div className='need-material'>
            <span className='sub-title'>办理服务电话：</span>
            <span className='sub-content'>{contactMobile}</span>
            <span className='sub-title'>办理所需材料：</span>
            <span className='sub-content'>{selectedProject.material || '-'}</span>
          </div>
          <FunButton
            style={{ marginTop: '15px' }}
            type={EnButtonType.primary}
            size={EnButtonSize.big}
            onClick={() => stepThree()}
          >
            确定
          </FunButton>
          <FunButton
            style={{ marginTop: '15px' }}
            type={EnButtonType.white}
            size={EnButtonSize.big}
            onClick={() => {
              setApplyShow(false);
              setProjectShow(false);
              document.body.style.overflow = 'unset';
              setStep(1);
            }}
          >
            取消
          </FunButton>
        </div >
      }
      {step === 4 &&
        <div className='use-service-step-four '>
          <FunIcon icon={'icon-checked-solid'} iconStyle={{ fontSize: '50px', color: '#FFD582', marginTop: '85px' }} iconFontSize={50} />
          <span className='apply-success'>使用成功</span>
          <span className='apply-success-des'>客服稍后来电确认，请保持电话畅通</span>

          <div className='apply-btn'>
            <FunButton
              type={EnButtonType.primary}
              size={EnButtonSize.big}
              onClick={() => {
                setApplyShow(false);
                document.body.style.overflow = 'unset';
              }}
            >
              好的
            </FunButton>
          </div>
        </div>
      }

    </VipDialogCommon >
  };

  return (
    <div className='point-change-container' style={{
      marginTop: (companyUserInfo &&
        companyUserInfo.member_status === 2 ? 0 : '-50px')
    }}>
      <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
        <Header hidden={false} hasBack={true} className={'top'} history={props.history} isNoBorder>
          线上积分兑换
        </Header>
        <div className='content-container'>
          <div className={isHp ? 'bg1-1' : 'bg1'}>
            <Image src={bgImg1} />
          </div>
          <div className={isHp ? 'bg2-2' : 'bg2'}>
            <Image src={bgImg2} />
          </div>
          <div className='service-content-out-container' style={{
            // top: (companyUserInfo &&
            //   companyUserInfo.member_status === 2) ? '50px' : '150px'
            top: '50px'
          }}>
            {/* {
              companyUserInfo &&
              companyUserInfo.member_status === 2 &&
              <VipInfo disabled showLabel companyUserInfo={companyUserInfo} />
            } */}
            <VipInfo disabled showLabel companyUserInfo={companyUserInfo} />

            <Image className="vipRightImg" src={isHp ? vipRightHpImg : vipRightImg} />
            <Content
              style={{ marginTop: '50px' }}
              title='工商注册'
              img={businessRegistration}
              content='工商登记注册和税务登记'
              contentItemClickFunc={() => contentItemClick('工商注册')}
            />
            <Content
              title='证照变更'
              img={businessRegistration}
              content='公司名称，经营范围，营业期限延长，注册地址，法定代表人、董监事、经理、财务人员等，注册资本增资（不含股东变更），注册资本减资（不含股东变更），股东（不含自然人股东变更），股东（含自然人股东变更），股东（含内外资转变）'
              contentItemClickFunc={() => contentItemClick('证照变更')}
            />
            {isHp && <Content
              title='初创企业扶持政策'
              img={startupCompanyImg}
              content='社会保险费补贴咨询、首次创业一次性补贴咨询、认定创业孵化示范基地'
              contentItemClickFunc={() => contentItemClick('初创企业扶持政策')}
            />}
            <Content
              title='科技创新政策'
              img={technologyInnovationImg}
              content='科技型中小企业评价、软件企业认定（不含规费）、软件产品登记（不含规费）（个）、高新技术企业、专精特新企业'
              contentItemClickFunc={() => contentItemClick('科技创新政策')}
            />
            <div className='sub-content-container'>
              <SubContent
                title='选址服务'
                img={locationServicceImg}
                content='孵化载体、商铺、办公楼等场地租赁、选址'
                subContentItemClickFunc={() => contentItemClick('选址服务')}
              />
              <SubContent
                title='银行业务服务'
                img={bankBusinessImg}
                content='办理银行开户等、协助申请各类银行助企优惠贷款及财政贴息贷款'
                subContentItemClickFunc={() => contentItemClick('银行业务服务')}
              />
            </div>
            <Content
              title='知识产权服务'
              img={intellectualPropertyImg}
              content='商标注册（一标一类）、发明专利-专利检索报告、发明专利-专利申请（含专利申请初步检索以及实质审查）、发明专利-驳回复审、实用新型专利申请、实用新型-实质审查、实用新型-驳回复审、外观设计-专利申请（普通）、外观设计-驳回复审、著录项目变更（申请人变更/发明人变更/专利申
              请权转让/专利权转让）、软件著作权登记（3-4 个月取证）、软件著作权登记（35 个工作日取证）'
              contentItemClickFunc={() => contentItemClick('知识产权服务')}
            />
            <Content
              title='其他服务'
              img={hrServicceImg}
              content='代理记账、社保公积金管理、高新企业认定专项审计等'
              contentItemClickFunc={() => contentItemClick('其他服务')}
            />

            <div className='vip-bottom'>
              {companyUserInfo &&
                companyUserInfo.member_status === 0 &&
                <Image className='join' src={joinVipImg} onClick={() => {
                  const { member_status } = companyUserInfo;
                  if (member_status === 1) {
                    FunToast.show('您已提交申请，请稍后～');
                    return;
                  }
                  setApplyShow(true);
                }} />}

              <span className='member-detail' onClick={() => onGoToMemberDetail()}>点击查看会员服务详情</span>
              <span className='service-company-address'>中心地址：上海市黄浦区浙江中路400号春申江大厦18楼<br />联系电话：021-63511018</span>
            </div>
          </div>
        </div>
      </FunLayout >
      <FunDialog
        isShow={applyShow}
        customShow
        noTitle
        noCancel
        noConfirm
        toggle={false}
        backgroundColor={'transparent'}
        children={
          generateDialogContent()
        }
      />

    </div >
  );
}