import { Component } from "react";
import Utils from "../../common/utils";
import LocalStore from "../../store/localStore";
import model from "./model";

export default class LoginCallback extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    const redirectUrl = query.get('redirectUrl') || '';
    this.state = {
      redirectUrl,
    };
  }

  componentDidMount() {
    this.getEshiminAccessToken();
  }

  componentWillUnmount() {
    LocalStore.set('shouldIntercept', false);
  }

  /**
   * 随申办sso回调链接https://www.csj-cy.com/#/login-callback?code=ujaQVs
   * 通过随申办授权后获取的code获取AccessToken,
   * 返回成功后说明登录成功，接着自己的逻辑，从哪来回哪去
   */
  getEshiminAccessToken() {
    const query = new URLSearchParams(this.props.location.search);
    const code = query.get('code') || '';

    if (code) {
      model.loginAfterEshiminSSO(code).then((res) => {
        console.log(res);
        const { is_reg, username } = res;
        const { redirectUrl } = this.state;
        // 登陆逻辑 有2种情况：
        // 1.用户是应用用户，直接登陆  接口返回字段 is_reg = 1
        // 2.用户非应用用户，接口返回字段is_reg=0
        // 接口还会返回一个字段username,用于 情况2 走应用内 手机号注册登录时传递,通过username绑定帐号
        if (is_reg === 0) {
          this.props.history.push(`/login?username=${username}&redirectUrl=${redirectUrl}`);
        } else if (is_reg === 1) {
          Utils.setLogin(true);
          this.props.history.go(-2);
        }
      });
    }
  }

  render() {
    return (
      <div />
    );
  }
}