import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Swiper from 'swiper';

import "../../../node_modules/swiper/dist/css/swiper.min.css";
import "./style.scss";

/**
 * 图片浏览组件，Image gallery
 */
export default class ImageGallery extends Component {

  static propTypes = {
    //样式class
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    //图片列表
    images: PropTypes.array,
    //初始index
    index: PropTypes.number,
    effect: PropTypes.string,
    itemImgClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  }

  static defaultProps = {
    images: [],
    index: 0,
  }

  constructor(props) {
    super(props);
    this.state = {
      current: this.props.index,
      showBars: true,
      swiping: false,
      effect: this.props.effect || 'coverflow',
      images: this.props.images || [],
    };
  }

  componentDidMount() {
    this.initSwiper();
  }

  initSwiper() {
    const { effect, current, images = [] } = this.state;
    if (effect === 'coverflow') {
      this.swiper = new Swiper('.swiper-container', {
        pagination: '.swiper-pagination',
        effect: 'coverflow',
        slidesPerView: 'auto',
        grabCursor: true,
        centeredSlides: true,
        initialSlide: this.props.index,
        coverflowEffect: {
          rotate: 0,
          stretch: 100,
          depth: 100,
          modifier: 1,
          slideShadows: true
        },
        loop: true,
      });
    } else {
      this.swiper = new Swiper('.swiper-container', {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        loop: images.length > 1,
        initialSlide: current,
        passiveListeners: false,
      });
    }

    this.swiper.on('click', e => {
      const { onSwiperItemClick } = this.props;
      if (onSwiperItemClick) {
        onSwiperItemClick(e.target);
      }
    });
    this.swiper.on('slideChangeTransitionStart', () => this.onChangeStart());
    this.swiper.on('slideChangeTransitionEnd', () => this.onChange());
  }

  componentWillUnmount() {
    this.swiper.off('click');
    this.swiper.off('slideChangeTransitionStart');
    this.swiper.off('slideChangeTransitionEnd');
  }

  onChangeStart() {
    this.setState({
      swiping: true
    })
  }

  onChange() {
    const current = this.swiper.realIndex;
    this.setState({
      current: current,
    });
  }

  render() {
    const { images, itemClassName } = this.props;
    return (
      <div className={classNames("image-gallery", this.props.className)}>
        <div className="swiper-container">
          <div className="swiper-wrapper">
            {
              images.map((item, index) => {
                return (
                  <div className={classNames("swiper-slide", this.props.itemImgClassName)} key={index}>
                    <div className="swiper-zoom-container">
                      <img className={itemClassName} id={item.id} src={item.src} alt='' />
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </div>
    );
  }
}