import { request } from '../../http';
class Model {
  getCompanyDetail(id) {
    return request(`/h5/company/detail/${id}`, {
      method: 'GET',
    });
  }

  saveCompanyDetail(data) {
    const { id } = data;
    return request(`/h5/company/update/${id}`, {
      method: 'POST',
      data,
    });
  }
}

export default new Model();
