import { Component } from "react";
import FunLayout, { EnLayoutType } from "@/components/fun-layout";
import Header from "@/components/header";
import Image from "@/components/image";
import ReactPlayer from 'react-player';
import { PhotoProvider, PhotoConsumer } from 'react-photo-view';
import 'react-photo-view/dist/index.css';

import Utils from "@/common/utils";
import { eshiminSetTitleStyle } from "@/common/eshiminUtils";
import { setWechatSdk, wechatShare } from "@/common/wechatUtils";
import Platform from "@/common/platform";
import model from './model';

import './index.scss';
export default class Detail extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    const infoId = query.get('infoId') || '';
    const isThirdPage = (infoId.startsWith('https') || infoId.startsWith('http') || infoId.startsWith('www'));
    this.state = {
      detail: {
        title: '',
        create_at: '',
        video_url: '',
        img_url_1: '',
        content: '',
      },
      infoId,
      isThirdPage,
    };
  }

  componentDidMount() {
    const { infoId, isThirdPage } = this.state;
    !isThirdPage && model.getInfoDetail(infoId).then(res => {
      const {
        title = '',
        create_at = '',
        video_url = '',
        img_url_1 = '',
        content = '',
        cover_url = '',
      } = res;
      this.setState({
        detail: {
          title,
          create_at,
          video_url: Utils.getUrl(video_url),
          img_url_1: Utils.getUrl(img_url_1),
          content
        }
      });
      eshiminSetTitleStyle('热点关注');
      wechatShare(title, content, cover_url);
      // this.doWechatSdkInit();
    });
  }

  doWechatSdkInit() {
    if (Platform.isWechat) {
      setWechatSdk(window.location.href.split('#')[0]).then(() => {
        // window.wx.ready(function () {
        //   window.wx.checkJsApi({
        //     jsApiList: ['updateAppMessageShareData'],
        //     success: function (res) {
        //       console.log('checkJsApi:' + JSON.stringify(res));
        //     }
        //   });

        //   window.wx.error(function (res) {
        //     console.log('error:' + JSON.stringify(res));
        //   })
        // });

        const { detail: { title, img_url_1, content } } = this.state;

        // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
        window.wx.updateTimelineShareData({
          title, // 分享标题
          link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: img_url_1, // 分享图标
          success: function () {

          }
        });

        // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
        window.wx.updateAppMessageShareData({
          title, // 分享标题
          desc: content, // 分享描述
          link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: img_url_1, // 分享图标
          success: function () {
            // 设置成功
          }
        })
      });
    }
  }

  onShowImage(url) {
    this.props.history.push(`/see-origin-img?title=热点关注&url=${url}`);
  }

  render() {
    const { detail: { title, create_at, video_url, img_url_1, content }, infoId, isThirdPage } = this.state;
    console.log(video_url)
    const photoImages = [img_url_1];
    return (
      <div className="detail-container">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <Header hidden={false} hasBack={true} className={'top'} history={this.props.history}>
            热点关注
          </Header>
          {!isThirdPage && <div className="detail-content-container">
            <span className="detail-title">{title}</span>
            <span className="detail-create-time">{create_at}</span>
            {video_url && <div className="detail-video">
              <ReactPlayer controls url={video_url} width="100%" height="100%;" />
            </div>}
            {img_url_1 && <div className="detail-image" >

              <PhotoProvider>
                {photoImages.map((item, index) => (
                  <PhotoConsumer key={index} src={item}>
                    <Image scale={646 / 363} src={img_url_1} />
                  </PhotoConsumer>
                ))}
              </PhotoProvider>
            </div>}

            <div
              className="detail-content"
              dangerouslySetInnerHTML={{
                __html:
                  content.replace(/(\r\n|\n|\r)/gm, '<br />'),
              }}
            />
          </div>}

          {isThirdPage &&
            <iframe src={infoId} className="detail-third-page-container"></iframe>}
        </FunLayout>
      </div>
    );
  }
}