import { request } from '../../http';
class Model {

  getCustomerList(companyName) {
    return request(`/h5/company/list?companyName=${companyName}`, {
      method: 'GET',
    });
  }
}

export default new Model();
