import { request } from '../../http';
class Model {

  getTaskList(status) {
    return request(`/h5/task/list?status=${status}`, {
      method: 'GET',
    });
  }

  finishTask(data) {
    return request('/h5/task/finish', {
      method: 'POST',
      data,
    });
  }
}

export default new Model();
