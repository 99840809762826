import { request } from '../../http';
class Model {
  getBuildingList(searchContent, buildingType, page, pageSize) {
    return request(`/building/list?buildingType=${buildingType}&page=${page}&pageSize=${pageSize}`, {
      method: 'GET',
    });
  }
}

export default new Model();
