import { Component } from 'react';
import FunIcon from '@/components/fun-icon';
import FunLayout, { EnLayoutType } from '@/components/fun-layout';
import Header from '@/components/header';
import Image from '@/components/image';
import FunToast from '@/components/fun-toast';
import Utils from '@/common/utils';
import avatarImg from "@/public/images/icon-company-avatar.png";
import model from './model';

import './index.scss'

export default class MyNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationList: [],
    };
  }

  componentDidMount() {
    model.getNotificationList().then(res => {
      const { data = [] } = res;
      this.setState({ notificationList: data });
      if (data.length <= 0) {
        FunToast.show('暂无提醒哦～')
      }
    });
  }

  onShowOrHideProfile(id) {
    const { notificationList = [] } = this.state;
    notificationList.forEach(item => {
      if (item.id === id) {
        item.isShowProfile = !item.isShowProfile;
      }
    });
    this.setState({ notificationList });
  }

  render() {
    const { notificationList } = this.state;
    return (
      <div className="my-notification-container">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <Header hidden={false} hasBack={true} className={'top'} history={this.props.history}>
            我的提醒
          </Header>

          {
            notificationList && notificationList.map(item => {
              const { id, logo_url, isShowProfile, companyThrough = {}, activity = {} } = item;
              const { name = '' } = companyThrough;
              const { title = '', content = '', start_time, end_time } = activity;
              return (
                <div className="my-notification-item-container">
                  <div className="my-notification-company-item" onClick={() => this.onShowOrHideProfile(id)}>
                    <div className="my-notification-company-avatar">
                      <Image scale={1} src={Utils.getUrl(logo_url)} />
                    </div>
                    <span className="my-notification-company-name">{name}</span>
                    <FunIcon icon={isShowProfile ? 'icon-arrow-up' : 'icon-arrow-down'} />
                  </div>
                  {isShowProfile && <div className="my-notification-activity-item" key={id}>
                    <span className="my-notification-activity-name">{title}</span>
                    <span className="my-notification-activity-profile">活动简介：</span>
                    <span className="my-notification-activity-profile-content">{content}</span>
                    <span className="my-notification-activity-time">{`活动时间：${start_time}-${end_time}`}</span>
                  </div>}
                </div>
              );
            })
          }
        </FunLayout>
      </div>
    )
  }
}
