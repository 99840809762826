import { request } from '../../http';

class Model {

  /** 
   *  获取政策列表
   */
  getPolicyList(title, policyType, industry, page = 1, pageSize = 30) {
    return request(`/policy/list?title=${title}&policyType=${policyType}&industry=${industry}&page=${page}&pageSize=${pageSize}`, {
      method: 'GET',
    });
  }

  /** 
   *  获取政策解读列表
   * @param {number} id 政策ID
   */
  getPolicyAnalyzeList(id) {
    return request(`/policy/detail/${id}`, {
      method: 'GET',
    });
  }

  /** 
  *  获取申请政策列表
  */
  getApplyPolicyList(title, page = 1, pageSize = 30) {
    return request(`/policy/list_sq?title=${title}&page=${page}&pageSize=${pageSize}`, {
      method: 'GET',
    });
  }

  /**
   * 获取政策详情
   * @param  id 政策id
   * @returns 政策详情
   */
  getPolicyDetail(id) {
    return request(`/policy/detail/${id}`, {
      method: 'GET',
    });
  }

  collectPolicy(data) {
    return request('/company/policyCollect/add', {
      method: 'POST',
      data,
    });
  }

  canelCollectPolicy(data) {
    return request('/company/policyCollect/cancel', {
      method: 'POST',
      data,
    });
  }

  checkIfCollect(data) {
    return request(`/company/policyCollect/check`, {
      method: 'POST',
      data,
    });
  }

  /**
   * 获取申报材料
   */
  getPolicyMaterials(data) {
    return request(`/company/consultation/policy`, {
      method: 'POST',
      data,
    });
  }
}


export default new Model();
