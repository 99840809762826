import { Component } from 'react';
import FunLayout, { EnLayoutType } from '@/components/fun-layout';
import Header from '@/components/header';
import Image from '@/components/image';
import FunToast from '@/components/fun-toast';
import Utils from '@/common/utils';
import { eshiminSetTitleStyle } from '@/common/eshiminUtils';
import model from './model';

import './index.scss'

const StatusEnum = {
  0: '未完成',
  1: '已完成',
};
export default class MyExpertReservation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reservationList: [],
    };
  }
  componentDidMount() {
    model.getReservationList().then(res => {
      const { data = [] } = res;
      this.setState({ reservationList: data });
      if (data <= 0) {
        FunToast.show('暂无专家预约哦～');
      }
    });
    eshiminSetTitleStyle('我的专家预约');
  }

  render() {
    const { reservationList = [] } = this.state;
    return (
      <div className="my-expert-reservation-container">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <Header hidden={false} hasBack={true} className={'top'} history={this.props.history}>
            我的专家预约
          </Header>

          {
            reservationList && reservationList.map(item => {
              const { id, expert: { avatar, name, create_at, status } = {} } = item;
              return (
                <div className="reservation-item" key={id}>
                  <div className="expert-avatar-img">
                    <Image scale={1} src={Utils.getUrl(avatar)} />
                  </div>
                  <span className="expert-name">{`${name} 专家`}</span>
                  <span className="reservation-time">{`${create_at} 预约`}</span>
                  <span className="reservation-status">{StatusEnum[status]}</span>
                </div>
              );
            })
          }
        </FunLayout>
      </div>
    )
  }
}
